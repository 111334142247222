import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import TextField from "@mui/material/TextField";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import { addDoc, collection } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import {
  englishUser,
  sinhalaUser,
  tamilUser,
} from "../../dummyData/UserDetailsFormData";
import { Form } from "react-bootstrap";
import { languageOptions } from "../../dummyData/dummyData";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useAddUserMutation } from "../../redux/service/userService";
import "./UserDetailsForm.css";

function UserDetailsForm() {
  const navigate = useNavigate();
  const [language, setLanguage] = useState(sinhalaUser);

  const [addNewUser] = useAddUserMutation();

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    dob: "",
    gender: "",
    city: "",
    district: "",
    address: "",
    nic: "",
    isHaveDrivingSchool: "",
  });

  const [errors, setErrors] = useState({});


  useEffect(() => {
    const selectedLanguageValue =
      localStorage.getItem("languageValue") || "sinhala";
    switch (selectedLanguageValue) {
      case "english":
        setLanguage(englishUser);
        break;
      case "tamil":
        setLanguage(tamilUser);
        break;
      default:
        setLanguage(sinhalaUser);
        break;
    }
  }, []);

  const handleInputChange = (e) => {
    const { id, value, name, type } = e.target;
    if (type === "radio") {
      setFormData({ ...formData, [name]: value });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formData.firstName) {
      formErrors.firstName = "First name is required";
      isValid = false;
    }
    if (!formData.lastName) {
      formErrors.lastName = "Last name is required";
      isValid = false;
    }
    if (!formData.email) {
      formErrors.email = "Email is required";
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      formErrors.email = "Email address is invalid";
      isValid = false;
    }
    if (!formData.contactNumber) {
      formErrors.contactNumber = "Contact number is required";
      isValid = false;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      formErrors.mobile = "Mobile number must be 10 digits";
      isValid = false;
    }
    if (!formData.nic) {
      formErrors.nic = "NIC number is required";
      isValid = false;
    } else if (!/^\d{9}[VXvx]$|^\d{12}$/.test(formData.nic)) {
      formErrors.nic = "NIC must be 9 digits followed by V/X or 12 digits.";
      isValid = false;
    }
    if (!formData.dob) {
      formErrors.dob = "Date of birth is required";
      isValid = false;
    }
    if (!formData.gender) {
      formErrors.gender = "Gender is required";
      isValid = false;
    }
    if (!formData.city) {
      formErrors.city = "City is required";
      isValid = false;
    }
    if (!formData.district) {
      formErrors.district = "District is required";
      isValid = false;
    }
    if (!formData.address) {
      formErrors.address = "Address is required";
      isValid = false;
    }
    if (!formData.isHaveDrivingSchool) {
      formErrors.isHaveDrivingSchool = "Driving school option is required";
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {

    e.preventDefault();
    if (validateForm()) {
      try {
        const result = await addNewUser(formData).unwrap();
        console.log("Form Data:", formData);
        console.log("API Response:", result);
        Swal.fire({
          title: "Success",
          text: "Data submitted successfully!",
          icon: "success",
          confirmButtonText: "OK",
        });
        return result;
      } catch (error) {
        console.error("API Error:", error);
        Swal.fire({
          title: "Error",
          text: "Failed to submit data. Please try again later.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    }
  };

  // const handleSkip = (e) => {
  //   e.preventDefault();
  //   Swal.fire({
  //     title: "Are you sure?",
  //     icon: "question",
  //     confirmButtonText: "Yes",
  //     confirmButtonColor: "#590B21",
  //   }).then(() => {
  //     navigate("/");
  //   });
  // };

  return (
    <div>
      <form className="row g-3 p-4">
        <div className="col-md-6">
          <label htmlFor="firstName" className="user-form-label">
            {language.firstName}
          </label>
          <input
            type="text"
            className="form-control"
            id="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
          />
          {/* <TextField id="outlined-basic" label="Outlined" variant="outlined" /> */}
          {errors.firstName && (
            <p className="text-danger">{errors.firstName}</p>
          )}
        </div>
        <div className="col-md-6">
          <label htmlFor="lastName" className="user-form-label">
            {language.lastName}
          </label>
          <input
            type="text"
            className="form-control"
            id="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
          />
          {errors.lastName && <p className="text-danger">{errors.lastName}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="email" className="user-form-label">
            {language.email}
          </label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={formData.email}
            onChange={handleInputChange}
          />
          {errors.email && <p className="text-danger">{errors.email}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="nic" className="user-form-label">
            {language.nic}
          </label>
          <input
            id="nic"
            value={formData.nic}
            onChange={handleInputChange}
            className="form-control"
            type="text"
          />
          {errors.nic && <p className="text-danger">{errors.nic}</p>}
        </div>
        <div className="col-md-4">
          <label htmlFor="mobile" className="user-form-label">
            {language.mobileNumber}
          </label>
          <input
            id="contactNumber"
            value={formData.contactNumber}
            onChange={handleInputChange}
            className="form-control"
            type="text"
          />
          {errors.mobile && <p className="text-danger">{errors.mobile}</p>}
        </div>
        <div className="col-md-4">
          <label htmlFor="dob" className="user-form-label">
            {language.dob}
          </label>
          <input
            id="dob"
            className="form-control"
            type="date"
            value={formData.dob}
            onChange={handleInputChange}
          />
          {errors.dob && <p className="text-danger">{errors.dob}</p>}
        </div>
        <div className="col-md-4">
          <label htmlFor="gender" className="user-form-label">
            {language.gender}
          </label>
          <select
            className="form-select"
            id="gender"
            value={formData.gender}
            onChange={handleInputChange}
          >
            <option value="">{language.dropDownTitle}</option>
            <option value="male">{language.genderType.male}</option>
            <option value="female">{language.genderType.female}</option>
            <option value="other">{language.genderType.other}</option>
          </select>
          {errors.gender && <p className="text-danger">{errors.gender}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="city" className="user-form-label">
            {language.city}
          </label>
          <input
            type="text"
            className="form-control"
            id="city"
            value={formData.city}
            onChange={handleInputChange}
          />
          {errors.city && <p className="text-danger">{errors.city}</p>}
        </div>
        <div className="col-md-6">
          <label htmlFor="district" className="user-form-label">
            {language.district}
          </label>
          <select
            className="form-select"
            id="district"
            value={formData.district}
            onChange={handleInputChange}
          >
            <option value="">{language.dropDownTitle}</option>
            {Object.keys(language.districtList).map((key) => (
              <option key={key} value={language.districtList[key]}>
                {language.districtList[key]}
              </option>
            ))}
          </select>
          {errors.district && <p className="text-danger">{errors.district}</p>}
        </div>
        <div className="col-12">
          <label htmlFor="address" className="user-form-label">
            {language.address}
          </label>
          <input
            type="text"
            className="form-control"
            id="address"
            value={formData.address}
            onChange={handleInputChange}
          />
          {errors.address && <p className="text-danger">{errors.address}</p>}
        </div>
        {/* --------------------------- */}
        <div className="col-12">
          <label htmlFor="drivingSchool" className="user-form-label me-2">
            {language.radioButtonSection.question}
          </label>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isHaveDrivingSchool"
              id="inlineRadio1"
              value="yes"
              onChange={handleInputChange}
            />
            <label className="user-form-label ms-1" htmlFor="inlineRadio1">
              {language.radioButtonSection.yes}
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="isHaveDrivingSchool"
              id="inlineRadio2"
              value="no"
              onChange={handleInputChange}
            />
            <label className="user-form-label ms-1" htmlFor="inlineRadio2">
              {language.radioButtonSection.no}
            </label>
          </div>
        </div>

        {/* ----------------------------- */}
        <div className="col-12 justify-content-start">
          <button
            type="submit"
            className="btn form-btn text-light px-4 py-2 mx-2 jus"
            onClick={handleSubmit}
          >
            {language.submit}
          </button>
          {/* <button
                type="button"
                className="btn btn-secondary px-4 py-2 ml-2 mx-2"
                onClick={handleSkip}
              >
                {language.skip}
              </button> */}
        </div>
      </form>
    </div>
  );
}

export default UserDetailsForm;
