import React from 'react'

function HomeButton(props) {
  return (
    <div className="col justify-content-center align-self-center">
      <a href="/">
        <button className="btn btn-warning mb-2 row">
          <i
            className="fa fa-home fa-md p-2 align-items-start justify-content-start col text-light"
            aria-hidden="true"
          >
            <span
              className="col text-light"
              style={{
                fontSize: "16px",
                fontFamily: "Arial, sans-serif",
              }}
            >
              {" "}
            {props.home}
            </span>
          </i>
        </button>
      </a>
    </div>
  );
}

export default HomeButton
