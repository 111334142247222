import React, { useState, useEffect } from "react";
import { Modal, Button, Container } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import {
  englishData,
  sinhalaData,
  tamilData,
} from "../../dummyData/HomeDummyData";
import "./PaperSelectModal.css";

function ChangePaperModal({ showModal, handleClose }) {
  const [language, setLanguage] = useState(sinhalaData[0]);

  const handleOpenPaper = (route) => {
    handleClose(); 
    setTimeout(() => {
      window.location.href = route; 
    },);  
  };

  useEffect(() => {
    const selectedLanguageValue =
      localStorage.getItem("languageValue") || "sinhala";

    switch (selectedLanguageValue) {
      case "english":
        setLanguage(englishData[0]);
        break;
      case "tamil":
        setLanguage(tamilData[0]);
        break;
      default:
        setLanguage(sinhalaData[0]);
        break;
    }
  }, []);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      size="lg"
      className="modal-top"
    >
      <Modal.Header closeButton>
        <div className="w-100">
          <Modal.Title>{language.modelTitle}</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Container fluid className="d-flex flex-column align-items-center p-4">
          <p className="text-center">{language.subTitle}</p>
          <div className="d-flex justify-content-around w-100">
            <Nav.Link href="/paper" onClick={(e) => handleOpenPaper("/paper")}>
              <Button className="btn model-basic-color m-2">
                {language.allqueButton}
              </Button>
            </Nav.Link>
            <Nav.Link
              href="/spotPaper"
              onClick={(e) => handleOpenPaper("/spotPaper")}
            >
              <Button className="btn model-basic-color m-2">
                {language.oneByoneButton}
              </Button>
            </Nav.Link>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default ChangePaperModal;
