import TopNav from "../TopNav/TopNav";
import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import "./indexAboutus.css";
import backgroundImg from "../../assets/images/aboutUsBackground.png";
import AboutUsMain from "../../assets/images/AboutUsMain.png";

const AboutUs = () => {
  return (
    <>
      <div className="position-fixed w-100 show-topnav-under">
        <TopNav />
      </div>
      <div className="aboutus-bg-hero">
        <div className="position-absolute top-0 start-0 w-100">
          <Container fluid className="d-flex justify-content-center">
            <Row className="d-flex  justify-content-center ps-2">
              <Col
                lg={6}
                className="d-flex flex-column align-items-center justify-content-center"
              >
                <div className="">
                  <div className="justify-content-center pt-4 pe-2 ps-2">
                    <div className="d-flex align-items-center justify-content-center flex-column bd-highlight mb-3">
                      <div className="d-flex justify-content-center p-2">
                        <h3 className="d-flex justify-content-center startnowtxt pt-4 mt-5">
                          About Us
                        </h3>
                      </div>
                      <div className="d-flex justify-content-center p-5">
                        <p className="d-flex justify-content-center about-p fw-normal">
                          Welcome to MechSIT (PVT) LTD, where innovation meets
                          excellence. Founded in 2019, we are dedicated to
                          delivering exceptional [products/services] that cater
                          to the diverse needs of our clients. Our team of
                          passionate professionals is committed to pushing the
                          boundaries of what’s possible, ensuring every
                          solution.
                        </p>
                      </div>
                      <a href="https://mechsit.com/">
                        <button
                          type="button"
                          className="btn btn-warning btn-lg mb-2 text-light justify-content-end "
                        >
                          See More{" "}
                          <i className="fa fa-chevron-circle-right p-2"></i>
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                sm={6}
                className="d-flex flex-column align-items-center justify-content-center"
                style={{ marginTop: 100 }}
              >
                <div className="ps-2 ms-1 align-items-center">
                  <Image src={AboutUsMain} className="bg-img img-fluid" />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUs;