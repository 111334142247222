export const englishUser = {
  firstName: "First Name",
  lastName: "Last Name",
  email: "Email",
  nic: "NIC Number",
  mobileNumber: "Mobile Number",
  dob: "Date of Birth",
  gender: "Gender",
  dropDownTitle: "Select",
  genderType: {
    male: "Male",
    female: "Female",
    other: "Other",
  },
  city: "City",
  district: "District",
  districtList: {
    ampara: "Ampara",
    anuradhapura: "Anuradhapura",
    badulla: "Badulla",
    batticaloa: "Batticaloa",
    colombo: "Colombo",
    galle: "Galle",
    gampaha: "Gampaha",
    hambantota: "Hambantota",
    jaffna: "Jaffna",
    kalutara: "Kalutara",
    kandy: "Kandy",
    kegalle: "Kegalle",
    kilinochchi: "Kilinochchi",
    kurunegala: "Kurunegala",
    mannar: "Mannar",
    matale: "Matale",
    matara: "Matara",
    monaragala: "Monaragala",
    mullaitivu: "Mullaitivu",
    nawaraeliya: "Nuwara Eliya",
    polonnaruwa: "Polonnaruwa",
    puttalam: "Puttalam",
    ratnapura: "Ratnapura",
    trincomalee: "Trincomalee",
    Vavuniya: "Vavuniya",
  },
  address: "Address",
  radioButtonSection: {
    question: "Are you already enrolled in a driving school?",
    yes: "Yes",
    no: "No",
  },
  submit: "Submit",
  skip: "Skip",
};

export const sinhalaUser = {
  firstName: "මුල් නම",
  lastName: "අවසන් නම",
  email: "ඊමේල්",
  nic: "ජාතික හැඳුනුම්පත් අංකය",
  mobileNumber: "දුරකථන අංකය",
  dob: "උපන් දිනය",
  gender: "ස්ත්‍රී / පුරුෂ භාවය ",
  dropDownTitle: "තෝරන්න",
  genderType: {
    male: "පුරුෂ",
    female: "ස්ත්‍රී",
    other: "වෙනත්",
  },
  city: "නගරය",
  district: "දිස්ත්‍රික්කය",
  districtList: {
    ampara: "	අම්පාර",
    anuradhapura: "අනුරාධපුර",
    badulla: "බදුල්ල",
    batticaloa: "මඩකලපුව",
    colombo: "කොළඹ",
    galle: "ගාල්ල",
    gampaha: "ගම්පහ",
    hambantota: "හම්බන්තොට",
    jaffna: "යාපනය",
    kalutara: "කළුතර",
    kandy: "මහනුවර",
    kegalle: "	කෑගල්ල",
    kilinochchi: "කිලිනොච්චි",
    kurunegala: "කුරුණෑගල",
    mannar: "මන්නාරම",
    matale: "මාතලේ",
    matara: "මාතර",
    monaragala: "මොණරාගල",
    mullaitivu: "මුලතිව්",
    nawaraeliya: "නුවරඑළිය",
    polonnaruwa: "පොළොන්නරුව",
    puttalam: "	පුත්තලම",
    ratnapura: "රත්නපුර",
    trincomalee: "ත්‍රිකුණාමලය",
    Vavuniya: "වවුනියාව",
  },
  address: "ලිපිනය",
  radioButtonSection: {
    question: "ඔබ දැනටමත් රියපුහුණු පාසලකට ඇතුලත් වී සිටින්නේද ? ",
    yes: "ඔව්",
    no: "නැත ",
  },
  submit: "තොරතුරු ඇතුලත් කරන්න",
  skip: "මඟ හරින්න",
};

export const tamilUser = {
  firstName: "முதல் பெயர்",
  lastName: "கடைசி பெயர்",
  email: "மின்னஞ்சல்",
  nic: "தேசிய அடையாள அட்டை எண்",
  mobileNumber: "மொபைல் எண்",
  dob: "பிறந்த தேதி",
  gender: "பாலினம்",
  dropDownTitle: "தேர்ந்தெடு",
  genderType: {
    male: "ஆண்",
    female: "பெண் ஆண்",
    other: "மற்றவை",
  },
  city: "நகரம்",
  district: "மாவட்டம்",
  districtList: {
    ampara: "அம்பாறை",
    anuradhapura: "அனுராதபுரம்",
    badulla: "பதுளை",
    batticaloa: "மட்டக்களப்பு",
    colombo: "கொழும்பு",
    galle: "காலி",
    gampaha: "கம்பகா",
    hambantota: "அம்பாந்தோட்டை",
    jaffna: "யாழ்ப்பாணம்",
    kalutara: "களுத்துறை",
    kandy: "கண்டி",
    kegalle: "கேகாலை",
    kilinochchi: "கிளிநொச்சி",
    kurunegala: "குருணாகல்",
    mannar: "மன்னார்",
    matale: "மாத்தளை",
    matara: "மாத்தறை",
    monaragala: "மொனராகலை",
    mullaitivu: "முல்லைத்தீவு",
    nawaraeliya: "நுவரெலியா",
    polonnaruwa: "பொலன்னறுவை",
    puttalam: "புத்தளம்",
    ratnapura: "இரத்தினபுரி",
    trincomalee: "திருகோணமலை",
    Vavuniya: "வவுனியா",
  },
  address: "முகவரி",
  radioButtonSection: {
    question: "நீங்கள் ஏற்கனவே ஓட்டுநர் பள்ளியில் சேர்ந்திருக்கிறீர்களா?",
    yes: "ஆம்",
    no: "இல்லை",
  },
  submit: "சமர்ப்பிக்கவும்",
  skip: "தவிர்க்கவும்",
};
