import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../AuthContext";
import axios from "axios";
import { toast } from "react-toastify";
import { auth } from "../../firebase/firebase";
import {
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import "./SignIn.css";

export default function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  const navigate = useNavigate();
  const [remember, setRemember] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      const payload = { email, password };
      const response = await axios.post(
        "https://api-fyfkcojn3q-uc.a.run.app/api/auth/login",
        payload
        // const userCredential = await signInWithEmailAndPassword(
        //   auth,
        //   email,
        //   password
      );
      // await login(email, password);
      // toast.success("User signed in successfully!");
      // const accessToken = await userCredential.user.getIdToken();
      // localStorage.setItem("accessToken", accessToken);

      if (response.status === 200) {
        const { accessToken, userId } = response.data;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userId", userId);
        toast.success("User signed in successfully!");

        if (remember) {
          localStorage.setItem("email", email);
          localStorage.setItem("password", password);
          localStorage.setItem("remember", true);
        } else {
          localStorage.removeItem("email");
          localStorage.removeItem("password");
          localStorage.setItem("remember", false);
        }

        navigate("/admin");
      }
    } catch (error) {
      alert("Error signing in: " + error.message);
    }
  };

  const handleForgotPassword = async () => {
    const auth = getAuth();
    if (!email) {
      alert("Please enter your email to reset your password.");
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent! Please check your inbox.");
    } catch (error) {
      alert("Error resetting password: " + error.message);
    }
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    const savedRemember = localStorage.getItem("remember") === "true";
    if (savedRemember) {
      setEmail(savedEmail || "");
      setPassword(savedPassword || "");
      setRemember(savedRemember);
    }
  }, []);

  return (
    <div className="pb-4 shadow-lg">
      <form onSubmit={handleSignIn}>
        <div className="mb-3 mx-3 position-relative">
          <i className="fas fa-envelope icon-position"></i>
          <input
            type="email"
            className="form-control pl-2"
            id="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <div className="mb-3 mx-3 position-relative">
          <i className="fas fa-lock icon-position"></i>
          <input
            type="password"
            className="form-control pl-2"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>

        <div className="row">
          <div className="form-check d-flex justify-content-center col">
            <input
              className="form-check-input text-start"
              type="checkbox"
              name="pw-remember"
              id="pw-remember"
              value="Remember"
              checked={remember}
              onChange={(e) => setRemember(e.target.checked)}
            />
            <label className="form-check-label ms-2" htmlFor="pw-remember">
              Remember
            </label>
          </div>
          <button
            type="button"
            className="btn btn-link text-btn-color col"
            onClick={handleForgotPassword}
          >
            Forgot Password?
          </button>
        </div>

        <div className="d-grid">
          <button type="submit" className="btn mx-3 btn-login-color btn-block">
            LogIn
          </button>
        </div>
      </form>
    </div>
  );
}
