import AllRoadSigns from "../../src/assets/cardImages/AllRoadSigns.png";
import TrafficRulesandVehicleFines from "../../src/assets/cardImages/TrafficRulesandVehicleFines.png";
import Vehiclehandbook from "../../src/assets/cardImages/Vehiclehandbook.png";

export const englishData = [
  {
    title:
      "Question papers related to the examinations for Sri Lanka driving license",
    subTitle:
      'Here under "To answer all questions at once" you can check the correct answers after answering the questions and under "To answer questions one by one" after answering one question it will show whether the answer is correct.',
    titlesButton: "To answer the question Papers",
    modelTitle: "Change in Question Paper Format",
    allqueButton: "I want to Answer all questions once",
    oneByoneButton: "I want to Answer questions one by one",
    cardOne: "Traffic Rules and Vehicle Fines",
    cardTwo: "Vehicle hand book",
    cardThree: "All Road Signs",
    paperNo1: "Paper 01",
    paperNo2: "Paper 02",
    paperNo3: "Paper 03",
    paperNo4: "Paper 04",
    articlesCard: [
      {
        title: "Traffic Rules and Vehicle Fines",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: TrafficRulesandVehicleFines,
      },
      {
        title: "All Road Signs",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: AllRoadSigns,
      },
      {
        title: "Vehicle hand book",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: Vehiclehandbook,
      },
    ],
  },
];

export const sinhalaData = [
  {
    title: "ශ්‍රී ලංකා රියදුරු බලපත්‍ර සදහා වන විභාග වලට අදාල ප්‍රශ්න පත්‍ර",
    subTitle:
      'මෙහිදී "සියලුම ප්‍රශ්න වලට එකවර පිළිතුරු දීමට" යටතේ ප්‍රශ්න වලට පිළිතුරු දීමෙන් පසුව නිවැරදි පිළිතුරු පරීක්ෂා කළ හැකි අතර "ප්‍රශ්න වලට එකින් එක පිළිතුරු දීමට" යටතේ එක් ප්‍රශ්නයකට පිළිතුරු දීමෙන් පසුව පිළිතුර නිවැරදිදැයි පෙන්නුම් කරයි. ',
    titlesButton: "ප්‍රශ්න පත්‍ර  වලට පිළිතුරු දීමට",
    modelTitle: "ප්‍රශ්න පත්‍ර ආකාරය වෙනස් කිරීම",
    allqueButton: "සියලුම ප්‍රශ්න වලට එකවර පිළිතුරු දීමට",
    oneByoneButton: "ප්‍රශ්න වලට එකින් එක පිළිතුරු දීමට",
    cardOne: "රථවාහන නීති සහ වාහන දඩ",
    cardTwo: "රියදුරන්ගේ අත්පොත",
    cardThree: "සියලුම මාර්ග සංඥා",
    paperNo1: "ප්‍රශ්න පත්‍ර අංක 01",
    paperNo2: "ප්‍රශ්න පත්‍ර අංක 02",
    paperNo3: "ප්‍රශ්න පත්‍ර අංක 03",
    paperNo4: "ප්‍රශ්න පත්‍ර අංක 04",
    articlesCard: [
      {
        title: "Traffic Rules and Vehicle Fines",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: TrafficRulesandVehicleFines,
      },
      {
        title: "All Road Signs",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: AllRoadSigns,
      },
      {
        title: "Vehicle hand book",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: Vehiclehandbook,
      },
    ],
  },
];

export const tamilData = [
  {
    title: "இலங்கை ஓட்டுநர் உரிமத்திற்கான பரீட்சைகள் தொடர்பான வினாத்தாள்கள்",
    subTitle:
      'இங்கே "எல்லாக் கேள்விகளுக்கும் ஒரே நேரத்தில் பதிலளிக்க" என்பதன் கீழ் நீங்கள் கேள்விகளுக்குப் பதிலளித்த பிறகு சரியான பதில்களைச் சரிபார்க்கலாம் மற்றும் "கேள்விகளுக்கு ஒவ்வொன்றாக பதிலளிக்க" என்பதன் கீழ் ஒரு கேள்விக்கு பதிலளித்த பிறகு அது சரியானதா என்பதைக் காண்பிக்கும்.',
    titlesButton: "கேள்வி தாள்களுக்கு பதிலளிக்க",
    modelTitle: "வினாத்தாள் வடிவத்தில் மாற்றம்",
    allqueButton: "அனைத்து கேள்விகளுக்கும் ஒரே நேரத்தில் பதிலளிக்க",
    oneByoneButton: "கேள்விகளுக்கு ஒவ்வொன்றாகப் பதில் சொல்ல வேண்டும்",
    cardOne: "போக்குவரத்து சட்டங்கள் மற்றும் வாகன அபராதம்",
    cardTwo: "ஓட்டுநர் கையேடு",
    cardThree: "அனைத்து சாலை அடையாளங்களும்",
    paperNo1: "வினாத்தாள் எண். 01",
    paperNo2: "வினாத்தாள் எண். 02",
    paperNo3: "வினாத்தாள் எண். 03",
    paperNo4: "வினாத்தாள் எண். 04",
    articlesCard: [
      {
        title: "Traffic Rules and Vehicle Fines",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: TrafficRulesandVehicleFines,
      },
      {
        title: "All Road Signs",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: AllRoadSigns,
      },
      {
        title: "Vehicle hand book",
        description:
          "The word Traffic-Rules, therefore, offers structured and regulated steps that an individual requires to take on the roadway to reach their location safely.",
        image: Vehiclehandbook,
      },
    ],
  },
];
