import React, { useState, useEffect } from "react";
import "./ManageStudents.css";
import { useNavigate } from "react-router-dom";
import { FaEye, FaSearch, FaCalendarAlt, FaChevronDown } from "react-icons/fa";
import {
  useGetMembersByStatusQuery,
  useUpdateMembersByStatusMutation,
} from "../../../redux/service/membersService";

const ManageStudents = () => {
  const navigate = useNavigate();
  const [activeMembers, setActiveMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const drivingSchoolId = localStorage.getItem("userId");

  const {
    data: activeData,
    error: activeError,
    isLoading: activeLoading,
  } = useGetMembersByStatusQuery({
    drivingSchoolId,
    status: "active",
  });

  useEffect(() => {
    if (activeData?.members) {
      setActiveMembers(activeData.members);
      console.log("Fetched Active members successfully:", activeData);
    } else if (activeError) {
      console.error("Error fetching active members:", activeError);
    }
  }, [activeData, activeError]);

  console.log(activeData, "ActiveData ===================>");

if (isLoading) return <div>Loading...</div>;

  const handleViewProfile = (member) => {
    navigate(`/admin/studentProfile/${member.id}`, { state: { member } });
  };

  return (
    <div className="active-members-page">
      <div className="active-members-container">
        <h2>Active Members</h2>
        <div className="filters">
          <div className="left-filters">
            <div className="search-input-wrapper">
              <FaSearch className="search-icon" />
              <input
                type="text"
                placeholder="Search"
                className="search-input"
              />
            </div>
          </div>

          <div className="right-filters">
            <div className="dropdown-wrapper">
              <select className="dropdown">
                <option>Colombo</option>
                <option>Kandy</option>
                <option>Galle</option>
              </select>
              <FaChevronDown className="dropdown-icon" />
            </div>

            <div className="dropdown-wrapper">
              <select className="dropdown">
                <option>Branch</option>
                <option>Colombo</option>
                <option>Kandy</option>
              </select>
              <FaChevronDown className="dropdown-icon" />
            </div>

            <div className="date-input-wrapper">
              <input type="date" className="date-input" placeholder="From" />
            </div>

            <div className="date-input-wrapper">
              <input type="date" className="date-input" placeholder="To" />
            </div>
          </div>
        </div>

        <table className="members-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>City</th>
              <th>Branch</th>
              <th>Trail Date</th>
              <th>Exam Date</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(activeMembers) ? (
              activeMembers.map((member, index) => (
                <tr key={index}>
                  <td>{String(index + 1).padStart(2, "0")}</td>
                  <td>{`${member.firstName} ${member.lastName}`}</td>
                  <td>{member.city}</td>
                  <td>{member?.branch}</td>
                  <td>{member?.trialDate}</td>
                  <td>{member?.examDate}</td>
                  <td
                    className="action-icon"
                    onClick={() => handleViewProfile(member)}
                  >
                    <FaEye />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7">No active members found</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageStudents;
