import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Image,
  Form,
} from "react-bootstrap";
import mainImg from "../../assets/images/homeMain2.png";
import TrafficImg from "../../assets/images/Traffic Rules .png";
import AllRoadImg from "../../assets/images/All Road Signs.png";
import SLDLsinhala from "../../assets/images/SLDL.png";
import SLDLTamil from "../../assets/images/SLDL Tamil.png";
import SLDLEnglish from "../../assets/images/SLDL English.png";
import SLDLIOS from "../../assets/images/SLDL IOS.png";
import arrow from "../../../src/assets/cardImages/Arrow.png";
import PlayStore from "../../assets/images/Play Store.png";
import AppStore from "../../assets/images/App Store.png";
import getDrivingSchool from "../../assets/HomeSection/get-driving-school.png";
import { NavLink } from "react-router-dom";
import {
  englishData,
  sinhalaData,
  tamilData,
} from "../../dummyData/HomeDummyData";
import PaperShiftButtons from "../PaperFourShiftButtons/PaperShiftButtons";
import "./HomePageMain.css";
import UserDetailsModal from "../../views/Forms/UserDetailsModal";
import OurApps from "../OurApps/OurApps";
import UserDetailsForm from "../../views/Forms/UserDetailsForm";


function HomePageMain() {
  const [selectedAnswers, setSelectedAnswers] = useState(
    Array(sinhalaData.length).fill("")
  );

  const [language, setLanguage] = useState(sinhalaData[0]);
  const [showModal, setShowModal] = useState(false);

  const handleClose = () => {
    setShowModal(false);
    localStorage.setItem("modalShown", "true"); 
  };

  useEffect(() => {
  const selectedLanguageValue = localStorage.getItem("languageValue");
  const modalShown = localStorage.getItem("modalShown");

  switch (selectedLanguageValue) {
    case "english":
      setLanguage(englishData[0]);
      setSelectedAnswers(Array(englishData.length).fill(""));
      break;
    case "tamil":
      setLanguage(tamilData[0]);
      setSelectedAnswers(Array(tamilData.length).fill(""));
      break;
    default:
      setLanguage(sinhalaData[0]);
      setSelectedAnswers(Array(sinhalaData.length).fill(""));
      break;
  }


  if (!modalShown) {
    setShowModal(true);
    localStorage.setItem("modalShown", "true");
  }
}, []);


  const handleButtonClick = (props) => {
    window.location.href = props.link;
  };

  return (
    <div>
      <div className="home-page-hero">
        <div className="text-center">
          <h1 className="home-main-title-h1">Ready to Drive?</h1>
          <h1 className="home-main-title-h1">Begin Your Journey with Us!</h1>
          <p className="home-main-title-p">
            Prepare for your driving test with expert resources and mock exams
            designed for success.
          </p>
          <button className="btn btn-warning text-light px-5 py-2 w-auto w-sm-100">
            Join Now
          </button>
        </div>
      </div>
      <div className="section-vertical-space">
        <Container fluid className="d-flex justify-content-center p-4">
          <Row className="d-flex align-items-start justify-content-center py-4 px-4">
            <Col sm={6} className="d-flex flex-column align-items-start px-5">
              <div className="d-flex justify-content-start p-2 ">
                <h3 className="d-flex justify-content-start startnowtxt-head">
                  {/* {language.title} */}Mock Tests for Driving Test Success!
                </h3>
              </div>
            </Col>

            <Col sm={6} className="d-flex flex-column align-items-start px-5">
              <div className="d-flex justify-content-start p-2">
                <h6 className="d-flex justify-content-start startnowtxt">
                  {/* {language.subTitle} */}"Practice with mock tests and ace
                  your driving exam effortlessly! Stay confident and ready with
                  realistic questions and instant feedback. Your journey to
                  driving success starts here!"
                </h6>
              </div>
            </Col>
            <PaperShiftButtons
              First={language.paperNo1}
              Second={language.paperNo2}
              Third={language.paperNo3}
              Fourth={language.paperNo4}
            />
          </Row>
        </Container>
      </div>

      <Container fluid className="py-5 articles-section section-vertical-space">
        <div className="section-vertical-space">
          <h2 className="section-title text-center mb-5 justify-content-start">
            Top Articles to Boost Your Learning
          </h2>
          <Row className="d-flex justify-content-center">
            {language.articlesCard.map((article, index) => (
              <Col
                key={index}
                lg={3}
                md={6}
                sm={12}
                className="mb-4 d-flex align-items-stretch"
              >
                <Card className="shadow-sm article-card">
                  <Card.Img
                    variant="top"
                    src={article.image}
                    className="article-image"
                  />
                  <Card.Body>
                    <Card.Title>{article.title}</Card.Title>
                    <Card.Text>{article.description}</Card.Text>
                    <Button className="btn-outline-dark justify-content-start .btn-articles">
                      Read More
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </Container>

      <Container fluid className="get-driving-school-section">
        <div className="row align-items-center">
          {/* Image Section */}
          <div className="col-12 col-md-6 p-0">
            <img
              src={getDrivingSchool}
              alt="Driving School Promo"
              className="img-fluid get-driving-school-image"
            />
          </div>

          {/* Text Section */}
          <div className="col-md-6 text-white d-flex flex-column justify-content-center py-3 px-5 px-md-5">
            <h1 className="promo-title text-center text-md-start">
              Get Your Driving School Listed Here – It’s Free!
            </h1>
            <p className="promo-description text-justify text-center text-md-start">
              Join our platform and get your driving school listed for free!
              It's a simple way to connect with students looking for reliable,
              certified driving instruction. Reach a larger audience, and make
              it easier for new learners to find your school. Register today and
              grow your business!
            </p>
            <div className="d-flex justify-content-center justify-content-md-start">
              <button className="btn btn-warning promo-button">
                REGISTER NOW
              </button>
            </div>
          </div>
        </div>
      </Container>

      <Container className="driving-form-section">
        <div className="section-vertical-space">
          <Row className="mb-2">
            <Col>
              <h2 className="form-title">Ready to Learn Driving?</h2>
              <p className="form-description">
                Driver's education, driver education, driving education,
                driver's training, driver's ed, driving tuition or driving
                lessons is a formal class or program that prepares a new driver
                to obtain a learner's permit or driver's license.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3 className="form-subtitle">
                Enter Your Information to Get Started
              </h3>
            </Col>
          </Row>
          <UserDetailsForm />
        </div>
      </Container>

      <Container fluid className="get-driving-school-section p-5">
        <Row className="py-3">
          <Col lg={3} md={6} sm={12}></Col>
        </Row>
        <Row>
          {/* About Us */}
          <Col lg={3} md={6} sm={12}>
            <h5 className="company-name">MECHSIT(PVT)LTD</h5>
            <h4 className="footer-title">About Us</h4>
            <p className="footer-p-tag pe-2">
              Where innovation meets excellence. Our team of passionate
              professionals is committed to pushing the boundaries of what’s
              possible, ensuring every solution.
            </p>
          </Col>

          {/* Contact Info */}
          <Col lg={3} md={6} sm={12}>
            <h4 className="footer-title">Contact Info</h4>
            <p className="footer-p-tag">
              <strong>Call Us</strong>
              <br />
              +94 71 773 4346
              <br />
              +94 76 574 7783
            </p>
            <p className="footer-p-tag">
              <strong>Email</strong>
              <br />
              <a href="mailto:info@mechsit.com" style={{ color: "#FFFFFF" }}>
                info@mechsit.com
              </a>
            </p>
            <p className="footer-p-tag">
              <strong>Address</strong>
              <br />
              MECHSIT (PVT) LTD
              <br />
              No. 100/1, Dumbara Uyana, Balagolla, Kengalla
            </p>
          </Col>

          {/* Download Apps */}
          <Col lg={3} md={6} sm={12}>
            <h4 className="footer-title">Download the Other Apps</h4>
            <p className="footer-p-tag text-left">
              <a
                href="https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl&hl=en"
                className="apps-click"
                style={{ color: "#FFFFFF", textDecoration: "none" }}
              >
                <img
                  src={SLDLsinhala}
                  style={{ marginRight: "20px", width: "40px", height: "40px" }}
                  alt="arrow"
                />
                SLDL Sinhala App
              </a>
            </p>
            <p className="footer-p-tag text-left">
              <a
                href="https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl.tm&hl=ta&pli=1"
                className="apps-click"
                style={{ color: "#FFFFFF", textDecoration: "none" }}
              >
                <img
                  src={SLDLTamil}
                  style={{ marginRight: "20px", width: "40px", height: "40px" }}
                  alt="arrow"
                />
                SLDL Tamil App
              </a>
            </p>
            <p className="footer-p-tag">
              <a
                href="https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl.en&hl=en"
                className="apps-click"
                style={{ color: "#FFFFFF", textDecoration: "none" }}
              >
                <img
                  src={SLDLEnglish}
                  style={{ marginRight: "20px", width: "40px", height: "40px" }}
                  alt="arrow"
                />
                SLDL English App
              </a>
            </p>
          </Col>

          {/* Subscribe Newsletter */}
          <Col lg={3} md={6} sm={12}>
            <h4 className="footer-title">Subscribe Newsletter</h4>
            <p className="footer-p-tag">
              Subscribe to our newsletter to get updates about our services and
              offers
            </p>
            <Form>
              <Form.Group className="d-flex">
                <Form.Control
                  type="email"
                  placeholder="Email Address"
                  style={{
                    borderRadius: "20px 0 0 20px",
                    border: "none",
                    height: "50px",
                  }}
                />
                <Button
                  className="subscribe-button"
                  variant="primary"
                  style={{
                    width: "100px",
                    height: "50px",
                    borderRadius: "0px 20px 20px 0px",
                    backgroundColor: "#00AEEF",
                    border: "none",
                  }}
                >
                  &#x27A4;
                </Button>
              </Form.Group>
            </Form>
          </Col>
        </Row>
      </Container>

      {/* <div className="align-items-center justify-content-center">
        <div className="align-items-center justify-content-center py-1">
          <Container className="d-flex justify-content-center">
            <div className="container row d-flex justify-content-center p-4">
              <div className="d-flex justify-content-start p-4">
                <h4 className="d-flex justify-content-start startnowtxt ourappsText">
                  Try Over Apps
                </h4>
            
              </div>
              <div className="p-4 bg-light border border-warning rounded">
                <div>
                  <div className="container justify-content-center">
                    <div className="row row-cols-2 align-items-center justify-content-center p-2">
                    
                      <OurApps
                        appIcon={SLDLsinhala}
                        appName={"SLDL Sinhala"}
                        platform={PlayStore}
                        appLink={
                          "https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl&hl=en"
                        }
                      />

                      <OurApps
                        appIcon={SLDLTamil}
                        appName={"SLDL Tamil"}
                        platform={PlayStore}
                        appLink={
                          "https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl.tm&hl=ta&pli=1"
                        }
                      />

                      <OurApps
                        appIcon={SLDLEnglish}
                        appName={"SLDL English"}
                        platform={PlayStore}
                        appLink={
                          "https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl.en&hl=en"
                        }
                      />

                      <OurApps
                        appIcon={SLDLIOS}
                        appName={"SLDL - for IOS"}
                        platform={AppStore}
                        appLink={
                          "https://apps.apple.com/sr/app/mechsit-sri-lanka-drivers-exam/id1599318476"
                        }
                      />


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div> */}
      <div>
        <UserDetailsModal showModal={showModal} handleClose={handleClose} />
      </div>
    </div>
  );
}

export default HomePageMain;
