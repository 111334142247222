import TopNav from "../TopNav/TopNav";
import React from "react";
import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
// import backgroundImg from "../../assets/images/aboutUsBackground.png";
import WhatsAppIMG from "../../assets/images/WAppIcon.png";
import "./Contact.css";

const Contact = () => {
  const form = useRef();
  const [messageStatus, setMessageStatus] = useState("");
  const [formError, setFormError] = useState("");

  const sendEmail = (e) => {
    e.preventDefault();

    const name = form.current.user_name.value.trim();
    const email = form.current.user_email.value.trim();
    const message = form.current.message.value.trim();

    if (!name || !email || !message) {
      setFormError("Please fill out all fields.");
      setMessageStatus(""); 
      return;
    }

    setFormError(""); 

    emailjs
      .sendForm("service_pfbv76k", "template_yv2lvnt", form.current, {
        publicKey: "jqc6VLfJexTyBMv-C",
      })
      .then(
        () => {
          setMessageStatus("SUCCESS");
          form.current.reset();
        },
        (error) => {
          setMessageStatus(`FAILED: ${error.text}`);
        }
      );
  };
    return (
      <div>
        <div className="position-fixed w-100 show-topnav-under">
          <TopNav />
        </div>
        <div className="contact-img-hero">
          <div className="position-absolute top-0 start-0 w-100">
            <Container
              fluid
              className="d-flex container-fluid justify-content-center"
            >
              <Row
                className="d-flex  justify-content-center pt-5 "
                style={{ marginTop: 10 }}
              >
                <Row className="d-flex  justify-content-center mt-5 pb-4">
                  <h3 className="d-flex justify-content-center startnowtxt">
                    Contact Us
                  </h3>
                </Row>
                <Col
                  sm={6}
                  className="d-flex flex-column align-items-center justify-content-center "
                >
                  <div className="container overflow-hidden">
                    <div className="row gx-5 pe-4 pb-5">
                      <div className="col-4 align-items-center justify-content-center">
                        <i
                          className="fa fa-phone fa-2xl p-5 align-items-center justify-content-center"
                          aria-hidden="true"
                          p-5
                        ></i>
                      </div>
                      <div className="col-8 align-items-center justify-content-center">
                        <div className="align-items-center justify-content-start pe-5">
                          <h6>CALL US</h6>
                          <div className="p-1">+94 71 773 4346</div>
                          <div className="p-1">+94 76 574 7783</div>
                        </div>
                      </div>
                    </div>

                    <div className="row gx-5 pe-4 pb-5">
                      <div className="col-4 align-items-center justify-content-center">
                        <i className="fa fa-envelope fa-2xl p-5 align-items-center justify-content-center"></i>
                      </div>
                      <div className="col-8 align-items-center justify-content-center">
                        <div className="align-items-center justify-content-start pe-5">
                          <div className="p-1"></div>
                          <h6>EMAIL</h6>
                          <div className="p-1">lankalearnerslk@gmail.com</div>
                        </div>
                      </div>
                    </div>

                    <div className="row gx-5 pe-4 pb-5">
                      <div className="col-4 align-items-center justify-content-center">
                        <i
                          className="fa fa-map-marker fa-2xl p-5 align-items-center justify-content-center"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div className="col-8 align-items-center justify-content-center">
                        <div className="align-items-center justify-content-start pe-5">
                          <h6>ADDRESS</h6>
                          <div className="p-1">MECHSIT (PVT) LTD,</div>
                          <div className="p-1">
                            No. 100/1, Dumbara Uyana, Balagolla,Kengalla
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col
                  sm={6}
                  className="d-flex flex-column align-items-center justify-content-end"
                >
                  <div className="container overflow-hidden">
                    <form
                      ref={form}
                      onSubmit={sendEmail}
                      className="bg-secondary px-4 py-4 rounded bg-opacity-25 border border-warning"
                      //bg-success p-2 text-dark bg-opacity-50
                    >
                      <div className="firstRow">
                        <div className="mb-3">
                          <input
                            name="user_name"
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder="Full Name"
                          />
                        </div>
                        <div className="mb-3">
                          <input
                            name="user_email"
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder="Email"
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <textarea
                          type="text"
                          id="disabledTextInput"
                          className="form-control"
                          cols="30"
                          name="message"
                          rows="10"
                          placeholder="Message"
                        ></textarea>
                      </div>
                      {formError && (
                        <div className="text-danger mb-3">{formError}</div>
                      )}
                      {messageStatus === "SUCCESS" && (
                        <div className="text-success mb-3">
                          Message sent successfully!
                        </div>
                      )}
                      {messageStatus.startsWith("FAILED") && (
                        <div className="text-danger mb-3">
                          Message failed to send!
                          {/* {messageStatus} */}
                        </div>
                      )}
                      <div className="mb-3">
                        <a className="submit" href=" ">
                          <button
                            type="submit"
                            value="Send"
                            className="btn btn-warning col-12 text-light"
                          >
                            Send Message
                          </button>
                        </a>
                      </div>
                      {/* <div className="mb-3"></div> */}
                    </form>
                  </div>
                </Col>
              </Row>
              <div
                className="fixed-bottom right-100 p-3"
                style={{ zIndex: "100", left: "initial" }}
              >
                <a
                  href="https://wa.me/94778274522?text=I would like to know more about your products."
                  title="Chat with us on WhatsApp"
                >
                  <Image src={WhatsAppIMG} width={180} />
                </a>
              </div>
            </Container>
          </div>
        </div>
      </div>
    );
}

export default Contact;