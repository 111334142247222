import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import { FaUpload } from "react-icons/fa";
import {
  useUpdateDrivingSchoolMutation,
  useGetSingleDrivingSchoolMutation,
} from "../../../redux/service/drivingSchoolService";

function EditProfile({ drivingSchoolId }) {
  const [getSingleDrivingSchool] = useGetSingleDrivingSchoolMutation();
  const [updateDrivingSchool, { isLoading, isError, isSuccess }] =
    useUpdateDrivingSchoolMutation();

  const [formData, setFormData] = useState({
    name: "",
    regNumber: "",
    city: "",
    description: "",
    phoneNumber: "",
    email: "",
    address: "",
    logo: "",
    services: [],
    prices: [],
    branches: [],
  });

  drivingSchoolId = localStorage.getItem("userId");

  useEffect(() => {
    async function fetchDrivingSchool() {
      try {
        const response = await getSingleDrivingSchool({
          drivingSchoolId,
        }).unwrap();
        setFormData(response);
      } catch (error) {
        console.error("Error fetching driving school:", error);
      }
    }
    fetchDrivingSchool();
  }, [drivingSchoolId, getSingleDrivingSchool]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    try {
      await updateDrivingSchool({ drivingSchoolId, data: formData }).unwrap();
      alert("Profile updated successfully!");
    } catch (error) {
      console.error("Error updating profile:", error);
      alert("Failed to update profile.");
    }
  };

  return (
    <div className="edit-profile-container">
      <h2 className="edit-profile-title">Edit Profile</h2>

      <div className="profile-section-container">
        <div className="upload-photo-container">
          <FaUpload className="upload-icon" />
          <label>Click to upload</label>
        </div>

        <div className="profile-details-container">
          <div>
            <label className="input-label">Learners Name</label>
            <input
              type="text"
              className="input-field"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="input-label">Reg No</label>
            <input
              type="text"
              className="input-field"
              name="regNumber"
              value={formData.regNumber}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ gridColumn: "span 2" }}>
            <label className="input-label">Description</label>
            <textarea
              type="text"
              className="text-area"
              name="description"
              value={formData.description}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="input-label">Email</label>
            <input
              type="email"
              className="input-field"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div>
            <label className="input-label">Mobile Numbers</label>
            <input
              type="text"
              className="input-field"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleInputChange}
            />
          </div>
          <div style={{ gridColumn: "span 2" }}>
            <label className="input-label">Address</label>
            <input
              type="text"
              className="input-field"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>

      <div className="services-documents-container">
        <div className="selected-services-container">
          <h3>Selected Services</h3>
          {[
            "Motorbike license",
            "Motorbike license",
            "Motorbike license",
            "Motorbike license",
            "Motorbike license",
          ].map((service, index) => (
            <div key={index} className="service-item-container">
              <input type="checkbox" />
              <label className="checkbox-label">{service}</label>
              <input
                type="text"
                className="service-price-input"
                placeholder="Enter Price"
              />
            </div>
          ))}
        </div>

        <div className="required-documents-container">
          <h3>Required Documents</h3>
          {[
            "Birthday Certificate",
            "National Identity Card",
            "Medical Report",
            "Report (Only Heavy Vehicles)",
          ].map((doc, index) => (
            <div key={index} className="document-item-container">
              <input type="checkbox" />
              <label className="checkbox-label">{doc}</label>
            </div>
          ))}
        </div>
      </div>

      <div className="branches-section-container">
        <h3>Branches</h3>
        <div>
          <label className="input-label">District</label>
          <select className="dropdown">
            <option>Select...</option>
          </select>
        </div>
        <div>
          <label className="input-label">City</label>
          <select className="dropdown">
            <option>Select...</option>
          </select>
        </div>
        <div>
          <label className="input-label">Reg No</label>
          <input type="text" className="input-field" />
        </div>
        <div>
          <label className="input-label">Address</label>
          <input type="text" className="input-field" />
        </div>
        <div>
          <label className="input-label">Email</label>
          <input type="email" className="input-field" />
        </div>
        <div>
          <label className="input-label">Mobile Number</label>
          <input type="text" className="input-field" />
        </div>
        <div className="has-offers-container">
          <label className="input-label">Has Offers</label>
          <input type="checkbox" />
        </div>
      </div>

      <div className="save-button-container">
        <button
          className="save-button"
          onClick={handleSave}
          disabled={isLoading}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
      {isError && (
        <p className="error-message">
          Error updating profile. Please try again.
        </p>
      )}
      {isSuccess && (
        <p className="success-message">Profile updated successfully!</p>
      )}
    </div>
  );
}

export default EditProfile;
