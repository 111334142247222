

export const languageOptions  = [
    { value: 'sinhala', label: 'සිංහල' },
    { value: 'tamil', label: 'தமிழ்' },
    { value: 'english', label: 'English' },
];

export const paperOptions = [
  { value: "first", label: "First Paper" },
  { value: "second", label: "Second Paper" },
  { value: "third", label: "Third Paper" },
  { value: "fourth", label: "Fourth Paper" },
];





