import React, { useState, useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Paper.css";
import { englishQuiz } from "../../dummyData/englishQuestionsData";
import { sinhalaQuiz } from "../../dummyData/sinhalaQuestionsData";
import { tamilQuiz } from "../../dummyData/tamilQuestionsData";
import { setAnswers, setIsPaper } from "../../redux/paperSlice";
import HomeButton from "../../Components/HomeButton/HomeButton";
import Swal from "sweetalert2";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { PieChart } from "react-minimal-pie-chart";

function Paper() {
  const dispatch = useDispatch();
  
  const [timeLeft, setTimeLeft] = useState(null);
  
  const [selectedAnswers, setSelectedAnswers] = useState([]);
 
  const [showAnswers, setShowAnswers] = useState(false);
  
  const [result, setResults] = useState({
    score: 0,
    correctAnswers: 0,
    wrongAnswers: 0,
    didntAnswers: 0,
  });

  const [language, setLanguage] = useState(sinhalaQuiz);

  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const [questionArray, setQuestionArray] = useState([
    sinhalaQuiz.questionArray1,
  ]);

  useEffect(() => {
    const selectedLanguageValue =
      localStorage.getItem("languageValue") || "sinhala";
    const selectedPaperValue = localStorage.getItem("paperValue") || "first";

    let quizData;
    switch (selectedLanguageValue) {
      case "english":
        quizData = englishQuiz;
        break;
      case "tamil":
        quizData = tamilQuiz;
        break;
      default:
        quizData = sinhalaQuiz;
        break;
    }

    const questionKey = `questionArray${
      selectedPaperValue.charAt(0).toUpperCase() + selectedPaperValue.slice(1)
    }`;
    const questions = quizData[questionKey] || [];

    setLanguage(quizData);
    setQuestionArray(questions);

    const savedAnswers = localStorage.getItem(
      `selectedAnswers_${selectedPaperValue}`
    );
    const savedTimeLeft = localStorage.getItem(
      `timeLeft_${selectedPaperValue}`
    );

    if (savedAnswers) {
      try {
        const parsedAnswers = JSON.parse(savedAnswers);
        if (parsedAnswers.length === questions.length) {
          setSelectedAnswers(parsedAnswers);
        } else {
          setSelectedAnswers(Array(questions.length).fill(""));
        }
      } catch (e) {
        console.error("Error parsing saved answers:", e);
        setSelectedAnswers(Array(questions.length).fill(""));
      }
    } else {
      setSelectedAnswers(Array(questions.length).fill(""));
    }

    if (savedTimeLeft) {
      try {
        const parsedTimeLeft = JSON.parse(savedTimeLeft);
        setTimeLeft(parsedTimeLeft);
      } catch (e) {
        console.error("Error parsing saved time left:", e);
        setTimeLeft(3600);// change time
      }
    } else {
      setTimeLeft(null);
    }

    dispatch(setAnswers(savedAnswers || Array(questions.length).fill("")));
  }, [dispatch]);

  useEffect(() => {
    let timer;
    if (timeLeft > 0 && !showAnswers) {
      timer = setTimeout(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      const selectedPaperValue = localStorage.getItem("paperValue") || "first";
      localStorage.setItem(
        `selectedAnswers_${selectedPaperValue}`,
        JSON.stringify(selectedAnswers)
      );
      localStorage.setItem(
        `timeLeft_${selectedPaperValue}`,
        JSON.stringify(timeLeft)
      );
      dispatch(setAnswers(selectedAnswers));
    } else if (timeLeft === 0) {
      setShowAnswers(true);
      calculateResults();
      dispatch(setAnswers(null));
      dispatch(setIsPaper(false));
      clearTimeout(timer);
      const selectedPaperValue = localStorage.getItem("paperValue") || "first";
      localStorage.removeItem(`selectedAnswers_${selectedPaperValue}`);
      localStorage.removeItem(`timeLeft_${selectedPaperValue}`);
    }

    return () => clearTimeout(timer);
  }, [timeLeft, showAnswers, dispatch, selectedAnswers]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (timeLeft > 0) {
        event.preventDefault();
        event.returnValue = ""; // Required for modern browsers to show a confirmation dialog

        const selectedPaperValue =
          localStorage.getItem("paperValue") || "first";
        localStorage.setItem(
          `selectedAnswers_${selectedPaperValue}`,
          JSON.stringify(selectedAnswers)
        );
        localStorage.setItem(
          `timeLeft_${selectedPaperValue}`,
          JSON.stringify(timeLeft)
        );
      } else {
        // Prevent refresh if timeLeft is 0
        event.preventDefault();
        event.returnValue = "Time has run out. You cannot refresh the page.";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [selectedAnswers, timeLeft]);


  const calculateResults = () => {
    let score = 0;
    let correctAnswers = 0;
    let wrongAnswers = 0;
    let didntAnswers = 0;

    questionArray.forEach((question, index) => {
      if (selectedAnswers[index] === "") {
        didntAnswers++;
      } else if (selectedAnswers[index] === question?.correct) {
        correctAnswers++;
      } else {
        wrongAnswers++;
      }
    });

    setResults({ score, correctAnswers, wrongAnswers, didntAnswers });
  };

  const handleAnswerChange = (index, answer) => {
    const newAnswers = [...selectedAnswers];
    newAnswers[index] = answer;
    setSelectedAnswers(newAnswers);
  };

  const handleSubmit = () => {
    setShowAnswers(true);
    setTimeLeft(0);
    calculateResults();
    dispatch(setIsPaper(false));
    const selectedPaperValue = localStorage.getItem("paperValue") || "first";
    localStorage.removeItem(`selectedAnswers_${selectedPaperValue}`);
    localStorage.removeItem(`timeLeft_${selectedPaperValue}`);
  };

  const formatTime = (time) => {
    // const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `
            ${minutes.toString().padStart(2, "0")}:
            ${seconds.toString().padStart(2, "0")}`;
  };
// ${hours.toString().padStart(2, "0")}:
  const data = [
    {
      title: language.correctAnswers,
      value: result.correctAnswers,
      color: "#198754",
    },
    {
      title: language.wrongAnswers,
      value: result.wrongAnswers,
      color: "#DC3545",
    },
    {
      title: language.didntAnswers,
      value: result.didntAnswers,
      color: "#FFC107",
    },
  ];

useEffect(() => {
  let isMounted = true;

  const selectedPaperValue = localStorage.getItem("paperValue") || "first";

  const savedTimeLeft = localStorage.getItem(`timeLeft_${selectedPaperValue}`);

  if (
    savedTimeLeft === null ||
    savedTimeLeft === undefined ||
    savedTimeLeft === "0"
  ) {
    Swal.fire({
      title: language.popTitle,
      text: language.popText,
      icon: "question",
      showCancelButton: false,
      confirmButtonColor: "#590B21",
      cancelButtonColor: "#ddd",
      confirmButtonText: language.popButtonText,
      allowOutsideClick: false,
    }).then((result) => {
      if (isMounted && result.isConfirmed) {
        setTimeLeft(3600); //Change time
        dispatch(setIsPaper(true));
      }
    });
  } else {
    if (isMounted) {
      setTimeLeft(JSON.parse(savedTimeLeft));
      dispatch(setIsPaper(true));
    }
  }

  return () => {
    isMounted = false; 
    Swal.close(true); 
  };
}, [language, dispatch]);




  return (
    <div className="container p-1">
      <div className="row justify-content-center align-items-start">
        <div className="col-lg-10 col-md-12 col-sm-12 p-1">
          {!showAnswers && !drawerOpen && window.innerWidth < 992 && (
            <div className="row d-flex">
              <div
                className={` ${
                  window.innerWidth < 992
                    ? "col-12 pe-1 me-1 tabTopIndicatorStyle"
                    : "col-md-8"
                }  bg-light fixed-positions-questions show-answer-under m-2`}
              >
                <div
                  className={`row d-flex justify-content-between ${
                    window.innerWidth < 992 ? "" : "topIndicatorStyle"
                  }`}
                >
                  <span className="col-md col-lg">
                    {language.answerAllQuestions}
                  </span>
                  <span className="col">
                    {language.timeRemainings} : {formatTime(timeLeft)} / 1:00:00
                  </span>
                  <span className="col-md col-lg">
                    {language.total}: {language.totalQuestions}
                  </span>
                  <div className="d-flex justify-content-start align-items-start">
                    <div className="progress m-2" style={{ width: "100%" }}>
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{
                          width: `${((3600 - timeLeft) / 3600) * 100}%`,
                        }}
                        aria-valuenow={((3600 - timeLeft) / 3600) * 100}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="mt-5">
            {showAnswers &&
              (window.innerWidth < 1025 ? (
                <div className="border p-4 m-2 row justify-content-evenly align-items-center topScoreCharts bg-light">
                  <div
                    className="CircularProgressbar-path CircularProgressbar-trail CircularProgressbar-text CircularProgressbar-background col-3"
                    style={{ width: 200, height: 200 }}
                  >
                    <CircularProgressbarWithChildren
                      value={Math.ceil(
                        (result.correctAnswers / language.totalQuestions) * 100
                      )}
                      maxValue={100}
                    >
                      <div className="Progressbar-text">
                        {result.correctAnswers} / {language.totalQuestions}
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className="col-md align-self-center">
                    <h4 className="py-2 ">
                      {language.score} : {result.correctAnswers} /{" "}
                      {language.totalQuestions}
                    </h4>
                    <h6 className="text-success text-gradient">
                      {language.correctAnswers} : {result.correctAnswers}
                    </h6>
                    <h6 className="text-danger text-gradient">
                      {language.wrongAnswers} : {result.wrongAnswers}
                    </h6>
                    <h6 className="text-warning text-gradient">
                      {language.didntAnswers} : {result.didntAnswers}
                    </h6>
                  </div>
                </div>
              ) : (
                <div className="border p-4 m-2 row justify-content-evenly align-items-center topScoreCharts bg-light">
                  <div
                    className="CircularProgressbar-path CircularProgressbar-trail CircularProgressbar-text CircularProgressbar-background col-3"
                    style={{ width: 200, height: 200 }}
                  >
                    <CircularProgressbarWithChildren
                      value={Math.ceil(
                        (result.correctAnswers / language.totalQuestions) * 100
                      )}
                      maxValue={100}
                    >
                      <div className="Progressbar-text">
                        {result.correctAnswers} / {language.totalQuestions}
                      </div>
                    </CircularProgressbarWithChildren>
                  </div>
                  <div className="col-lg" style={{ width: 250, height: 250 }}>
                    <PieChart
                      center={[50, 50]}
                      data={data}
                      lineWidth={40}
                      radius={30}
                    />
                  </div>
                  <div className="col">
                    <div className="row pieChartLable">
                      {data.map((entry, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "5px",
                          }}
                        >
                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              backgroundColor: entry.color,
                              marginRight: "5px",
                              borderRadius: "4px",
                            }}
                          ></div>
                          <span>{entry.title}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-4 align-self-center">
                    <h2 className="py-2">
                      {language.score} : {result.correctAnswers} /{" "}
                      {language.totalQuestions}
                    </h2>
                    <h5 className="text-success text-gradient">
                      {language.correctAnswers} : {result.correctAnswers}
                    </h5>
                    <h5 className="text-danger text-gradient">
                      {language.wrongAnswers} : {result.wrongAnswers}
                    </h5>
                    <h5 className="text-warning text-gradient">
                      {language.didntAnswers} : {result.didntAnswers}
                    </h5>
                  </div>
                </div>
              ))}
            {window.innerWidth < 770 ? (
              !showAnswers && <div className="pt-5 mt-5"></div>
            ) : (
              <div></div>
            )}
            {!showAnswers && <div className="pt-4 mt-4"></div>}
            {window.innerWidth < 770 ? (
              <div className="quiz-container">
                <div className="px-5">
                  <div className="pe-3">
                    {questionArray?.map((question, index) => (
                      <div
                        key={question?.questionId}
                        className="p-2 border-bottom border-2 border-dark "
                      >
                        <div className="row justify-content-start text-start">
                          {index + 1}. {question?.question}
                        </div>
                        <div className="p-1">
                          {question?.image && (
                            <img
                              src={question?.image}
                              alt="Question illustration"
                              className="img-thumbnail imageSize"
                            />
                          )}
                        </div>
                        <div className="row justify-content-evenly">
                          <div className="col-md-12 align-self-start">
                            {question?.answers?.map((answer) => (
                              <div key={answer}>
                                <label
                                  className={`label-container ${
                                    showAnswers && answer === question?.correct
                                      ? selectedAnswers[index] ===
                                        question?.correct
                                        ? "alert alert-success paperAnswersAlertMargine"
                                        : selectedAnswers[index] !== ""
                                        ? "alert alert-danger paperAnswersAlertMargine"
                                        : "alert alert-warning paperAnswersAlertMargine"
                                      : ""
                                  }`}
                                >
                                  <input
                                    type="radio"
                                    name={`question-${index}`}
                                    disabled={showAnswers}
                                    checked={selectedAnswers[index] === answer}
                                    onChange={() =>
                                      handleAnswerChange(index, answer)
                                    }
                                    className="answersRadioButton"
                                  />
                                  {question?.answers.indexOf(answer) + 1}.{" "}
                                  {answer}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className="quiz-container"
                style={{
                  overflow: "auto",
                }}
              >
                <div className="px-5">
                  <div className="pe-3">
                    {questionArray?.map((question, index) => (
                      <div
                        key={question?.questionId}
                        className="quection-area p-2 border-bottom border-2 border-dark "
                      >
                        <div className="question-text">
                          <div className="row justify-content-start text-start">
                            {index + 1}. {question?.question}
                          </div>
                          <div className="col-md-12 align-self-end ">
                            {question?.image && (
                              <div className="image-container">
                                <img
                                  src={question?.image}
                                  alt="Question illustration"
                                  className="img-thumbnail float-end imageSize"
                                />
                              </div>
                            )}
                          </div>
                          <div className="row justify-content-start">
                            <div className="col-md-10 align-self-start">
                              {question?.answers?.map((answer) => (
                                <div key={answer} className="text-left">
                                  <label
                                    className={`label-container ${
                                      showAnswers &&
                                      answer === question?.correct
                                        ? selectedAnswers[index] ===
                                          question?.correct
                                          ? "alert alert-success paperAnswersAlertMargine"
                                          : selectedAnswers[index] !== ""
                                          ? "alert alert-danger paperAnswersAlertMargine"
                                          : "alert alert-warning paperAnswersAlertMargine"
                                        : ""
                                    }`}
                                  >
                                    <input
                                      type="radio"
                                      name={`question-${index}`}
                                      disabled={showAnswers}
                                      checked={
                                        selectedAnswers[index] === answer
                                      }
                                      onChange={() =>
                                        handleAnswerChange(index, answer)
                                      }
                                      className="answersRadioButton"
                                    />
                                    {question?.answers.indexOf(answer) + 1}.{" "}
                                    {answer}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-md-2 align-self-start bd-sidebar">
          <div className="row bg-light p-3 border position-fixed h-100 ">
            <div className="col align-self-center">
              {/*-------------------------*/}
              <HomeButton home={language.homeButton} />
              {/*-------------------------*/}
              <div className="timer">
                {language.timeRemainings} : {formatTime(timeLeft)}
              </div>
              <h5>
                {language.score}: {result.correctAnswers} /{" "}
                {language.totalQuestions}
              </h5>
            </div>
            <div>
              <div className="row row-cols-sm-3 row-cols-md-4 row-cols-lg-5 g-2 g-lg-3 m-1">
                {Array.from({ length: language.totalQuestions }).map((_, i) => (
                  <div key={i}>
                    <button
                      className={`btn p-1 ${
                        timeLeft !== 0
                          ? selectedAnswers[i] !== ""
                            ? "btn-primary"
                            : "btn-secondary"
                          : selectedAnswers[i] === questionArray[i]?.correct
                          ? "btn-success"
                          : selectedAnswers[i] !== ""
                          ? "btn-danger"
                          : "btn-warning"
                      }`}
                      onClick={() =>
                        window.scrollTo(
                          0,
                          document.body.scrollHeight *
                            (i / language.totalQuestions)
                        )
                      }
                    >
                      {i < 9 ? "0" + (i + 1) : i + 1}
                      {/* {i + 1} */}
                    </button>
                  </div>
                ))}
              </div>
            </div>

            {/*-------------------------*/}
            <div className="row">
              <div className="justify-content-center align-self-center">
                {!showAnswers && (
                  <button
                    className="btn btn-primary ms-3"
                    onClick={handleSubmit}
                  >
                    {language.submitBtn}
                  </button>
                )}
              </div>
            </div>
            {/*-------------------------*/}
          </div>
        </div>

        {window.innerWidth < 992 && (
          <button
            className="btn btn-primary fixed-bottom m-3"
            style={{
              left: "50%",
              transform: "translateX(-50%)",
              width: "auto",
              zIndex: "100",
            }}
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            {drawerOpen ? "Close Menu" : "Open Menu"}
          </button>
        )}

        {drawerOpen && window.innerWidth < 992 && (
          <div className="drawer bg-light position-fixed p-3">
            {!showAnswers && <div className="pt-2 mt-2"></div>}
            <div className="timer">
              {language.timeRemainings} : {formatTime(timeLeft)}
            </div>
            <h2>{language.score} :</h2>
            <h2>
              {result.correctAnswers} / {language.totalQuestions}
            </h2>

            <div className="row row-cols-5 row-cols-lg-5 g-2 g-lg-3">
              {Array.from({ length: language.totalQuestions }).map((_, i) => (
                <div key={i}>
                  <button
                    className={`btn  ${
                      timeLeft !== 0
                        ? selectedAnswers[i] !== ""
                          ? "btn-primary"
                          : "btn-secondary"
                        : selectedAnswers[i] === questionArray[i]?.correct
                        ? "btn-success"
                        : selectedAnswers[i] !== ""
                        ? "btn-danger"
                        : "btn-warning"
                    }`}
                    onClick={() =>
                      window.scrollTo(
                        0,
                        document.body.scrollHeight *
                          (i / language.totalQuestions)
                      )
                    }
                  >
                    {i < 9 ? "0" + (i + 1) : i + 1}
                    {/* {i + 1} */}
                  </button>
                </div>
              ))}
            </div>
            {/*-------------------------*/}
            <div className="row">
              {/*-------------------------*/}
              <HomeButton home={language.homeButton} />

              <div className="col justify-content-center align-self-center">
                {!showAnswers && (
                  <button
                    className="btn btn-primary m-3"
                    onClick={handleSubmit}
                  >
                    {language.submitBtn}
                  </button>
                )}
              </div>
            </div>
            {/*-------------------------*/}
          </div>
        )}
        {/* <div className="row mt-3 p-5">
          <div className="col justify-content-center align-self-center">
            {!showAnswers && (
              <button className="btn btn-primary ms-3" onClick={handleSubmit}>
                {language.submitBtn}
              </button>
            )}
            {!showAnswers && (
                            <button
                                className="btn btn-outline-primary ms-3"
                                onClick={clearAnswers}
                            >
                                Clear
                            </button>
                        )}
          </div>
        </div> */}
      </div>
    </div>
  );
}

export default Paper;
