import React, { useEffect, useState, useRef } from "react";
import "./StudentProfile.css";
import { FaCalendarAlt, FaEdit } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { useUpdateActiveMembersMutation } from "../../../redux/service/membersService";
import profileImage from "../../../assets/LearnersAuthReg/Profile.jpg";

const StudentProfile = () => {
  const location = useLocation();
  const { member } = location.state || {};
   const trialDateRef = useRef(null);
   const examDateRef = useRef(null);
  const [memberData, setMemberData] = useState(() => ({
    name: member.firstName + " " + member.lastName,
    email: member.email,
    address: member.address,
    regNumber: member.id,
    city: member.city,
    phoneNumber: member.contactNumber,
    branch: member.branch,
    nic: member.nic,
    services: member.services || [],
    trialDate: member.trialDate || "",
    examDate: member.examDate || "",
  }));

  const drivingSchoolId = localStorage.getItem("userId");

  const [updateActiveMembers, { isLoading, isSuccess }] =
    useUpdateActiveMembersMutation();

    const handleButtonClick = (ref) => {
      ref.current.focus();
    };

  useEffect(() => {
    if (isSuccess) {
      alert("Member updated successfully!");
    }
  }, [isSuccess]);

  if (!member) {
    return <div>No member data found.</div>;
  }

   const calculateAge = (dob) => {
     const birthDate = new Date(dob);
     const today = new Date();

     let age = today.getFullYear() - birthDate.getFullYear();
     const hasHadBirthdayThisYear =
       today.getMonth() > birthDate.getMonth() ||
       (today.getMonth() === birthDate.getMonth() &&
         today.getDate() >= birthDate.getDate());

     if (!hasHadBirthdayThisYear) {
       age -= 1;
     }

     return age;
   };

  const handleInputChange = (e) => {
    const { value, checked } = e.target;

    setMemberData((prev) => {
      const updatedServices = checked
        ? [...prev.services, value] 
        : prev.services.filter((service) => service !== value); 

      return {
        ...prev,
        services: updatedServices,
      };
    });
  };


  const handleUpdateMember = async () => {

    try {
      const response = await updateActiveMembers({
        drivingSchoolId: drivingSchoolId,
        memberId: member.id,
        data: memberData,
      }).unwrap();
      console.log("Update successful:", response);

    } catch (error) {
      console.error("Update failed:", error);
    }
  };
  


  return (
    <div className="student-profile-page">
      <h2>Active Members / Student Profile</h2>
      <div className="student-profile-container">
        <div className="profile-card">
          <img src={profileImage} alt="Profile" className="profile-picture" />
          <h3>{`${member.firstName} ${member.lastName}`}</h3>
          <p>{member.email}</p>
          <p>{member.contactNumber}</p>
          <p>{member.address}</p>
          <div className="registration">
            <span>Reg No</span>
            <span className="reg-number">
              {String(member.id).padStart(2, "0")}
            </span>
          </div>
        </div>

        <div className="right-side-cards">
          <div className="account-info">
            <h3>Account Information</h3>
            <div className="info-grid">
              <p>
                <strong>City</strong>{" "}
                <span className="info-value">{member.city}</span>
              </p>
              <p>
                <strong>Branch</strong>{" "}
                <span className="info-value">{member.branch}</span>
              </p>
              <p>
                <strong>Gender</strong>{" "}
                <span className="info-value">{member.gender}</span>
              </p>
              <p>
                <strong>Birth day</strong>{" "}
                <span className="info-value">{member.dob}</span>
              </p>
              <p>
                <strong>Age</strong>{" "}
                <span className="info-value">{calculateAge(member.dob)}</span>
              </p>
              <p>
                <strong>ID Number</strong>{" "}
                <span className="info-value">{member.nic}</span>
              </p>
            </div>
          </div>

          <div className="selected-services">
            <h3>
              Selected Services <FaEdit className="edit-icon" />
            </h3>
            <div className="services-list">
              {[
                "Motorbike license",
                "Heavy vehicle license",
                "Three-wheel license",
                "Light vehicle license",
              ].map((service, index) => (
                <label key={index}>
                  <input
                    type="checkbox"
                    name="services"
                    id={service}
                    value={service}
                    checked={memberData.services.includes(service)}
                    onChange={handleInputChange}
                  />{" "}
                  {service}
                </label>
              ))}
            </div>
          </div>

          <div className="dates-section">
            <div className="date-card">
              <h4>Trial Date</h4>
              <div className="date-picker">
                <input
                  className="date-picker-with-update"
                  ref={trialDateRef}
                  type="date"
                  name="trialDate"
                  value={memberData.trialDate}
                  onChange={(e) =>
                    setMemberData((prev) => ({
                      ...prev,
                      trialDate: e.target.value,
                    }))
                  }
                />
                <button onClick={() => handleButtonClick(trialDateRef)}>
                  Add
                </button>
              </div>
            </div>

            <div className="date-card">
              <h4>Exam Date</h4>
              <div className="date-picker">
                <input
                  className="date-picker-with-update"
                  ref={examDateRef}
                  type="date"
                  name="examDate"
                  value={memberData.examDate}
                  onChange={(e) =>
                    setMemberData((prev) => ({
                      ...prev,
                      examDate: e.target.value,
                    }))
                  }
                />
                <button onClick={() => handleButtonClick(examDateRef)}>
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-end mt-3">
        <button className="btn btn-primary" onClick={handleUpdateMember}>
          Update Member
        </button>
      </div>
    </div>
  );
};

export default StudentProfile;
