import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import TopNav from "../TopNav/TopNav";
import {
  englishData,
  sinhalaData,
  tamilData,
} from "../../dummyData/HomeDummyData";
import ChangePaperModal from "../../Components/PaperFourShiftButtons/PaperSelectModal";
import card1 from "../../assets/cardImages/PapersImg.png";
import card2 from "../../assets/cardImages/PapersImg.png";
import card3 from "../../assets/cardImages/PapersImg.png";
import card4 from "../../assets/cardImages/PapersImg.png";

function PapersPage() {
  const [language, setLanguage] = useState(sinhalaData[0]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedPaper, setSelectedPaper] = useState({
    label: "First Paper",
    value: "first",
  });

  useEffect(() => {
    const selectedLanguageValue = localStorage.getItem("languageValue"); 
    switch (selectedLanguageValue) {
      case "english":
        setLanguage(englishData[0]);
        break;
      case "tamil":
        setLanguage(tamilData[0]);
        break;
      default:
        setLanguage(sinhalaData[0]);
        break;
    }
  }, []);

  useEffect(() => {
    const storedPaperLabel =
      localStorage.getItem("paperLabel") || "First Paper";
    const storedPaperValue = localStorage.getItem("paperValue") || "first";
    setSelectedPaper({ label: storedPaperLabel, value: storedPaperValue });
  }, []);

  const handleOpenChangePaperModal = (label, value) => {
    setIsModelOpen(true); 
    localStorage.setItem("paperLabel", label);
    localStorage.setItem("paperValue", value);
    setSelectedPaper({ label, value });
  };

  const handleCloseChangePaperModal = () => {
    setIsModelOpen(false); 
  };

  return (
    <div>
      <div className="position-fixed w-100 show-topnav-under">
        <TopNav />
      </div>

      <div className="position-absolute top-0 start-0 w-100 mt-5 pt-2">
        <Row className="d-flex  justify-content-center mt-5 pb-4">
          <h3 className="d-flex justify-content-center">Papers</h3>
        </Row>
        <Container fluid className="d-flex justify-content-center">
          <Row className="d-flex  justify-content-center pb-4">
            <Col
              lg={3}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <button
                className="container col-sm col-md p-2 m-2 bg-light border border-warning rounded"
                onClick={() =>
                  handleOpenChangePaperModal("First Paper", "first")
                }
              >
                <div className="jumbotron rounded">
                  <img src={card1} className="img-fluid p-3" alt="..." />
                  <div className="justify-content-center d-flex">
                    <h5 className="d-inline-flex p-3">{language.paperNo1}</h5>
                  </div>
                </div>
              </button>
            </Col>
            <Col
              lg={3}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <button
                className="container col-sm col-md p-2 m-2 bg-light border border-warning rounded"
                onClick={() =>
                  handleOpenChangePaperModal("Second Paper", "second")
                }
              >
                <div className="jumbotron rounded">
                  <img src={card2} className="img-fluid p-3" alt="..." />
                  <div className="justify-content-center d-flex">
                    <h5 className="d-inline-flex p-3">{language.paperNo2}</h5>
                  </div>
                </div>
              </button>
            </Col>
            <Col
              lg={3}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <button
                className="container col-sm col-md p-2 m-2 bg-light border border-warning rounded"
                onClick={() =>
                  handleOpenChangePaperModal("Third Paper", "third")
                }
              >
                <div className="jumbotron rounded">
                  <img src={card3} className="img-fluid p-3" alt="..." />
                  <div className="justify-content-center d-flex">
                    <h5 className="d-inline-flex p-3">{language.paperNo3}</h5>
                  </div>
                </div>
              </button>
            </Col>
            <Col
              lg={3}
              md={6}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <button
                className="container col-sm col-md p-2 m-2 bg-light border border-warning rounded"
                onClick={() =>
                  handleOpenChangePaperModal("Fourth Paper", "fourth")
                }
              >
                <div className="jumbotron rounded">
                  <img src={card4} className="img-fluid p-3" alt="..." />
                  <div className="justify-content-center d-flex">
                    <h5 className="d-inline-flex p-3">{language.paperNo4}</h5>
                  </div>
                </div>
              </button>
            </Col>
          </Row>
        </Container>
      </div>
      {isModelOpen && (
        <ChangePaperModal
          showModal={isModelOpen}
          handleClose={handleCloseChangePaperModal}
        />
      )}
    </div>
  );
}

export default PapersPage;
