import React, { useState } from "react";
import TopNavAuth from "../../AuthLearners/TopNavAuth/TopNavAuth";
import Sidebar from "./Sidebar/Sidebar";
import "./AdminPanel.css";
import { Outlet } from "react-router-dom";

function Admin() {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

  return (
    <div className="admin-panel-container">
      <TopNavAuth />
      <div className={`admin-panel ${sidebarCollapsed ? "collapsed" : ""}`}>
        <Sidebar
          sidebarCollapsed={sidebarCollapsed}
          toggleSidebar={() => setSidebarCollapsed(!sidebarCollapsed)}
        />
        <main className="content">
          <Outlet />
        </main>
      </div>
    </div>
  );
}

export default Admin;
