import React from 'react'
import "./Home.css";
import TopNav from '../TopNav/TopNav';
import HomePageMain from '../../Components/HomePageMain/HomePageMain';
import {useSelector} from "react-redux";

function Home() {

    const userId = useSelector((state) => state?.paper?.isPaper);
    console.log(userId, " userId-------------------- paper get value ----------->")

  return (
    <>
      <div className="position-fixed w-100 show-topnav-top">
        <TopNav />
      </div>

      <HomePageMain />
    </>
  );
}

export default Home
