import React, {useState, useEffect} from 'react';
import { FaUserFriends, FaCheckCircle, FaUser, FaClipboardList } from 'react-icons/fa';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { useGetSingleDrivingSchoolMutation } from "../../../redux/service/drivingSchoolService";
import './AdminHome.css';

// Import the image
import logo from '../../../assets/LearnersCardImage/AmalLearners.png';

const data = [
  { name: 'Male', value: 26, color: '#FF4D4D' },
  { name: 'Female', value: 6, color: '#FFD700' },
];

function AdminHome() {
const [schoolData, setSchoolData] = useState(null);
const [getSingleDrivingSchool] =
  useGetSingleDrivingSchoolMutation();

  const drivingSchoolId = localStorage.getItem("userId");

  useEffect(() => {
    if (drivingSchoolId) {
      // Fetch school data
      getSingleDrivingSchool({ drivingSchoolId })
        .unwrap()
        .then((data) => {
          setSchoolData(data);
        })
        .catch((error) => {
          console.error("Error fetching driving school data:", error);
        });
    }
  }, [drivingSchoolId, getSingleDrivingSchool]);

  if (!schoolData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="admin-home">
      <div className="stats-cards">
        <div className="card">
          <div className="icon-container">
            <FaUserFriends className="icon" />
          </div>
          <div className="line-connector"></div>
          <div className="text-container">
            <p>Student Requests</p>
            <h2>02</h2>
          </div>
        </div>
        <div className="card">
          <div className="icon-container">
            <FaCheckCircle className="icon" />
          </div>
          <div className="line-connector"></div>
          <div className="text-container">
            <p>Accepted Requests</p>
            <h2>15</h2>
          </div>
        </div>
        <div className="card">
          <div className="icon-container">
            <FaUser className="icon" />
          </div>
          <div className="line-connector"></div>
          <div className="text-container">
            <p>Active Students</p>
            <h2>32</h2>
          </div>
        </div>
        <div className="card">
          <div className="icon-container">
            <FaClipboardList className="icon" />
          </div>
          <div className="line-connector"></div>
          <div className="text-container">
            <p>Completed Students</p>
            <h2>150</h2>
          </div>
        </div>
      </div>

      <div className="main-info">
        <div className="school-info">
          <img src={logo} alt="Amal Learners Logo" className="logo" />
          <div className="school-info-content">
            <h3>{schoolData.name}</h3>
            <span className="reg-no">Reg.No: {schoolData.regNumber}</span>
            <p>{schoolData.description}</p>
          </div>
        </div>

        <div className="additional-info">
          <div className="recent-students">
            <h4>Recent Trail Dates</h4>
            <div className="student-list">
              <div className="student-item">
                <div
                  className="student-initial"
                  style={{ backgroundColor: "#C5A3FF" }}
                >
                  C
                </div>
                <p>Chanaka Perera</p>
                <span>01/10/2024</span>
              </div>
              <div className="student-item">
                <div
                  className="student-initial"
                  style={{ backgroundColor: "#A3FFC5" }}
                >
                  C
                </div>
                <p>Chanaka Perera</p>
                <span>02/10/2024</span>
              </div>
              <div className="student-item">
                <div
                  className="student-initial"
                  style={{ backgroundColor: "#FFC5A3" }}
                >
                  C
                </div>
                <p>Chanaka Perera</p>
                <span>03/10/2024</span>
              </div>
              <div className="student-item">
                <div
                  className="student-initial"
                  style={{ backgroundColor: "#A3C5FF" }}
                >
                  C
                </div>
                <p>Chanaka Perera</p>
                <span>04/10/2024</span>
              </div>
            </div>
          </div>

          <div className="student-gender-chart">
            <h4>Students</h4>
            <ResponsiveContainer width="100%" height={200}>
              <PieChart>
                <Pie
                  data={data}
                  dataKey="value"
                  nameKey="name"
                  cx="50%"
                  cy="50%"
                  innerRadius={60}
                  outerRadius={80}
                  fill="#82ca9d"
                  label
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={entry.color} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
            <div className="gender-count">
              <p>Male: 26</p>
              <p>Female: 6</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminHome;
