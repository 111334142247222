import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const storedToken = localStorage.getItem("accessToken");

  if (!storedToken) {
    return <Navigate to="/authPage" replace />;
  }

  return <>{children}</>;
};

export default ProtectedRoute;
