import { apiSlice } from "../apiSlice"

export const userService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSingleUser: builder.mutation({
      query: ({ userId }) => {
        let url = `https://api-fyfkcojn3q-uc.a.run.app/api/user/getUser?userId=${encodeURIComponent(
          userId
        )}`;

        return {
          url,
          method: "GET",
          responseHandler: (response) => response.json(),
        };
      },
    }),

    getAllUsers: builder.mutation({
      query: () => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/api/user/getAllUsers`,
        method: "GET",
        responseHandler: (response) => response.json(),
      }),
    }),

    addUser: builder.mutation({
      query: (data) => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/api/user/addUser`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.text(),
      }),
    }),
  }),
});

export const { useGetSingleUserMutation, useGetAllUsersMutation, useAddUserMutation } =
  userService;