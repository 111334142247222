import AmalLearners from "../assets/LearnersCardImage/AmalLearners.png";
import NewLankaLearners from "../assets/LearnersCardImage/NewLankaLearners.png";
import PereraLearners from "../assets/LearnersCardImage/PereraLearners.png";
import MotorbikeTraining from "../assets/LearnersUIimages/Motorbiketraining.jpeg";
import AutoCartraining from "../assets/LearnersUIimages/AutoCartraining.jpg";
import Manualtraining from "../assets/LearnersUIimages/Manualtraining.jpg";
import HomeVisittraining from "../assets/LearnersUIimages/HomeVisittraining.jpg";
import Refreshertraining from "../assets/LearnersUIimages/Refreshertraining.png";
import HeavyVehicletraining from "../assets/LearnersUIimages/HeavyVehicletraining.png";

export const LearnersData = [
  {
    name: "Amal Learners",
    city: "Kandy",
    description:
      "Amal Driving School is a trusted driving school in colombo Sri Lanka with over 50 years experience in teaching learners how to drive. We highly believe in Promoting Road safety",
    image: AmalLearners,
    rating: 4.5,
    hotLine: "0778877552",
    services: [
      {
        name: "Motorbike training",
        image: MotorbikeTraining,
      },
      { name: "Home Visit training", image: HomeVisittraining },
      {
        name: "Auto Car training",
        image: AutoCartraining,
      },
      { name: "Manual training ", image: Manualtraining },
      { name: "Refresher training", image: Refreshertraining },
      { name: "Heavy vehicle  training", image: HeavyVehicletraining },
    ],
    prices: [
      { type: "Motorbike", price: 5500 },
      { type: "Threewheel", price: 6500 },
      { type: "Car (Auto)", price: 13000 },
      { type: "Car/Van Manual", price: 11000 },
      { type: "Heavy Vehicle", price: 13000 },
      { type: "Prime Mover", price: 20000 },
    ],
    // prices: {
    //   motorcycle: "5,500",
    //   threewheel: "6,500",
    //   carAuto: "13,000",
    //   carVanManual: "11,000",
    //   heavyVehicle: "13,000",
    //   primeMover: "20,000",
    // },
    branches: [
      {
        branchName: "Kesbewa Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
      {
        branchName: "Moratuwa Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
      {
        branchName: "Piliyandala Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
    ],
  },
  {
    name: "New Lanka Learners",
    city: "Kandy",
    description:
      "New Lanka Learners is a trusted driving school in Colombo Sri Lanka with over 50 years experience in teaching learners how to drive. We highly believe in Promoting Road safety",
    image: NewLankaLearners,
    rating: 4.2,
    hotLine: "0778877552",
    services: [
      { name: "Motorbike Training", image: "service1_image_url" },
      { name: "Home Visit training", image: "service2_image_url" },
      { name: "Auto Car training", image: "service1_image_url" },
      { name: "Manual  training ", image: "service2_image_url" },
      { name: "Refresher training", image: "service1_image_url" },
      { name: "Heavy vehicle  training", image: "service2_image_url" },
    ],
    prices: [
      { type: "Motorbike", price: 5500 },
      { type: "Threewheel", price: 6500 },
      { type: "Car (Auto)", price: 13000 },
      { type: "Car/Van Manual", price: 11000 },
      { type: "Heavy Vehicle", price: 13000 },
      { type: "Prime Mover", price: 20000 },
    ],

    branches: [
      {
        branchName: "Kesbewa Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
      {
        branchName: "Moratuwa Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
      {
        branchName: "Maradana Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
    ],
  },
  {
    name: "Perera Learners",
    city: "Kandy",
    description:
      "Perera Learners is a trusted driving school in colombo Sri Lanka with over 50 years experience in teaching learners how to drive. We highly believe in Promoting Road safety",
    image: PereraLearners,
    rating: 4.0,
    hotLine: "0778877552",
    services: [
      { name: "Motorbike Training", image: "service1_image_url" },
      { name: "Home Visit training", image: "service2_image_url" },
      { name: "Auto Car training", image: "service1_image_url" },
      { name: "Manual  training ", image: "service2_image_url" },
      { name: "Refresher training", image: "service1_image_url" },
      { name: "Heavy vehicle  training", image: "service2_image_url" },
    ],
    prices: [
      { type: "Motorbike", price: 5500 },
      { type: "Threewheel", price: 6500 },
      { type: "Car (Auto)", price: 13000 },
      { type: "Car/Van Manual", price: 11000 },
      { type: "Heavy Vehicle", price: 13000 },
      { type: "Prime Mover", price: 20000 },
    ],

    branches: [
      {
        branchName: "Kesbewa Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
      {
        branchName: "Moratuwa Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
      {
        branchName: "Mahara Branch",
        branchAddress: "57/E/B, Kaduwela,Boralesgamuwa.",
        branchContactNum: ["011 2 509133", "071 3145202"],
        email: "amallearner@gmail.com",
        regNo: "Reg.No: DS 382",
      },
    ],
  },
];
