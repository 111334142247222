
import './App.css';
import Router from './router/router';
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";
import {RootStore} from "./redux/RootStore";
import {Provider} from "react-redux";
import ReactGA from './analytics';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function App() {

  const location = useLocation();

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
      <Provider store={RootStore}>
    <div className="App">
      {/* <Paper/> */}
      <Router/>
    </div>
      </Provider>
  );
}

export default App;
