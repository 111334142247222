import React, { useState, useEffect} from "react";
import SLDLsinhala from "../../assets/images/sldl sinhala.png";
import SLDLTamil from "../../assets/images/SLDL Tamil.png";
import SLDLEnglish from "../../assets/images/SLDL English.png";
import SLDLIOS from "../../assets/images/SLDL IOS.png";
import PlayStore from "../../assets/images/Play Store.png";
import AppStore from "../../assets/images/App Store.png";
import OurApps from "../../Components/OurApps/OurApps";
import HomeButton from "../../Components/HomeButton/HomeButton";
import { englishQuiz } from "../../dummyData/englishQuestionsData";

function JoinPage() {

  const [language, setLanguage] = useState(englishQuiz);

  return (
    <div>
      <h2 className='mt-4 mb-3'>Join with our apps</h2>
      <h4>
        Please download the SLDL mobile applications from the following to continue
      </h4>
      <div className="p-4 m-5 bg-light border border-warning rounded">
        <div>
          <div className="container justify-content-center">
            <div className="row row-cols-2 align-items-center justify-content-center p-2">
              {/* ------------------------------------------------------------ */}
              <OurApps
                appIcon={SLDLsinhala}
                appName={"SLDL Sinhala"}
                platform={PlayStore}
                appLink={
                  "https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl&hl=en"
                }
              />

              <OurApps
                appIcon={SLDLTamil}
                appName={"SLDL Tamil"}
                platform={PlayStore}
                appLink={
                  "https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl.tm&hl=ta&pli=1"
                }
              />

              <OurApps
                appIcon={SLDLEnglish}
                appName={"SLDL English"}
                platform={PlayStore}
                appLink={
                  "https://play.google.com/store/apps/details?id=appinventor.aimechsita.sldl.en&hl=en"
                }
              />

              <OurApps
                appIcon={SLDLIOS}
                appName={"SLDL - for IOS"}
                platform={AppStore}
                appLink={
                  "https://apps.apple.com/sr/app/mechsit-sri-lanka-drivers-exam/id1599318476"
                }
              />

              {/* ------------------------------------------------------------- */}
            </div>
          </div>
        </div>
      </div>
      <div>
        <HomeButton home={language.homeButton} />
      </div>
    </div>
  );
}

export default JoinPage
