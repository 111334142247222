import React, { useState, useEffect } from "react";
import ChangePaperModal from "./PaperSelectModal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import card2 from "../../../src/assets/cardImages/paperIcon.png";
import arrow from "../../../src/assets/cardImages/Arrow.png";
import "./PaperShiftButtons.css";

function PaperShiftButtons(props) {
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedPaper, setSelectedPaper] = useState({
    label: "First Paper",
    value: "first",
  });

  useEffect(() => {
    const storedLabel = localStorage.getItem("paperLabel") || "First Paper";
    const storedValue = localStorage.getItem("paperValue") || "first";

    setSelectedPaper({
      label: storedLabel,
      value: storedValue,
    });
  }, []);

  const handleOpenChangePaperModal = (label, value) => {
    setIsModelOpen(true);
    localStorage.setItem("paperLabel", label);
    localStorage.setItem("paperValue", value);

    setSelectedPaper({
      label,
      value,
    });
  };

  const handleCloseChangePaperModal = () => {
    setIsModelOpen(false);
  };

  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-2">
      {["First", "Second", "Third", "Fourth"].map((item, index) => (
        <Col
          key={index}
          lg={3}
          md={6}
          sm={6}
          className="d-flex flex-column align-items-center justify-content-center px-4"
        >
          <button
            className="container col-12 m-4 button-design paper-button"
            onClick={() =>
              handleOpenChangePaperModal(`${item} Paper`, item.toLowerCase())
            }
          >
            <div className="jumbotron rounded">
              <img
                src={card2}
                className="img-fluid p-3"
                alt={`${item} Paper`}
              />
              <div className="justify-content-center d-flex">
                <h5 className="d-inline-flex p-3 papers-card-text">
                  {props[item]}
                  <img src={arrow} style={{ marginLeft: "20px" }} alt="arrow" />
                </h5>
              </div>
            </div>
          </button>
        </Col>
      ))}

      {isModelOpen && (
        <ChangePaperModal
          showModal={isModelOpen}
          handleClose={handleCloseChangePaperModal}
        />
      )}
    </div>
  );
}

export default PaperShiftButtons;
