import { apiSlice } from "../apiSlice";

export const membersService = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // getMembers: builder.mutation({
    //   query: ({ drivingSchoolId }) => {
    //     let url = `https://api-fyfkcojn3q-uc.a.run.app/drivingSchools/${encodeURIComponent(
    //       drivingSchoolId
    //     )}`;

    //     return {
    //       url,
    //       method: "GET",
    //       responseHandler: (response) => response.json(),
    //     };
    //   },
    // }),

    getMembersByStatus: builder.query({
      query: ({ drivingSchoolId, status }) => {
        return {
          url: `https://api-fyfkcojn3q-uc.a.run.app/api/members/getMembersByStatus?drivingSchoolId=${drivingSchoolId}&&status=${status}`,
          method: "GET",
          responseHandler: (response) => response.json(),
        };
      },
      providesTags: ["Members"],
    }),

    getNonMembersUsers: builder.query({
      query: ({ drivingSchoolId }) => {
        return {
          url: `https://api-fyfkcojn3q-uc.a.run.app/api/members/getNonMemberUsers?drivingSchoolId=${drivingSchoolId}`,
          method: "GET",
          responseHandler: (response) => response.json(),
        };
      },
      providesTags: ["Members"],
    }),

    addMemberToDrivingSchool: builder.mutation({
      query: (data) => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/api/members/addMember`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.text(),
      }),
    }),

    updateMembersByStatus: builder.mutation({
      query: (data) => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/api/members/updateMember`,
        method: "POST",
        body: data,
        responseHandler: (response) => response.text(),
      }),
    }),

    updateActiveMembers: builder.mutation({
      query: ({ drivingSchoolId, memberId, data }) => ({
        url: `https://api-fyfkcojn3q-uc.a.run.app/api/members/editActiveMember?drivingSchoolId=${drivingSchoolId}&memberId=${memberId}`,
        method: "PUT",
        body: data,
      }),
    }),

    // updateDrivingSchool: builder.mutation({
    //   query: ({ drivingSchoolId, data }) => ({
    //     url: `https://api-fyfkcojn3q-uc.a.run.app/drivingSchools/${drivingSchoolId}`,
    //     method: "PUT",
    //     body: data,
    //   }),
    // }),

    // deleteDrivingSchool: builder.mutation({
    //   query: ({ drivingSchoolId, data }) => ({
    //     url: `https://api-fyfkcojn3q-uc.a.run.app/drivingSchools/${drivingSchoolId}`,
    //     method: "DELETE",
    //     body: data,
    //   }),
    // }),
  }),
});

export const {
  useGetMembersByStatusQuery,
  useGetNonMembersUsersQuery,
  useAddMemberToDrivingSchoolMutation,
  useUpdateMembersByStatusMutation,
  useUpdateActiveMembersMutation,
} = membersService;
