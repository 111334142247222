import React from "react";
import { useNavigate } from "react-router-dom";
import { Navbar, Container } from "react-bootstrap";
import LanguageDropDown from "../../Components/LanguageDropDown/LanguageDropDown";
import profileImage from "../../assets/LearnersAuthReg/Profile.jpg";
import { FaBell, FaSignOutAlt } from "react-icons/fa";
import { useAuth } from "../AuthContext";
import "./TopNavAuth.css";
import Swal from "sweetalert2";

function TopNavAuth() {
  const { logout } = useAuth() || {};
  const navigate = useNavigate();

  const handleLogOut = () => {
    Swal.fire({
      title: "Are you sure you want to log out?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#590B21",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log out",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        navigate("/authPage");
      }
    });
  };

  return (
    <Navbar bg="light" expand="lg" className="topnav-auth">
      <Container fluid>
        <Navbar.Brand className="mx-auto">
          <h5>Amal Learners - Student Management System</h5>
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <div className="notification-wrapper">
            <FaBell className="notification-icon" />
          </div>

          <div className="profile-wrapper">
            <img src={profileImage} alt="Profile" className="profile-image" />
          </div>

          <div className="logout-wrapper" onClick={handleLogOut}>
            <FaSignOutAlt className="logout-icon" />
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default TopNavAuth;
