import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isPaper: false,
  isOBOPaper: false,
  setPaperDetails: null,
  answers: [],
  oBOanswers: [],
};

console.log("pape --------------slice  r");
export const paperSlice = createSlice({
  name: "Paper",
  initialState,
  reducers: {
    setIsPaper: (state, action) => {
      state.isPaper = action.payload;
      console.log("box");
    },
    setAnswers: (state, action) => {
      state.answers = action.payload;
    },
    setIsOBOPaper: (state, action) => {
      state.isOBOPaper = action.payload;
      console.log("box");
    },
    setOBOAnswers: (state, action) => {
      state.oBOanswers = action.payload;
    },

    resetPaper: (state) => {
      state.answers = [];
    },
  },
});

export const {
    setIsPaper, setAnswers,setIsOBOPaper,setOBOAnswers, resetPaper
} = paperSlice.actions;

export default paperSlice.reducer;

