import React from "react";
import { useLocation } from "react-router-dom"; 
import "./TopNav.css";
import { Navbar, Nav, Container } from "react-bootstrap";
import Logo from "../../assets/images/SLDL.png";
import LanguageDropDown from "../../Components/LanguageDropDown/LanguageDropDown";

function TopNav() {
  const location = useLocation(); 
  

  const handleNavLinkClick = () => {
    const toggleButton = document.querySelector(".navbar-toggler");
    if (
      toggleButton &&
      window.getComputedStyle(toggleButton).display !== "none"
    ) {
      toggleButton.click();
    }
  };


  return (
    <Navbar
      //bg="dark"
      expand="lg"
      className="justify-content-between pe-3 yellow-pill toplinks topNavNewStyle navbar navbar-expand-md bd-navbar"
    >
      <Container fluid>
        <Navbar.Brand href="/">
          {/* <h4 className="text-warning p-2 topNavNewStyleHedding">SLDL</h4> */}
          <div className="logo-wrapper">
            <img src={Logo} alt="logo" className="logo-image" />
          </div>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto align-items-center toplinks">
            <Nav.Link
              href="/papersPage"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/papersPage"
                  ? "yellow-pill nav-link"
                  : ""
              }
            >
              Papers
            </Nav.Link>
            <Nav.Link
              href="/aboutUs"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/aboutUs" ? "yellow-pill nav-link" : ""
              }
            >
              AboutUs
            </Nav.Link>
            <Nav.Link
              href="/contact"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/contact" ? "yellow-pill nav-link" : ""
              }
            >
              Contact
            </Nav.Link>
            {/* <Nav.Link
              href="/userDetailsForm"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/userDetailsForm"
                  ? "yellow-pill nav-link"
                  : ""
              }
            >
              Send Details
            </Nav.Link> */}
            <Nav.Link
              href="/learners"
              onClick={handleNavLinkClick}
              className={
                location.pathname === "/learners" ? "yellow-pill nav-link" : ""
              }
            >
              Learners
            </Nav.Link>
          </Nav>

          <LanguageDropDown />
        </Navbar.Collapse>
        <Navbar.Brand href="/">
          {/* <h4 className="text-warning p-2 topNavNewStyleHedding">SLDL</h4> */}
          <div className="user-profile-wrapper">
            <img src={Logo} alt="logo" className="user-profile-image" />
          </div>
        </Navbar.Brand>
      </Container>
    </Navbar>
  );
}

export default TopNav;
