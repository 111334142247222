import React from "react";
import "./OurApps.css";

function OurApps(props) {
  return (
    <div className="col-md-3 col-sm-6 align-items-stretch justify-content-between d-grid gap-2 d-md-flex">
      <div className="container row app-container">
        <div className="app-content align-items-stretch justify-content-between">
          <div>
            <img
              src={props.appIcon}
              className="img-fluid store-img p-2"
              alt={props.appName}
            />
          </div>
          <div className="col align-self-center justify-content-center">
            <h5>{props.appName}</h5>
          </div>
          <div className="col-md align-self-center">
            <div className="p-2">
              <img
                src={props.platform}
                className="img-fluid store-img storeLogImg expan-image"
                alt={props.platform}
              />
            </div>
            <a href={props.appLink}>
              <button type="button" className="btn btn-outline-warning">
                Download
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurApps;
