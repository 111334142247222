import React, { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Paper from "../views/quections/Paper";
import SpotPaper from "../views/spotQuections/SpotPaper";
import Home from "../views/Home/Home";
import AboutUs from "../views/AboutUs";
import Contact from "../views/Contact";
import PapersPage from "../views/PapersPage/PapersPage";
import UserDetailsForm from "../views/Forms/UserDetailsForm";
import Learners from "../views/Learners/Learners"
import DrivingSchool from "../views/DrivingSchool/DrivingSchool";
import AuthPage from "../AuthLearners/AuthPage";
import Admin from "../views/Admin/Admin";
import JoinPage from "../views/JoinPage/JoinPage";
import StudentProfile from "../views/Admin/ManageStudents/StudentProfile";
import ProtectedRoute from "./protectedRoute";
import Members from "../views/Admin/Members/Members";
import ManageStudents from "../views/Admin/ManageStudents/ManageStudents";
import EditProfile from "../views/Admin/EditProfile/EditProfile";
import AdminHome from "../views/Admin/AdminHome/AdminHome";

const Error = lazy(() => import("../views/Error"));
const Router = () => {

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/paper" element={<Paper />} />
          <Route path="/spotPaper" element={<SpotPaper />} />
          <Route path="/papersPage" element={<PapersPage />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/userDetailsForm" element={<UserDetailsForm />} />
          <Route path="/learners" element={<Learners />} />
          <Route
            path="/drivingSchool/:schoolName"
            element={<DrivingSchool />}
          />
          <Route path="/joinPage" element={<JoinPage />} />

          {/* !!!!!! ------------------ Do not delete this this is auth and admin routers ---------- !!!! */}
          
          <Route path="/authPage" element={<AuthPage />} />

          {/* ------------- Admin Section Routes ------------------------------ */}
          <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <Admin />
                </ProtectedRoute>
              }
            >
              <Route path="/admin" element={<AdminHome />} />
              <Route path="/admin/members" element={<Members />} />
              <Route path="/admin/manageStudent" element={<ManageStudents />} />
              <Route path="/admin/editProfile" element={<EditProfile />} />
              <Route
                path="/admin/studentProfile/:memberID"
                element={<StudentProfile />}
              />
            </Route>
          {/* ------------- Admin Section Routes ------------------------------ */}

          {/* !!!!!! ------------------ Do not delete this this is auth and admin routers ---------- !!!! */}
        </Routes>
      </Suspense>
    </div>
  );
};

export default Router
