import img1 from "../assets/quectionsImages/1.jpg";
import img2 from "../assets/quectionsImages/2.jpg";
import img3 from "../assets/quectionsImages/3.jpg";
import img4 from "../assets/quectionsImages/4.jpg";
import img5 from "../assets/quectionsImages/5.jpg";
import img6 from "../assets/quectionsImages/6.jpg";
import img7 from "../assets/quectionsImages/7.jpg";
import img8 from "../assets/quectionsImages/8.jpg";
import img9 from "../assets/quectionsImages/9.jpg";
import img10 from "../assets/quectionsImages/10.jpg";
import img11 from "../assets/quectionsImages/11.jpg";
import img12 from "../assets/quectionsImages/12.jpg";
import img13 from "../assets/quectionsImages/13.jpg";
import img14 from "../assets/quectionsImages/14.jpg";
import img15 from "../assets/quectionsImages/15.jpg";
import img16 from "../assets/quectionsImages/16.jpg";
import img17 from "../assets/quectionsImages/17.jpg";
import img18 from "../assets/quectionsImages/18.jpg";
import img19 from "../assets/quectionsImages/19.jpg";
import img20 from "../assets/quectionsImages/20.jpg";
import img21 from "../assets/quectionsImages/21.jpg";
import img22 from "../assets/quectionsImages/22.jpg";
import img23 from "../assets/quectionsImages/23.jpg";
import img24 from "../assets/quectionsImages/24.jpg";
import img25 from "../assets/quectionsImages/25.jpg";
import img26 from "../assets/quectionsImages/26.jpg";
import img27 from "../assets/quectionsImages/27.jpg";
import img28 from "../assets/quectionsImages/28.jpg";
import img29 from "../assets/quectionsImages/29.jpg";
import img30 from "../assets/quectionsImages/30.jpg";
import img31 from "../assets/quectionsImages/31.jpg";
import img32 from "../assets/quectionsImages/32.jpg";
import img33 from "../assets/quectionsImages/33.jpg";
import img34 from "../assets/quectionsImages/34.jpg";
import img35 from "../assets/quectionsImages/35.jpg";
import img36 from "../assets/quectionsImages/36.jpg";
import img37 from "../assets/quectionsImages/37.jpg";
import img38 from "../assets/quectionsImages/38.jpg";
import img39 from "../assets/quectionsImages/39.jpg";
import img40 from "../assets/quectionsImages/40.jpg";
import img44 from "../assets/quectionsImages/44.jpg";
import img45 from "../assets/quectionsImages/45.jpg";
import img46 from "../assets/quectionsImages/46.jpg";
import img47 from "../assets/quectionsImages/47.jpg";
import img48 from "../assets/quectionsImages/48.jpg";
import img49 from "../assets/quectionsImages/49.jpg";
import img50 from "../assets/quectionsImages/50.jpg";
import img51 from "../assets/quectionsImages/51.jpg";
import img52 from "../assets/quectionsImages/52.jpg";
import img53 from "../assets/quectionsImages/53.jpg";
import img54 from "../assets/quectionsImages/54.jpg";
import img55 from "../assets/quectionsImages/55.jpg";
import img56 from "../assets/quectionsImages/56.jpg";
import img57 from "../assets/quectionsImages/57.jpg";
import img58 from "../assets/quectionsImages/58.jpg";
import img59 from "../assets/quectionsImages/59.jpg";
import img60 from "../assets/quectionsImages/60.jpg";
import img61 from "../assets/quectionsImages/61.jpg";
import img62 from "../assets/quectionsImages/62.jpg";
import img63 from "../assets/quectionsImages/63.jpg";
import img64 from "../assets/quectionsImages/64.jpg";
import img65 from "../assets/quectionsImages/65.jpg";
import img66 from "../assets/quectionsImages/66.jpg";
import img67 from "../assets/quectionsImages/67.jpg";
import img68 from "../assets/quectionsImages/68.jpg";
import img69 from "../assets/quectionsImages/69.jpg";
import img70 from "../assets/quectionsImages/70.jpg";
import img71 from "../assets/quectionsImages/71.jpg";

export const tamilQuiz = {
  totalQuestions: 40,
  score: "மதிப்பெண்",
  correctAnswers: "சரியான பதில்கள்",
  wrongAnswers: "தவறான பதில்கள்",
  didntAnswers: "பதில் அளிக்கவில்லை",
  answerAllQuestions: "அனைத்து கேள்விகளுக்கும் பதிலளிக்கவும்",
  timeRemainings: "மீதியுள்ள நேரம்",
  total: "கேள்விகளின் எண்ணிக்கை",
  popTitle: "தேர்வைத் தொடங்க நீங்கள் தயாரா?",
  popText: "தொடங்குவதற்கு ஆம் என்பதைக் கிளிக் செய்யவும்.",
  popButtonText: "ஆம், இப்போதே தொடங்குங்கள்!",
  submitBtn: "பதில்களை வழங்கி முடிப்பேன்.",
  homeButton: "முகப்பு பக்கம்",

  questionArrayFirst: [
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	இரட்டை நாட்களில் வாகனங்கள் உட்பிரவேசிப்பது தடைசெய்யப்படடுள்ளது	",
        "	ஒற்றை நாட்களில் வாகனங்கள் உட்பிரவேசிப்பது தடைசெய்யப்படடுள்ளது	",
        "	இரட்டை நாட்களில் வாகனங்கள் நிறுத்துவது தடைசெய்யப்படடுள்ளது	",
        "	ஒற்றை நாட்களில் வாகனங்கள் நிறுத்துவது தடைசெய்யப்படடுள்ளது	",
      ],
      questionId: 1,
      correct: "	இரட்டை நாட்களில் வாகனங்கள் நிறுத்துவது தடைசெய்யப்படடுள்ளது	",
      image: img1,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் நிறுத்தவும்	",
        "	குறுக்காகச் செல்லும் வீதிகள் முன்னால்	",
        "	கட்டாய சுற்றுவட்டம் முன்னால	",
        "	சுற்றுவட்டம் முன்னால்	",
      ],
      questionId: 2,
      correct: "	சுற்றுவட்டம் முன்னால்	",
      image: img2,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வீதியில் வழி விடவும்	",
        "	கட்டாய முக்கோண வடிவ சந்தி	",
        "	முன்னால் வீதியில் வழிவிடவும்	",
        "	பிரதான வீதி முன்னால்	",
      ],
      questionId: 3,
      correct: "	வீதியில் வழி விடவும்	",
      image: img3,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	விலங்குகள் சரணாலயம் முன்னால்	",
        "	வளர்க்கும் பன்னை முன்னால்	",
        "	மந்தைகள் மற்றும் ஏனைய விலங்குகள் முன்னால் வீதியைக் கடக்கக	",
        "	கூடும் மந்தைகள் வீதியை கடப்பதற்கு ஒதுக்கப்பட்டுள்ள இடம் முன்னால்	",
      ],
      questionId: 4,
      correct: "	மந்தைகள் மற்றும் ஏனைய விலங்குகள் முன்னால் வீதியைக் கடக்கக	",
      image: img4,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வலது பக்கமாக இரட்டை வளைவு முன்னால	",
        "	ரு வடிவிற்கு திருப்ப முடியுமான இடம் முன்னால்	",
        "	வலது பக்கமாக வளைவு முன்னால்	",
        "	வலது பக்கமாக கொண்டை ஊசி வடிவ வளைவு முன்னால்	",
      ],
      questionId: 5,
      correct: "	வலது பக்கமாக கொண்டை ஊசி வடிவ வளைவு முன்னால்	",
      image: img5,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	நாற்சந்தி முன்னால்	",
        "	முந்துரிமைப் பாதை	",
        "	முந்துரிமைப் பாதை முன்னால்	",
        "	சந்திக் கோடு	",
      ],
      questionId: 6,
      correct: "	முந்துரிமைப் பாதை	",
      image: img6,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	இடது பக்கத்தால் வாகனங்கள் பிரதான வீதிக்கு இணையும சந்தி முன்னால்	",
        "	ஆரம்பத்தில் இடது பக்கத்திற்கு செல்லும் சிறிய சந்தி முன்னால்	",
        "	இடது பக்கத்தால் வரும் வாகனங்களுக்கு முந்துரிமை வழங்குக	",
        "	லு வடிவ சந்தி முன்னால்	",
      ],
      questionId: 7,
      correct:
        "	இடது பக்கத்தால் வாகனங்கள் பிரதான வீதிக்கு இணையும சந்தி முன்னால்	",
      image: img7,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	பிள்ளைகள் கடக்கும இடம்	",
        "	கண்பார்வை அற்றோர் கடக்கும இடம் முன்னால்	",
        "	பிள்ளைகள் கடக்கும இடம் முன்னால்	",
        "	பாடசாலை முன்னால்	",
      ],
      questionId: 8,
      correct: "	பிள்ளைகள் கடக்கும இடம் முன்னால்	",
      image: img8,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	பாதசாரிகள் கடக்கும் இடம்	",
        "	பொலிஸ் நிலையம்	",
        "	எரிபொருள் நிரப்பும் இடம்	",
        "	வாகனங்கள் நிறுத்தும் இடம்	",
      ],
      questionId: 9,
      correct: "	வாகனங்கள் நிறுத்தும் இடம்	",
      image: img9,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வலது பக்கமாக இரட்டை வளைவு முன்னால்	",
        "	முன்னால் வலது பக்கத்திற்கு திரும்பவும்	",
        "	வலது பக்க வளைவு முன்னால்	",
        "	வலது பக்க கொண்டை ஊசி வடிவ வளைவு முன்னால்	",
      ],
      questionId: 10,
      correct: "	வலது பக்க வளைவு முன்னால்	",
      image: img10,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	தடைசெய்யப்பட்ட ஒரு சமிக்ஞை	",
        "	வரையறுக்கப்பட்ட ஒரு சமிக்ஞை	",
        "	ஒரு கட்டளை சமிக்ஞை	",
        "	ஆபத்தை குறிக்கும் ஒரு சமிக்ஞை	",
      ],
      questionId: 11,
      correct: "	வரையறுக்கப்பட்ட ஒரு சமிக்ஞை	",
      image: img11,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	சகல வாகனங்களுக்கும் வீதி மூடப்பட்டுள்ளது	",
        "	ஒற்றை நாட்களில் வாகனம் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
        "	நிறுத்தல் மற்றும் ஏற்றல் தடை செய்யப்பட்டுள்ளது	",
        "	வாகனங்கள் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
      ],
      questionId: 12,
      correct: "	வாகனங்கள் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
      image: img12,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	அச்சாணி ஒன்றின் மீது ஏற்ற முடியுமான நிறை அளவு	",
        "	வேக எல்லையின் முடிவு	",
        "	வேக எல்லையின் ஆரம்பம்	",
        "	வேக எல்லை	",
      ],
      questionId: 13,
      correct: "	வேக எல்லையின் முடிவு	",
      image: img13,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	பாதசாரிகளுக்கென ஒதுக்கப்பட்ட வீதியின் ஆரம்பம	",
        "	பாதசாரிகளுக்கு வழி விடவும்	",
        "	பாதசாரிகள் கடக்கும் இடம்	",
        "	பாதசாரிகள் கடவை முன்னால்	",
      ],
      questionId: 14,
      correct: "	பாதசாரிகள் கடக்கும் இடம்	",
      image: img14,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வீதி இலகுவில் வழுக்கும் இடம் முன்னால்	",
        "	மேல நோக்கி ஆபத்தான சரிவு முன்னால்	",
        "	கார் வண்டிகள் இலகுவில் வழுக்கும் இடம் முன்னால்	",
        "	கீழ்நோக்கி ஆபத்தான சரிவு முன்னால்	",
      ],
      questionId: 15,
      correct: "	கீழ்நோக்கி ஆபத்தான சரிவு முன்னால்	",
      image: img15,
    },
    {
      question: "	இவ் வீதிச் சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	லு  வடிவ சந்தி முன்னால்	",
        "	ஒடுங்கிய வீதி முன்னால்	",
        "	பிரதான விதிக்கு இரு பக்கங்களிலிருந்தும் வாகனங்கள் நுழையும் சந்தி முன்னால்	",
        "	இரட்டை வழி வீதியின் ஆரம்பம் முன்னால்	",
      ],
      questionId: 16,
      correct: "	லு  வடிவ சந்தி முன்னால்	",
      image: img16,
    },
    {
      question: "	படத்தில் காட்டப்பட்டுள்ள சந்தியில்	",
      answers: [
        "	மோட்டார கார் கட்டாயமாக முன்னால் செல்லல் வேண்டும்	",
        "	முச்சக்கர வண்டிக்கு நேராக முன்னால் செல்ல முடியும்	",
        "	முச்சக்கர வண்டியை வலது பக்கத்திற்கு திருப்பவோ அல்லது நேராக செலுத்தவோ முடியும்	",
        "	மோட்டார் காரை நேராக முன்னால் செலுத்தவோ அல்லது வலது பக்கத்திற்கு திருப்பவோ முடியும்	",
      ],
      questionId: 17,
      correct: "	மோட்டார கார் கட்டாயமாக முன்னால் செல்லல் வேண்டும்	",
      image: img17,
    },
    {
      question:
        "	இங்குள்ள சந்தியில் இடது பக்கத்திற்கு திருப்புவதற்கு என்னுவதாயின் முறையே யுஇடீ       மற்றும் ஊ  ஆகிய இடங்களில் செய்யவேண்டியது	",
      answers: [
        "	தீர்மானித்தல்ää சமிக்ஞையிடல் மற்றும் செயற்படுத்தல்	",
        "	அவதானித்தல்ää தீர்மானித்தல் மற்றும் செயற்படுத்தல்	",
        "	சமிக்ஞையிடல்ää தீர்மானித்தல் மற்றும் செயற்படுத்தல்	",
        "	மேலுள்ள அனைத்தும் சரியானவை	",
      ],
      questionId: 18,
      correct: "	அவதானித்தல்ää தீர்மானித்தல் மற்றும் செயற்படுத்தல்	",
      image: img18,
    },
    {
      question:
        "	காட்டப்படடுள்ள மோட்டார் வாகன வீதி சமிக்ஞை விளக்குகளில் அடுத்து எரியும் நிறம் யாது?	",
      answers: [
        "	சிவப்பு மற்றும் மஞ்சள்	",
        "	சிவப்பு	",
        "	பச்சை மற்றும் மஞ்சள்	",
        "	பச்சை	",
      ],
      questionId: 19,
      correct: "	சிவப்பு	",
      image: img19,
    },
    {
      question: "	காட்டப்பட்டுள்ள பொலிஸ் உத்தியோகத்தரின் கட்டளை யாது?	",
      answers: [
        "	முன்னால் மற்றும் பின்னால் வரும் சகல வாகனங்களும் நிறுத்துக	",
        "	பின்னால் வரும் வாகனங்கள் நிறுத்துக	",
        "	நிறுத்துக	",
        "	முன்னால் வரும் வாகனங்கள் நிறுத்துக	",
      ],
      questionId: 20,
      correct: "	முன்னால் மற்றும் பின்னால் வரும் சகல வாகனங்களும் நிறுத்துக	",
      image: img20,
    },
    {
      question:
        "	வீதியில் வரையப்பட்டுள்ள இவ்வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வீதி ஒழுக்கு சமிக்ஞை	",
        "	மத்திய ரேகை	",
        "	வலது பக்கத்திற்கு திருப்புவது தவிர்ந்த குறுக்காக பயனிப்பதை தடை செய்யும் இரட்டை ரேகை	",
        "	வீதிக்கு குறுக்காக பயனிப்பதை தடை செய்யும் இரட்டை ரேகை	",
      ],
      questionId: 21,
      correct: "	வீதிக்கு குறுக்காக பயனிப்பதை தடை செய்யும் இரட்டை ரேகை	",
      image: img21,
    },
    {
      question: "	வாகன ஒளி சமிக்ஞைகள் உள்ள இச்சந்தியில்	",
      answers: [
        "	வேன் வண்டியை தேவையெனின் இடது பக்கம் திருப்பலாம்	",
        "	வேன் வண்டியை கட்டாயமாக வலது பக்கத்துக்கு திருப்ப வேண்டும்	",
        "	மோட்டார் காரை தேவையெனின் வலது பக்கம் திருப்பலாம்	",
        "	மோட்டார் காரை தேவையெனின் இடது பக்கம் திருப்பலாம்	",
      ],
      questionId: 22,
      correct: "	மோட்டார் காரை தேவையெனின் வலது பக்கம் திருப்பலாம்	",
      image: img22,
    },
    {
      question:
        "	வீதியில் குறுக்காக வரையப்பட்டுள்ள இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வீதியில் வழி விடும் ரேகை	",
        "	வீதி பிரியும் இடமொன்றிலுள்ள நிறுத்தும் ரேகை	",
        "	நிறுத்து சமிக்ஞையில் உள்ள நிறுத்து ரேகை	",
        "	சுற்றுவட்டத்தில் உள்ள வீதியில் வழி விடும் ரேகை	",
      ],
      questionId: 23,
      correct: "	சுற்றுவட்டத்தில் உள்ள வீதியில் வழி விடும் ரேகை	",
      image: img23,
    },
    {
      question:
        "	படத்தில் காட்டப்பட்டுள்ள வு வடிவ சந்திக்கு பிரவேசிக்கும் இச் சாரதி தனது வாகனத்தைää	",
      answers: [
        "	பிரதான வீதியில் வாகனங்கள் இல்லாத போது நிறுத்தவேண்டிய அவசியமில்லை	",
        "	கட்டாயமாக நிறுத்த வேண்டும்	",
        "	வலது பக்கத்திற்கு திருப்புவதாயின் மாத்திரம் நிறுத்தல் வேண்டும்.	",
        "	இடது பக்கத்திற்கு திருப்புகின்ற போது நிறுத்த அவசியமில்லை	",
      ],
      questionId: 24,
      correct: "	கட்டாயமாக நிறுத்த வேண்டும்	",
      image: img24,
    },
    {
      question:
        "	வீதியில் குறுக்காக வரையப்பட்டுள்ள இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வீதியில் வழி விடும் ரேகை	",
        "	மோட்டார் வாகன வீதி சமிக்ஞை விளக்கு ஒளியின் போது அல்லது பொலிஸாரினால் நிருவகிக்கப்படும் இடங்களுக்கு இடையில் உள்ள நிறுத்தும் ரேகை	",
        "	நிறுத்து சமிக்ஞையில் உள்ள நிறுத்து ரேகை	",
        "	சுற்றுவட்டத்தில் உள்ள வீதியில் வழி விடும் ரேகை	",
      ],
      questionId: 25,
      correct:
        "	மோட்டார் வாகன வீதி சமிக்ஞை விளக்கு ஒளியின் போது அல்லது பொலிஸாரினால் நிருவகிக்கப்படும் இடங்களுக்கு இடையில் உள்ள நிறுத்தும் ரேகை	",
      image: img25,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் வீதி மூடப்பட்டுள்ளது	",
        "	புகையிரத குறுக்கு வீதி முன்னால்	",
        "	பாதுகாப்பற்ற புகையிரத குறுக்கு வீதி	",
        "	பாதுகாப்பற்ற புகையிரத குறுக்கு வீதி முன்னால்	",
      ],
      questionId: 26,
      correct: "	பாதுகாப்பற்ற புகையிரத குறுக்கு வீதி முன்னால்	",
      image: img26,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	கட்டாய சுற்று வட்டம	",
        "	உட்பிரவேசித்தல் தடைசெய்யப்பட்டுள்ளது	",
        "	வசு மற்றும் லொறிகளுக்கு வீதி மூடப்பட்டுள்ளது	",
        "	வீதி முடப்பட்டுள்ளது	",
      ],
      questionId: 27,
      correct: "	வீதி முடப்பட்டுள்ளது	",
      image: img27,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	இலகுவில் வழுக்கும் வீதி முன்னால்	",
        "	வளைவுகளுடன் கூடிய வீதி முன்னால்	",
        "	அபாயகரமான சந்தி முன்னால்	",
        "	முன்னால் வீதி ஒடுக்கம்	",
      ],
      questionId: 28,
      correct: "	இலகுவில் வழுக்கும் வீதி முன்னால்	",
      image: img28,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	லு வடிவ சந்தி முன்னால்	",
        "	முன்னால் வீதி ஒடுக்கம்	",
        "	இரு வழி வாகனப் பாதையின் முடிவு முன்னால்	",
        "	ஒடுங்கிய பாலம் முன்னால்	",
      ],
      questionId: 29,
      correct: "	இரு வழி வாகனப் பாதையின் முடிவு முன்னால்	",
      image: img29,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	கட்டாய சுற்று வட்டம் முன்னால்	",
        "	கட்டாய சுற்று வட்டம்	",
        "	சுற்று வட்டம் முன்னால்	",
        "	சுற்று வட்டம்	",
      ],
      questionId: 30,
      correct: "	கட்டாய சுற்று வட்டம்	",
      image: img30,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	இடது பக்கத்திற்கு திருப்பி நிறுத்தவும்	",
        "	இடது பக்கத்திற்கு திருப்ப வேண்டும்	",
        "	இடது பக்கத்துக்கு திருப்புவதற்கு முந்துரிமை	",
        "	முன்னால் இடது பக்கத்திற்கு திருப்ப வேண்டும்	",
      ],
      questionId: 31,
      correct: "	முன்னால் இடது பக்கத்திற்கு திருப்ப வேண்டும்	",
      image: img31,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	ஒரு தகவல் சமிக்ஞை	",
        "	ஒரு எச்சரிக்கை சமிக்ஞை	",
        "	ஒரு தடைசெய்யப்பட்ட சமிக்ஞை	",
        "	ஒரு கட்டளை சமிக்ஞை	",
      ],
      questionId: 32,
      correct: "	ஒரு தடைசெய்யப்பட்ட சமிக்ஞை	",
      image: img32,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	இடது பக்கத்திற்கு இரட்டை வளைவு முன்னால்	",
        "	இடது பக்கத்திற்கு கொண்டை ஊசி வடிவ வளைவு முனனால்	",
        "	இடது பக்கத்திற்கு வளைவு முன்னால்	",
        "	ரு வடிவில் திருப்ப முடியுமான இடம் முன்னால்	",
      ],
      questionId: 33,
      correct: "	இடது பக்கத்திற்கு கொண்டை ஊசி வடிவ வளைவு முனனால்	",
      image: img33,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	சகல வாகனங்களுக்கும் வீதி மூடப்பட்டுள்ளது	",
        "	வீதி மூடப்பட்டுள்ளது	",
        "	கார் மற்றும் துவிச்சக்கர வண்டிகளுக்கு வீதி மூடப்பட்டுள்ளது	",
        "	கார் மற்றும் துவிச்சக்கர வண்டிகள் உட்பிரவேசித்தல் தடை செய்யப்பட்டுள்ளது	",
      ],
      questionId: 34,
      correct: "	சகல வாகனங்களுக்கும் வீதி மூடப்பட்டுள்ளது	",
      image: img34,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	சுற்றுவட்டம் முன்னால்	",
        "	வாகனங்கள் நிறுத்தும் இடம்	",
        "	நிறுத்து	",
        "	முன்னால் நிறுத்து	",
      ],
      questionId: 35,
      correct: "	முன்னால் நிறுத்து	",
      image: img35,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	இரு வழி வாகனப் பாதை ஆரம்பம் முன்னால்	",
        "	முன்னால் வீதி ஒடுக்கம்	",
        "	இரு வழி வாகனப் பாதை முடிவு முன்னால்	",
        "	ஒடுங்கிய பாலம் முன்னால்	",
      ],
      questionId: 36,
      correct: "	முன்னால் வீதி ஒடுக்கம்	",
      image: img36,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வேகத்தின் எல்லை	",
        "	சாதாரண வேகம்	",
        "	ஆகக்குறைந்த வேகம்	",
        "	நகர எல்லையினுள் வேகத்தின் அளவு	",
      ],
      questionId: 37,
      correct: "	வேகத்தின் எல்லை	",
      image: img37,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் சந்தியொன்று	",
        "	ஒற்றை நாட்களில் நிறுத்தல் மற்றும் ஏற்றல் தடைசெய்யப்பட்டுள்ளது	",
        "	இரட்டை நாட்களில் நிறுத்தல் மற்றும் ஏற்றல் தடைசெய்யப்பட்டுள்ளது	",
        "	நிறுத்தல் மற்றும் ஏற்றல் தடைசெய்யப்பட்டுள்ளது	",
      ],
      questionId: 38,
      correct: "	நிறுத்தல் மற்றும் ஏற்றல் தடைசெய்யப்பட்டுள்ளது	",
      image: img38,
    },
    {
      question:
        "	காட்டப்படடுள்ள மோட்டார் வாகன வீதி சமிக்ஞை விளக்குகளில் அடுத்து எரியும் நிறம் யாது	",
      answers: [
        "	பச்சை	",
        "	மஞ்சள்	",
        "	சிவப்பு மற்றும் மஞ்சள்	",
        "	மஞ்சள் மற்றும் பச்சை	",
      ],
      questionId: 39,
      correct: "	சிவப்பு மற்றும் மஞ்சள்	",
      image: img39,
    },
    {
      question: "	இவ் வீதி சமிக்ஞையினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் வரும் வாகனங்களக்கு முந்துரிமை முடிவு	",
        "	உட்பிரவேசிப்பது தடைசெய்யப்பட்டுள்ளது	",
        "	வாகனங்களை முந்துதல் தடைசெய்யப்பட்டுள்ளது	",
        "	இரட்டை நாட்களில் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
      ],
      questionId: 40,
      correct: "	வாகனங்களை முந்துதல் தடைசெய்யப்பட்டுள்ளது	",
      image: img40,
    },
  ],

  questionArraySecond: [
    {
      question: "	காட்டப்பட்டுள்ள பொலிஸ் உத்தியோகத்தரின் கட்டளை யாது?	",
      answers: [
        "	பின்னால் வரும் வாகனங்கள் நிறுத்துக	",
        "	முன்னால் மற்றும் பின்னால் வரும் வாகனங்கள் நிறுத்துக	",
        "	முன்னால் வருக	",
        "	நிறுத்துக	",
      ],
      questionId: 1,
      correct: "	நிறுத்துக	",
      image: img44,
    },
    {
      question:
        "	வீதியில் குறுக்காக வரையப்பட்டுள்ள இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வழிவிடும் ரேகை	",
        "	மோட்டார் வாகன ஒளி சமிக்ஞைகள் அல்லது பொலிஸாரினால் நிருவகிக்கப்படும் வீதி பிரிந்து செல்லும் இடமொன்றில் உள்ள நிறுத்தும் ரேகை	",
        "	நிறுத்தும் சமிக்ஞைக்கு அண்மையிலுள்ள நிறுத்தும் ரேகை	",
        "	“சுற்று வளைவுக்கு” அண்மையிலுள்ள வழிவிடும் ரேகை	",
      ],
      questionId: 2,
      correct:
        "	மோட்டார் வாகன ஒளி சமிக்ஞைகள் அல்லது பொலிஸாரினால் நிருவகிக்கப்படும் வீதி பிரிந்து செல்லும் இடமொன்றில் உள்ள நிறுத்தும் ரேகை	",
      image: img45,
    },
    {
      question:
        "	வீதியில் குறுக்காக வரையப்பட்டுள்ள இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வீதி பிரியும் இடமொன்றில் அமைந்துள்ள நிறுத்தும் ரேகை	",
        "	சுற்றுவட்டமொன்றில் அமைந்தள்ள வழி விடும் ரேகை	",
        "	நிறுத்தும் சமிக்ஞையில் அமைந்துள்ள நிறுத்தும் ரேகை	",
        "	வீதியில் வழி விடும் ரேகை	",
      ],
      questionId: 3,
      correct: "	சுற்றுவட்டமொன்றில் அமைந்தள்ள வழி விடும் ரேகை	",
      image: img46,
    },
    {
      question: "	வீதியில் வரையப்பட்டுள்ள இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வீதி ஒழுக்கு குறியீடு	",
        "	வீதி ஊடாக பயனிப்பதை தடை செய்யும் ரேகை	",
        "	வலது பக்கத்திற்கு திருப்புதல் தவிர குறுக்காக செல்வதை தடைசெய்யும் ரேகை	",
        "	மத்திய ரேகை	",
      ],
      questionId: 4,
      correct:
        "	வலது பக்கத்திற்கு திருப்புதல் தவிர குறுக்காக செல்வதை தடைசெய்யும் ரேகை	",
      image: img47,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	இரு வழிப்பாதை முன்னால் உண்டு	",
        "	ஒடுங்கிய பாலம	",
        "	முன்னால் உள்ள வீதி ஒடுக்கம	",
        "	ஒடுங்கிய பாலம் முன்னால் உண்டு	",
      ],
      questionId: 5,
      correct: "	ஒடுங்கிய பாலம் முன்னால் உண்டு	",
      image: img48,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	பாதசாரிகள் கடக்கும் இடம் முன்னால் உண்டு	",
        "	பாதசாரிகள் கடவை முன்னால் உண்டு	",
        "	பயனிகள் கடக்கும இடம் முன்னால் உண்டு	",
        "	பிள்ளைகள் கடக்கும் இடம் முன்னால் உணடு	",
      ],
      questionId: 6,
      correct: "	பாதசாரிகள் கடக்கும் இடம் முன்னால் உண்டு	",
      image: img49,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	ஒற்றை நாட்களில் உட்பிரவேசிப்பது தடைசெய்யப்பட்டுள்ளது	",
        "	ஒற்றை நாட்களில் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
        "	உட்பிரவேசிப்பது தடைசெய்யப்பட்டுள்ளது	",
        "	மாற்றுத் திசையிலிருந்து வாகனங்கள் வரமுடியாது	",
      ],
      questionId: 7,
      correct: "	உட்பிரவேசிப்பது தடைசெய்யப்பட்டுள்ளது	",
      image: img50,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	பிரதான வீதி முன்னால் உண்டு என்பதாகும	",
        "	முன்னால் வழி விடுக என்பதாகும	",
        "	முன்னால் வழி விடுக என்பதாகும்	",
        "	வீதியில் வழி விடுக என்பதாகும	",
      ],
      questionId: 8,
      correct: "	வீதியில் வழி விடுக என்பதாகும	",
      image: img51,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வாகனங்கள் உட்பிரவேசிப்பது மட்டுப்படுத்தப்பட்டுள்ளது	",
        "	முன்னால் வழி விடுக	",
        "	முந்துரிமை வீதி	",
        "	முந்துரிமை வீதியின் முடிவு	",
      ],
      questionId: 9,
      correct: "	முந்துரிமை வீதியின் முடிவு	",
      image: img52,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் பிரதான வீதிக்கு உட்பிரவேசிப்பு	",
        "	முன்னால் வரும் வாகனங்களுக்கு முந்துரிமை வழங்கல் வேண்டும்	",
        "	முன்னால் செல்லும் வாகனங்களுக்கு முந்துரிமை வழங்கல் வேண்டும்	",
        "	முன்னால் செல்லும் வாகனங்களுக்கு முந்துரிமை வழங்கல் வேண்டும்	",
      ],
      questionId: 10,
      correct: "	முன்னால் செல்லும் வாகனங்களுக்கு முந்துரிமை வழங்கல் வேண்டும்	",
      image: img53,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	புகையிரதம் முன்னால் வரக்கூடும் என்பதால் எச்சரிக்கையாக இருக்கவும்	",
        "	புகையிரதம் குறித்து எச்சரிக்கையாக இருக்கவும	",
        "	பாதுகாப்பற்ற புகையிரத குறுக்கு வீதி	",
        "	பாதுகாப்பற்ற புகையிரத குறுக்கு வீதி முன்னால்	",
      ],
      questionId: 11,
      correct: "	பாதுகாப்பற்ற புகையிரத குறுக்கு வீதி முன்னால்	",
      image: img54,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் வீதி முடிவடைகிறது	",
        "	கொண்டை ஊசி வடிவ வளைவு முன்னால	",
        "	ரு வடிவத்திற்கு திருப்புவது தடைசெய்யப்பட்டுள்ளது	",
        "	ழே சiபாவ வரசn	",
      ],
      questionId: 12,
      correct: "	ரு வடிவத்திற்கு திருப்புவது தடைசெய்யப்பட்டுள்ளது	",
      image: img55,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	சுற்றுவட்டம் முன்னால் உள்ளது	",
        "	மின் ஒளி விளக்கு சமிக்ஞை	",
        "	நிறுத்து	",
        "	வழி விடுக	",
      ],
      questionId: 13,
      correct: "	நிறுத்து	",
      image: img56,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	நகரப் பிரதேசமொன்றுக்குள் உட்பிரவேசித்தல்	",
        "	அப்பிரதேசத்தினுள் வாகனம் செலுத்த முடியுமான அதி கூடிய வேகம்	",
        "	அப்பிரதேசத்தினுள் வாகனம் செலுத்த முடியுமான சாதாரண வேகம்	",
        "	குறிப்பிடப்பட்டுள்ள வேக எல்லையின் முடிவு	",
      ],
      questionId: 14,
      correct: "	அப்பிரதேசத்தினுள் வாகனம் செலுத்த முடியுமான அதி கூடிய வேகம்	",
      image: img57,
    },
    {
      question: "	 இச்சமிக்ஞை	",
      answers: [
        "	தடைசெய்யப்பட்ட ஒரு சமிக்ஞை	",
        "	எச்சரிக்கை விடுக்கும் ஒரு சமிக்ஞை	",
        "	கட்டுப்பாட்டு சமிக்ஞை	",
        "	தகவல் சமிக்ஞை	",
      ],
      questionId: 15,
      correct: "	தகவல் சமிக்ஞை	",
      image: img58,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	புகையிரத வீதி முன்னால் உண்டு	",
        "	வைத்தியசாலை முன்னால் உண்டு	",
        "	பள்ளிவாசல் ஒன்று முன்னால	",
        "	குறுக்காக செல்லும் வீதிகள் முன்னால்	",
      ],
      questionId: 16,
      correct: "	குறுக்காக செல்லும் வீதிகள் முன்னால்	",
      image: img59,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	வலது பக்கத்திற்கு திரும்பவும்	",
        "	இடது பக்கமாக வளைவு முன்னால்	",
        "	வலது பக்கமாக வளைவு முன்னால்	",
        "	இடது பக்கத்திற்கு திரும்பவும்	",
      ],
      questionId: 17,
      correct: "	இடது பக்கமாக வளைவு முன்னால்	",
      image: img60,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	லு வடிவ சந்தி முன்னால் உண்டு;	",
        "	பிரதான வீதி முன்னால் உண்டு;	",
        "	இடது பக்கத்தால் வரும் வாகனங்களுக்கு முந்துரிமை வழங்கல் வேண்டும் என்பதை அறிவித்தல்	",
        "	இடது பக்கத்தால் வரும் வாகனங்கள் பிரதான வீதியுடன் இணையும் சந்தி முன்னால்	",
      ],
      questionId: 18,
      correct:
        "	இடது பக்கத்தால் வரும் வாகனங்கள் பிரதான வீதியுடன் இணையும் சந்தி முன்னால்	",
      image: img61,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் வலது பக்கத்திற்கு திரும்பவும்	",
        "	வலது பக்கமாக கொண்டை ஊசி வடிவ வளைவு முன்னால்	",
        "	முன்னால் ரு வடிவுக்கு திருப்ப முடியும	",
        "	இடது பக்கமாக கொண்டை ஊசி வடிவ வளைவு முன்னால	",
      ],
      questionId: 19,
      correct: "	வலது பக்கமாக கொண்டை ஊசி வடிவ வளைவு முன்னால்	",
      image: img62,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	றை நாட்களில் வாகனங்கள் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
        "	வீதி மூடப்பட்டுள்ளது	",
        "	வாகனங்கள் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
        "	முன்னால் சுற்றுவட்டமொன்று உண்டு	",
      ],
      questionId: 20,
      correct: "	வாகனங்கள் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
      image: img63,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	இரட்டை நாடகளில் வாகனங்கள் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
        "	வாகனங்கள் உட்பிரவேசித்தல் தடைசெய்யப்பட்டுள்ளது	",
        "	வாகனங்கள் நிறுத்துவது தடைசெய்யப்பட்டுள்ளது	",
        "	வாகனங்கள் நிறுத்தல் மற்றும் ஏற்றல் தடைசெய்யப்பட்டுள்ளது	",
      ],
      questionId: 21,
      correct: "	வாகனங்கள் நிறுத்தல் மற்றும் ஏற்றல் தடைசெய்யப்பட்டுள்ளது	",
      image: img64,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் பின்நோக்கி திரும்பவும	",
        "	இடது பக்கம் திரும்பவும்	",
        "	இப்பக்கத்தால் கடந்து செல்லவும்	",
        "	முன்னால் இடது பக்கத்திற்கு திரும்பவும்	",
      ],
      questionId: 22,
      correct: "	இப்பக்கத்தால் கடந்து செல்லவும்	",
      image: img65,
    },
    {
      question: "	இக் குறியீட்டினால் குறிப்பிடப்படுவது	",
      answers: [
        "	முன்னால் வலது பக்கத்திற்கு திரும்பவும்	",
        "	வாகனங்கள் நிறுத்த முடியும்	",
        "	முன்னால் ஆபத்து உள்ளதால் மெதுவாக செலுத்தவும	",
        "	“ நிறுத்து” சமிக்ஞை	",
      ],
      questionId: 23,
      correct: "	முன்னால் வலது பக்கத்திற்கு திரும்பவும்	",
      image: img66,
    },
    {
      question:
        "	படத்திற்கு அமைய வாகனமொன்றை சந்தியில் திருப்பும் போது “අ” இடத்தில்	",
      answers: [
        "	வாகனத்தை நிறுத்த வேண்டும்	",
        "	சமிக்ஞையிடல் வேண்டும்	",
        "	கண்ணாடியைப் பார்த்தல் வேண்டும்	",
        "	வேகத்தை தனித்தல் வேண்டும்	",
      ],
      questionId: 24,
      correct: "	கண்ணாடியைப் பார்த்தல் வேண்டும்	",
      image: img67,
    },
    {
      question:
        "	படத்திற்கு அமைய வாகனமொன்றை சந்தியில் திருப்பும் போது “ආ” இடத்தில்	",
      answers: [
        "	ஹோனை ஒலிக்க வேண்டும்	",
        "	கண்ணாடியைப் பார்த்தல் வேண்டும்	",
        "	சமிக்ஞையிடல் வேண்டும்	",
        "	வேகத்தை அதிகரித்தல் வேண்டும்	",
      ],
      questionId: 25,
      correct: "	சமிக்ஞையிடல் வேண்டும்	",
      image: img68,
    },
    {
      question:
        "	படத்திற்கு அமைய வாகனமொன்றை சந்தியில் திருப்பும் போது “ඇ” இடத்தில	",
      answers: [
        "	ஹோனை ஒலிக்க வேண்டும்	",
        "	கண்ணாடியைப் பார்த்தல் வேண்டும்	",
        "	சமிக்ஞையிடல் வேண்டும்	",
        "	வாகனத்தை உரிய வீதி ஒழுக்குக்கு கொண்டு வரல் வேண்டும்	",
      ],
      questionId: 26,
      correct: "	வாகனத்தை உரிய வீதி ஒழுக்குக்கு கொண்டு வரல் வேண்டும்	",
      image: img69,
    },
    {
      question:
        "	படத்திற்கு அமைய “யு” எனும் ஒன்றுவிட்ட கோட்டினால் காட்டப்படுவது	",
      answers: [
        "	முன்னால் சந்தியொன்று உள்ளதை உணர்த்தல	",
        "	வீதிக்கு குறுக்காக பயனிக்க தடைசெய்யப்பட்ட ரேகை	",
        "	வீதிக்கு நடுவே வரையப்பட்டுள்ள மத்திய ரேகை	",
        "	வீதி ஒழுக்கை குறிக்க வரையப்பட்டுள்ள ரேகை	",
      ],
      questionId: 27,
      correct: "	வீதிக்கு நடுவே வரையப்பட்டுள்ள மத்திய ரேகை	",
      image: img70,
    },
    {
      question:
        "	படத்திற்கு அமைய “டீ” எனும் ஒன்றுவிட்ட கோட்டினால் காட்டப்படுவது	",
      answers: [
        "	வீதி பிரிக்கப்பட்டுள்ள ரேகை	",
        "	“நிறுத்து” எனும் சமிக்ஞையின் ரேகை	",
        "	வீதியில் வழி விடுக எனும் ரேகை	",
        "	வீதியில் வழி விடுக எனும் ரேகை	",
      ],
      questionId: 28,
      correct: "	வீதியில் வழி விடுக எனும் ரேகை	",
      image: img71,
    },
    {
      question:
        "	இலங்கை வீதி ஒழுங்கு விதிகள் கோவையில் எச்சரிக்கை சமிக்ஞைகள் குறிப்பிடப்பட்டிருப்பதுää	",
      answers: [
        "	நீல நிறப் பின்னணியில் வெள்ளை நிறத்தால்	",
        "	சிவப்பு நிறப் பின்னணியில் வெள்ளை நிறத்தால்	",
        "	மஞ்சள் நிறப் பின்னணியில் கறுப்பு நிறத்தால்	",
        "	வெள்ளை நிறப் பின்னணியில் சிவப்பு நிறத்தால்	",
      ],
      questionId: 29,
      correct: "	மஞ்சள் நிறப் பின்னணியில் கறுப்பு நிறத்தால்	",
      image: "",
    },
    {
      question: "	சுற்றுவட்டமொன்றின் அடிப்படைச் சட்டம்	",
      answers: [
        "	தங்களுக்கு வலது பக்கத்தால் வரும் வாகனங்களுக்கு வழிவிடல வேண்டும்	",
        "	தங்களுக்கு இடது பக்கத்தால் அல்லது அண்மையில் வரும் வாகனங்களுக்கு வழிவிடல வேண்டும்	",
        "	வாகன நெரிசல் மிகுந்த திசையிலிருந்து வரும் வாகனனங்களுக்கு வழிவிடல வேண்டும்	",
        "	தங்களுக்கு இடது பக்கத்தால் அல்லது அண்மையில் வரும் வாகனங்களுக்கு வழிவிடல வேண்டும	",
      ],
      questionId: 30,
      correct:
        "	தங்களுக்கு வலது பக்கத்தால் வரும் வாகனங்களுக்கு வழிவிடல வேண்டும்	",
      image: "",
    },
    {
      question:
        "	ஒரு திசைக்கு மூன்று ஒழுக்குகளுள்ள வீதியொன்றில் தங்களது வாகனம் முன்னோக்கி செல்லும் சந்தர்ப்பத்தில் தங்களால் தேர்ந்தெடுக்கப்படவேண்டிய வீதி ஒழுக்கு	",
      answers: [
        "	வீதியின் நடு ஒழுக்கு அல்லது வலது பக்க ஒழுக்கு	",
        "	வீதியின் வலது பக்க ஒழுக்கு	",
        "	வீதியின் இடது பக்க ஒழுக்கு	",
        "	வீதியின் நடு ஒழுக்கு	",
      ],
      questionId: 31,
      correct: "	வீதியின் நடு ஒழுக்கு	",
      image: "",
    },
    {
      question:
        "	தங்களது வாகனத்திற்கும் தங்களுக்கு முன்னால் செல்லும் வாகனத்திற்கும் இடையில் போதியளவு தூரத்தைப் பேணவேண்டியதுää	",
      answers: [
        "	தங்களுக்கு முன்னால் செல்லும் வாகனத்தின் சாரதி திடீரென்று வாகனத்தை நிறுத்தினால் அவ்வாகனத்தை கடந்து செல்ல தங்களுக்கு அதிக நேரம் கிடைப்பதனால்	",
        "	தங்களுக்கு முன்னால் செல்லும் வாகனத்தின் சாரதி திடீரென்று வாகனத்தை நிறுத்தினால் தங்களுக்கு தங்களது வாகனத்தை பாதுகாப்பாக நிறுத்திக்கொள்ள முடிவதனால்	",
        "	தங்களுக்கு முன்னால் செல்லும் வாகனத்தை கடந்து செல்ல அதிக இடம் கிடைப்பதனால்	",
        "	தங்களுக்கு முன்னால் செல்லும் வாகனத்திற்கு பின்னால் செல்வது மிகவும் இலகுவாக இருப்பதனால்	",
      ],
      questionId: 32,
      correct:
        "	தங்களுக்கு முன்னால் செல்லும் வாகனத்தின் சாரதி திடீரென்று வாகனத்தை நிறுத்தினால் தங்களுக்கு தங்களது வாகனத்தை பாதுகாப்பாக நிறுத்திக்கொள்ள முடிவதனால்	",
      image: "",
    },
    {
      question:
        "	வாகனமொன்றில் எச்சரிக்கையை குறிக்கும் விளக்கு ( ர்யணயசன டுயஅpள) பாவிக்க வேண்டியது	",
      answers: [
        "	வாகனம் பழுதடைந்த நிலையில் நிறுத்தி வைக்கும் சந்தர்ப்பத்தில் மாத்திரம்	",
        "	வாகனம் அவசர நிலமையின் காரணமாக செலுத்தப்படுகின்றது என்பதை உணர்த்துவதற்கு	",
        "	சந்தியொன்றில் வாகனமொன்றை நேரே செலுத்த எண்ணுகின்ற போது	",
        "	முந்துரிமையைப் பெறுவதற்கு	",
      ],
      questionId: 33,
      correct:
        "	வாகனம் பழுதடைந்த நிலையில் நிறுத்தி வைக்கும் சந்தர்ப்பத்தில் மாத்திரம்	",
      image: "",
    },
    {
      question: "	பிறிதொரு வாகனம் தங்களது வாகனத்தை கடந்து செல்லும் போது	",
      answers: [
        "	தங்களை கடந்து செல்ல தாங்கள் விரும்பாதபோது தங்களது வாகனத்தின் வலது பக்க சமிக்ஞை விளக்கை ;எரியவிடவும்	",
        "	தங்களது வாகனத்தை குறைந்த கியர் ஒன்றில் இட்டு நிறுத்துவதற்கு தயாராக வேண்டும்	",
        "	தங்களது வாகனத்தின் வேகத்தை அதிகரிக்காமல் கடந்து செல்பவருக்கு தங்களது வாகனத்தை கடந்து செல்ல ;வழிவிடல வேண்டும	",
        "	தங்களை கடந்து செல்ல விரும்பாதபோது தங்களது வாகனத்தின் வேகத்தை அதிகரிக்க முடியும்	",
      ],
      questionId: 34,
      correct:
        "	தங்களது வாகனத்தின் வேகத்தை அதிகரிக்காமல் கடந்து செல்பவருக்கு தங்களது வாகனத்தை கடந்து செல்ல ;வழிவிடல வேண்டும	",
      image: "",
    },
    {
      question: "	சந்திக் கோடு குறிக்கப்பட்டுள்ள ஒரு இடத்தில்	",
      answers: [
        "	தாங்கள் நேரே முன்னால் செல்வதாயின் தங்களுக்கு சந்திக் கோட்டுக்குள் பிரவேசிக்க முடியும்	",
        "	வலது பக்கத்திற்கு திருப்புதல் தவிர்ந்த வெளியேறுவதற்கு உள்ள வீதி நெருக்கடியாக இருப்பின் சந்திக் கோட்டிற்குள் பிரவேசிக்கக்கூடாது	",
        "	வலது பக்கத்திற்கு திருப்புதல் தவிர்ந்த ஏனைய எல்லா சந்தர்ப்பங்களிலும் தங்களுக்கு சந்திக் கோட்டுக்குள் பிரவேசிக்க முடியும்	",
        "	எல்லா சந்தர்ப்பங்களிலும் தங்களுக்கு சந்திக் கோட்டுக்குள் பிரவேசிக்க முடியும்	",
      ],
      questionId: 35,
      correct:
        "	வலது பக்கத்திற்கு திருப்புதல் தவிர்ந்த வெளியேறுவதற்கு உள்ள வீதி நெருக்கடியாக இருப்பின் சந்திக் கோட்டிற்குள் பிரவேசிக்கக்கூடாது	",
      image: "",
    },
    {
      question: "	வாகனமொன்றை நிறுத்திவைக்க முடியுமான ஒரு இடம	",
      answers: [
        "	சந்தியொன்றிலிருந்து 25 மீட்டர் எல்லைக்கு அப்பால்	",
        "	பஸ்தரிப்பு நிலையத்திலிருந்து 20 மீட்டர் எல்லைக்கு அப்பால்	",
        "	பாதசாரிகள் கடக்கும் இடத்திலுள்ள வளைவு பிரதேசத்திலிருந்து 10 மீட்டர் எல்லைக்கு அப்பால்	",
        "	தீயனைப்பு நீர் குழாயிலிருந்து 10 மீட்டர் எல்லைக்கு அப்பால்	",
      ],
      questionId: 36,
      correct: "	சந்தியொன்றிலிருந்து 25 மீட்டர் எல்லைக்கு அப்பால்	",
      image: "",
    },
    {
      question: "	தாங்கள் இன்னுமொறு வாகனத்தை கடந்து செல்லக்கூடாத சந்தர்ப்பம்	",
      answers: [
        "	வைத்தியசாலையொன்றின் முன்னால் அல்லது அதனை அண்மிக்கும் போது	",
        "	நீதிமன்றமொன்றின் முன்னால் அல்லது அதனை அண்மிக்கும் போது	",
        "	வீதியில் வரையப்பட்டுள்ள ஒன்றுவிட்ட கோடுகளை வெட்டாத வண்ணம்	",
        "	பாதசாரிகள் கடவையில் அல்லது அதனை நெருங்கும் போது	",
      ],
      questionId: 37,
      correct: "	பாதசாரிகள் கடவையில் அல்லது அதனை நெருங்கும் போது	",
      image: "",
    },
    {
      question: "	தங்களது வாகனம் வீதி விபத்துக்கு உட்பட்டால்	",
      answers: [
        "	விபத்துக்குள்ளான வாகனத்தை உடனடியாக அப்புறப்படுத்தி பொலிஸாருக்கு அறிவித்தல் வேண்டும்	",
        "	வாகனத்தின் அமைப்பை வீதியில் பதிவு செய்து விபத்துக்குள்ளானவர்களை கொண்டு செல்ல வாகனத்தைப் பயன்படுத்த முடியும்	",
        "	விபத்துக்குள்ளான வாகனத்தை பொலிஸ் உத்தியோகத்தர் ஒருவர் வரும் வரை அப்புறப்படுத்தக் கூடாது	",
        "	முடியுமான அளவு விரைவாக வாகனத்தை வீதியிலிருந்து அப்புறப்படுத்தி வாகனத்தில் ஏதும் இயந்திரக் கோளாறுகள் உள்ளனவா என பரிசோதித்தல வேண்டும்	",
      ],
      questionId: 38,
      correct:
        "	வாகனத்தின் அமைப்பை வீதியில் பதிவு செய்து விபத்துக்குள்ளானவர்களை கொண்டு செல்ல வாகனத்தைப் பயன்படுத்த முடியும்	",
      image: "",
    },
    {
      question: "	இரவு காலங்களில் தாங்கள் எப்போதும் வாகனத்தை நிறுத்த வேண்டியது	",
      answers: [
        "	வாகன நெரிசல் குறைந்த திசையை நோக்கி	",
        "	மோட்டார் வாகன போக்குவரத்தின் மாற்றுத்திசையை நோக்கி	",
        "	வாகன நெரிசல் கூடிய திசையை நோக்கி	",
        "	மோட்டார் வாகன போக்குவரத்தின் திசையை நோக்கி	",
      ],
      questionId: 39,
      correct: "	மோட்டார் வாகன போக்குவரத்தின் திசையை நோக்கி	",
      image: "",
    },
    {
      question: "	தங்களுக்கு இடது பக்கத்தால் ஒரு வாகனத்தை கடந்து செல்ல முடிவது	",
      answers: [
        "	தங்களுக்கு முன்னால் செல்லும் வாகனம் வலது பக்கத்திற்கு திருப்பப்படவுள்ளது என்ற சமிக்ஞை இடப்பட்டுள்ள போது	",
        "	தங்களுக்கு முன்னால் செல்லும் வாகனம் மிகவும் குறைந்த வேகத்தில் செல்லும் போது	",
        "	வலது பக்கத்தால் முந்திச் செல்வது பாதுகாப்பானதல்ல என்பதை உணரும் போது	",
        "	தங்களுக்கு மாற்றுத் திசையில் அதிக வாகன நெரிசல் காணப்படும் போது	",
      ],
      questionId: 40,
      correct:
        "	தங்களுக்கு முன்னால் செல்லும் வாகனம் வலது பக்கத்திற்கு திருப்பப்படவுள்ளது என்ற சமிக்ஞை இடப்பட்டுள்ள போது	",
      image: "",
    },
  ],

  questionArrayThird: [
    {
      question: "	தாங்கள் இன்னுமொரு வாகனத்தை முந்தக் கூடாத சந்தர்ப்பம்	",
      answers: [
        "	நீதிமன்றத்தின் முன்னால்	",
        "	வீதியில் ஒன்றுவிட்ட கோடுகள் கீரப்பட்டுள்ள இடத்தில்	",
        "	ஆபத்தான வளைவில்	",
        "	வைத்தியசாலைக்கு முன்னால்	",
      ],
      questionId: 1,
      correct: "	ஆபத்தான வளைவில்	",
      image: "",
    },
    {
      question: "	அதி வேகப் பாதையில் பயனிக்க தடைசெய்யப்படாதது	",
      answers: [
        "	முச்சக்கரவண்டிகள்	",
        "	காணி வாகனங்கள்	",
        "	துவிச்சக்கர வண்டிகள்	",
        "	லொறி வண்டிகள்	",
      ],
      questionId: 2,
      correct: "	லொறி வண்டிகள்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் வாகனமொன்றை கடந்து செல்ல என்னுவதாயின் தாங்கள் எடுக்கும் ஆரம்ப கட்ட நடவடிக்கை யாது?	",
      answers: [
        "	வாகனத்தை சரியான பாதை ஒழுக்குக்கு கொண்டுவரல்	",
        "	சமிக்ஞை இடல்	",
        "	அவதானித்தல்	",
        "	சந்தர்ப்பத்திற்கு ஏற்றவாறு வேகத்தை கட்டுப்படுத்தல்	",
      ],
      questionId: 3,
      correct: "	அவதானித்தல்ää தீர்மானித்தல் மற்றும் செயற்படுத்தல்	",
      image: "",
    },
    {
      question: "	வாகனமொன்றை நிறுத்தும் போது நிறுத்தும் தூரமாக கணிக்கப்படுவது	",
      answers: [
        "	என்னும் தூரம் மற்றும் பிரேக் இடும் தூரம் ஆகிய இரண்டினதும் குறைந்த தூரம்	",
        "	என்னும் தூரம் மற்றும் பிரேக் இடும் தூரம் ஆகிய இரண்டினதும் வித்தியாசம்	",
        "	என்னும் தூரம் மற்றும் பிரேக் இடும் தூரம் ஆகிய இரண்டினதும் கூட்டுத்தொகை	",
        "	என்னும் தூரம் மற்றும் பிரேக் இடும் தூரம் ஆகிய இரண்டினதும் கூடிய தூரம்	",
      ],
      questionId: 4,
      correct:
        "	என்னும் தூரம் மற்றும் பிரேக் இடும் தூரம் ஆகிய இரண்டினதும் கூட்டுத்தொகை	",
      image: "",
    },
    {
      question:
        "	வாகனத்தை நெடுஞ்சாலையில் செலுத்துகின்ற போது பயனிகள் கடக்கும் இடமொன்று முன்னால் உள்ளது என்பதைக் குறிக்கும் வீதிச் சமிக்ஞையை கண்டவுடன்	",
      answers: [
        "	நெடுஞ்சாலையில் மக்கள் நெரிசல் இல்லாத போது தான் விரும்பியபடி வாகனத்தைச் செலுத்தலாம்	",
        "	அவசரமாக பயனிகள் வருவதற்கு முன்னர் பயனிகள் கடக்கும் இடத்தை கடந்து செல்ல வேகத்தை அதிகரித்தல் வேண்டும்	",
        "	வாகனத்தை நிறுத்தும் நோக்குடன் வேகத்தை கட்டுப்படுத்தி வாகனத்தை செலுத்தல் வேண்டும் ",
        "	ஹோனை ஒலித்து தான் வருவதாக பயனிகளுக்கு அறிவித்து நிறுத்தாமல் செல்லல்	",
      ],
      questionId: 5,
      correct:
        "	வாகனத்தை நிறுத்தும் நோக்குடன் வேகத்தை கட்டுப்படுத்தி வாகனத்தை செலுத்தல் வேண்டும் ",
      image: "",
    },
    {
      question:
        "	முன்னால் செல்லும் வாகனத்தை தங்களுக்கு கடந்து செல்ல வேண்டுமாயின்	",
      answers: [
        "	முன்னாலுள்ள வாகனத்தின் சாரதி கடந்து செல்ல சமிக்ஞை செய்யும் போது அவ்வாறு செய்யவேண்டியதில்லை	",
        "	முன்னால் வீதி தெளிவாக தெரிகின்றதா என்பதை பரிசோதித்தல்	",
        "	நெடுஞ்சாலையில் இடமுள்ளதா என்பதை பரிசோதித்தல்	",
        "	நெடுஞ்சாலையை உன்னிப்பாக அவதானித்து கடந்து செல்ல முடியுமா என்பதை பரீட்சித்து தீர்மானம் எடுத்தல்	",
      ],
      questionId: 6,
      correct:
        "	நெடுஞ்சாலையை உன்னிப்பாக அவதானித்து கடந்து செல்ல முடியுமா என்பதை பரீட்சித்து தீர்மானம் எடுத்தல்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் முன்னால் வலது பக்கத்திற்கு திருப்ப வேண்டும் எனின் பயனிக்க வேண்டியதுää	",
      answers: [
        "	தான் விரும்பும் வீதிஒழுக்கொன்றை பயன்படுத்த முடியும்	",
        "	வீதியின் இடது பக்க ஒழுக்கில்	",
        "	வலது பக்கத்திற்கு கொண்டு செல்லல் வேண்டும்	",
        "	வீதியின் மத்திய ரேகைக்கு அண்மையிலுள்ள வீதி ஒழுக்கிலாகும்	",
      ],
      questionId: 7,
      correct: "	வலது பக்கத்திற்கு கொண்டு செல்லல் வேண்டும்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் முன்னால் இடது பக்கத்தில் நிறுத்த வேண்டுமென அறிவீர்கள் எனின் வாகனத்தை செலுத்தவேண்டியது	",
      answers: [
        "	வீதியின் இடது பக்க ஒழுக்கில்	",
        "	வீதி ஒழுக்குகளை பயன்படுத்த அவசியமில்லை	",
        "	வீதியின் நடு ஒழுக்கில	",
        "	வீதியின் வலது பக்கத்தில்	",
      ],
      questionId: 8,
      correct: "	வீதியின் இடது பக்க ஒழுக்கில்	",
      image: "",
    },
    {
      question: "	துணை வீதியொன்றிலிருந்து பிரதான வீதிக்குள் பிரவேசிக்கும் போது	",
      answers: [
        "	பிரதான வீதியில் வாகன நெரிசல் இல்லை எனின் இடது பக்கத்தால் பிரவேசிக்க முடியும	",
        "	வலது பக்கத்திற்கு கவனத்தைச் செலுத்தி வாகனங்கள் இல்லாத போது வீதிக்கு எடுத்தல் பொருத்தம்	",
        "	இடது பக்கத்திற்கு கவனத்தைச் செலுத்தி வாகனங்கள் இல்லாத போது வீதிக்கு எடுத்தல் பொருத்தம்	",
        "	வீதியின் இரு பக்கத்தையும் உன்னிப்பாக அவதானித்து வாகனத்தை வீதிக்கு எடுத்தல் பொருத்தம்	",
      ],
      questionId: 9,
      correct:
        "	வீதியின் இரு பக்கத்தையும் உன்னிப்பாக அவதானித்து வாகனத்தை வீதிக்கு எடுத்தல் பொருத்தம்	",
      image: "",
    },
    {
      question:
        "	தங்களுக்கு பின்னால் வரும் வாகனம் தங்களை கடந்து செல்வதற்கு முயற்சிக்கும் போது தாங்கள் செய்யவேண்டியது	",
      answers: [
        "	பின்னால் வரும் வாகனம் தொடர்பாக அக்கரை கொள்ள அவசியமில்லை	",
        "	வாகனத்தின் வேகத்தை தனித்து இடது பக்கத்தால் பயனித்து முந்திச் செல்வதற்கு வழிவிடல வேண்டும்	",
        "	இடது பக்க சிக்னல் விளக்கை எரியவிட்டு குறித்த நபருக்கு செல்லுமாறு அறிவித்தல்	",
        "	வாகனத்தை மேலும் வீதியின் நடுவில் செலுத்தி அவருக்கு முந்திச் செல்வதற்கு இடமளிக்காமல் பயனித்தல்	",
      ],
      questionId: 10,
      correct:
        "	வாகனத்தின் வேகத்தை தனித்து இடது பக்கத்தால் பயனித்து முந்திச் செல்வதற்கு வழிவிடல வேண்டும்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் வாகனத்தை செலுத்திக்கொண்டிருக்கும் போது தங்களது கையடக்க தொலைபேசி ஓசை எழுப்பினால	",
      answers: [
        "	நகர பிரதேசங்களில் மாத்திரம் கையடக்க தொலைபேசியை எடுத்து பதிலளிக்கக்கூடாது	",
        "	ஒரு கையினால் சுக்கானை செலுத்திய வண்ணம் மற்றையை கையால் தொலைபேசியை எடுத்து பதிலளித்தல் வேண்டும்	",
        "	தொலைபேசி அழைப்புக்கு பதிலளிக்க வேண்டுமெனின் வாகனத்தை ஓரத்தில் நிறுத்திவிட்டு தொலைபேசி அழைப்புக்கு பதிலளிக்கலாம்	",
        "	கையடக்க தொலைபேசியை கொண்டு செல்லக் கூடாது	",
      ],
      questionId: 11,
      correct:
        "	தொலைபேசி அழைப்புக்கு பதிலளிக்க வேண்டுமெனின் வாகனத்தை ஓரத்தில் நிறுத்திவிட்டு தொலைபேசி அழைப்புக்கு பதிலளிக்கலாம்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் வாகனத்தை செலுத்துகின்ற போது வீதிக்கு முன்னாலுள்ள தடையொன்று காரணமாக வரிசையாக வாகனங்கள் ஒன்றன் பின் ஒன்றாக நிறுத்தப்பட்டுள்ள போது தாங்கள் செய்யவேண்டியது	",
      answers: [
        "	எதிர் திiசியல் வாகனங்கள் இல்லையெனின் வீதியின் வலது பக்கத்தால் பயனித்தல் வேண்டும	",
        "	சகல வாகனங்களையும் கடந்து சென்று தடையுள்ள இடத்தை அடைதல	",
        "	வீதியில் இறுதியாக நிறுத்தப்பட்டுள்ள வாகனத்திற்கு பின்னால் வாகனத்தை நிறுத்துதல	",
        "	பயனிகள் கடப்பார்கள் எனும் நோக்கத்துடன் நிறுத்திக்கொள்வதற்கு முடியுமான விதத்தில வேகத்தை குறைத்து வாகனத்தைச் செலுத்தல்	",
      ],
      questionId: 12,
      correct:
        "	வீதியில் இறுதியாக நிறுத்தப்பட்டுள்ள வாகனத்திற்கு பின்னால் வாகனத்தை நிறுத்துதல	",
      image: "",
    },
    {
      question:
        "	தாங்கள் வாகனத்தை செலுத்துகின்ற போது முன்னால் வீதி ஒரத்தில் வசு வண்டியொன்று நிறுத்தப்பட்டு பயனிகள் இறங்கிக்கொண்டிருக்கும் போது தாங்கள் செய்யவேண்டியது	",
      answers: [
        "	நிறுத்தப்பட்டுள்ள வாகனம் தொடர்பாக கவனம் செலுத்த வேண்டியதில்லை	",
        "	பயனிகள் பாதையை கடக்கும் முன்னர் முடியுமான அளவு விறைவாக வசு வண்டியை முந்திச் செல்லல்	",
        "	வசு வண்டிக்கு  பின்னால் தாங்களது வாகனத்தையும் நிறுத்துதல்	",
        "	பயனிகள் கடப்பார்கள் எனும் நோக்கத்துடன் நிறுத்திக்கொள்வதற்கு முடியுமான விதத்தில வேகத்தை குறைத்து வாகனத்தைச் செலுத்தல்	",
      ],
      questionId: 13,
      correct:
        "	பயனிகள் கடப்பார்கள் எனும் நோக்கத்துடன் நிறுத்திக்கொள்வதற்கு முடியுமான விதத்தில வேகத்தை குறைத்து வாகனத்தைச் செலுத்தல்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் வாகனத்தை செலுத்துகின்ற போது முன்னால் வாகன ஒளி விளக்கு சமிக்ஞையுள்ள சந்தி ஒன்று உள்ளது என்பதாக அறிந்துகொள்ளும் போது	",
      answers: [
        "	வேகத்தை அதிகரித்து அவசரமாக சந்தியை கடக்க வேண்டும்	",
        "	எல்லா சந்தர்ப்பங்களிலும் சமிக்ஞை விளக்குகளுக்கு கட்டுப்பட்டு வாகனத்தை செலுத்தல் வேண்டும்	",
        "	சந்திக்கு அண்மையில் வாகனத்தை நிறுத்துவதற்கு தயாராக வேண்டும்	",
        "	முன்னால் பயனிக்கும் வாகனம் செய்வதைப் போன்று தானும் செய்தல் வேண்டும்  96) இரவு காலங்களில் வாகனம் செலுத்தும் போது	",
      ],
      questionId: 14,
      correct:
        "	எல்லா சந்தர்ப்பங்களிலும் சமிக்ஞை விளக்குகளுக்கு கட்டுப்பட்டு வாகனத்தை செலுத்தல் வேண்டும்	",
      image: "",
    },
    {
      question: "	இரவு காலங்களில் வாகனம் செலுத்தும் போது	",
      answers: [
        "	வேகத்தை அதிகரித்து அவசரமாக சந்தியை கடக்க வேண்டும்	",
        "	எல்லா சந்தர்ப்பங்களிலும் சமிக்ஞை விளக்குகளுக்கு கட்டுப்பட்டு வாகனத்தை செலுத்தல் வேண்டும்	",
        "	சந்திக்கு அண்மையில் வாகனத்தை நிறுத்துவதற்கு தயாராக வேண்டும்	",
        "	முன்னால் பயனிக்கும் வாகனம் செய்வதைப் போன்று தானும் செய்தல் வேண்டும	",
      ],
      questionId: 15,
      correct:
        "	எல்லா சந்தர்ப்பங்களிலும் சமிக்ஞை விளக்குகளுக்கு கட்டுப்பட்டு வாகனத்தை செலுத்தல் வேண்டும்	",
      image: "",
    },
    {
      question:
        "	சமிக்ஞை விளக்குகள் உள்ள ஒரு சந்தியில் பொலிஸ் உத்தியோகத்தர் ஒருவர் வாகனங்களை கட்டுப்படுத்துவாராயின் தாங்கள் செயற்படவேண்டியது	",
      answers: [
        "	வலது பக்கத்திற்கு திருப்பும் போது மாத்திரம் பொலிஸ் உத்தியோகத்தரின் கட்டளைக்கு அடிபனிதல் வேண்டும்	",
        "	வாகன நெரிசல் குறைவு என்பதனால் வேகமாக வாகனத்தை செலுத்தல் வேண்டும்	",
        "	சமிக்ஞை விளக்கின் கட்டளைப்படி	",
        "	தங்களுக்கு விரும்பிய பிரகாரம்	",
      ],
      questionId: 16,
      correct: "	பொலிஸ் உத்தியோகத்தரின் கட்டளைப்படி	",
      image: "",
    },
    {
      question:
        "	தாங்கள் செலுத்துகின்ற வாகனம் இடை நடுவில் இடருக்கு முகம் கொடுத்தால் தாங்கள் செய்யவேண்டியது	",
      answers: [
        "	முடியுமான அளவு வீதிக்கு இடைஞ்சல் ஏற்படாத வண்ணம் வாகனத்தை நிறுத்துதல்	",
        "	வாகனத்தின் விளக்குகளை எரியவிட்டு நிறுத்தலாம்	",
        "	இடருக்கு முகம் கொடுத்த இடத்திலேயே நிறுத்துதல்	",
        "	முடியுமான அளவு தூரம் வாகனத்தை செலுத்துதல்	",
      ],
      questionId: 17,
      correct:
        "	முடியுமான அளவு வீதிக்கு இடைஞ்சல் ஏற்படாத வண்ணம் வாகனத்தை நிறுத்துதல்	",
      image: "",
    },
    {
      question:
        "	மழையுடன் கூடிய காலநிலை நிலவும் சந்தர்ப்பத்தில் வாகனத்தை செலுத்தும் போது எப்போதும் கடைபிடிக்க வேண்டியது	",
      answers: [
        "	வாகனத்தின் டயர்கள் சிறப்பாக உள்ள போது கவனம் அவசியமில்லை	",
        "	குறைந்த கியரில் மெதுவாக வாகனத்தைச் செலுத்தல்	",
        "	கூடிய கியரில் வேகமாக வாகனத்தைச் செலுத்தல்	",
        "	கூடிய கியரில் மெதுவாக வாகனத்தைச் செலுத்தல்	",
      ],
      questionId: 18,
      correct: "	குறைந்த கியரில் மெதுவாக வாகனத்தைச் செலுத்தல்	",
      image: "",
    },
    {
      question: "	சாய்வுமிக்க வீதியொன்றில் வாகனத்தைச் செலுத்தும் போது	",
      answers: [
        "	முடியுமான அளவு வேகமாக வாகனத்தைச் செலுத்தல் வேண்டும்	",
        "	குறைவான கியரொன்றில் வாகனத்தைச் செலுத்தல் வேண்டும்	",
        "	கூடிய கியரொன்றில் வாகனத்தைச் செலுத்தல் வேண்டும்	",
        "	கியர் இன்றி நிவ்டல் நிலையில் வாகனத்தைச் செலுத்தல் வேண்டும்	",
      ],
      questionId: 19,
      correct: "	குறைவான கியரொன்றில் வாகனத்தைச் செலுத்தல் வேண்டும்	",
      image: "",
    },
    {
      question:
        "	சமதரையான ஒரு இடத்தில் வாகனத்தை நிறுத்திவைக்கும் போது தாங்கள் செய்யவேண்டிய முக்கிய விடயம்	",
      answers: [
        "	கதவுகளை நன்றாக பூட்டுதல் வேண்டும்	",
        "	நிறுத்திவைக்கும் பிரேக்கை சரியாக இடுதல்	",
        "	பகல் நேரமாயினும் நிறுத்தும் விளக்குகளை எரியவிடல்	",
        "	முன்னாலுள்ள டயர்களை வலது பக்கத்திற்கு திருப்பி நிறுத்துதல்	",
      ],
      questionId: 20,
      correct: "	நிறுத்திவைக்கும் பிரேக்கை சரியாக இடுதல்	",
      image: "",
    },
    {
      question: "	எச்சரிக்கையை உணர்த்தும் விளக்குகளை எரியவைக்க வேண்டியது	",
      answers: [
        "	வாகனத்தில் நோயாளி ஒருவரை கொண்டு செல்லும் போது	",
        "	நாற்சந்தியொன்றில் முன்னோக்கிச் செல்லும் போது	",
        "	ஆபத்தான ஒரு இடத்தில் வாகனமொன்றை கடந்து செல்லும் போது	",
        "	நெடுஞ்சாலையில் இடருக்குள்ளான வாகனமொன்றை நிறுத்திவைக்கும் போது	",
      ],
      questionId: 21,
      correct: "	நெடுஞ்சாலையில் இடருக்குள்ளான வாகனமொன்றை நிறுத்திவைக்கும் போது	",
      image: "",
    },
    {
      question: "	மோட்டார் வாகனமொன்றிற்கு எரிபொருள் நிரப்புகின்ற போது	",
      answers: [
        "	எரிபொருள் நிரப்பும் போது என்ஜின் தொடர்பாக கவனம் செலுத்தவேண்டியதில்லை;	",
        "	வாகனத்தின் என்ஜின் இயங்கிக்கொண்டிருக்கும் போது எரிபொருள் நிரப்புதல் வேண்டும்	",
        "	எரிபொருள் நிரப்புகின்ற போது என்ஜினை செயலிழக்கச் செய்தல் வேண்டும்	",
        "	என்ஜினில் எண்ணெய் உரிய அளவில் உள்ளதா என்பதை கட்டாயமாக பரிசோதித்தல் வேண்டும்	",
      ],
      questionId: 22,
      correct:
        "	எரிபொருள் நிரப்புகின்ற போது என்ஜினை செயலிழக்கச் செய்தல் வேண்டும்	",
      image: "",
    },
    {
      question:
        "	புதிய எண் தகடுகள் முறையின் கீழ் வழங்கப்படும் வாகனத்தின் அடையாள சான்றிதழ்	",
      answers: [
        "	அடையாளச் சான்றிதழ் தொடர்பாக கவனம் செலுத்தவேண்டியதில்லை	",
        "	எல்லா சந்தர்ப்பங்களிலும் வாகனத்தினுள் வைத்திருத்தல் வேண்டும்	",
        "	வீட்டில் பாதுகாப்பான ஒரு இடத்தில் பாதுகாத்து வைத்தல் வேண்டும்	",
        "	வாகன உரிமையாளர் வசம் வைத்திருத்தல் வேண்டும்	",
      ],
      questionId: 23,
      correct: "	எல்லா சந்தர்ப்பங்களிலும் வாகனத்தினுள் வைத்திருத்தல் வேண்டும்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் இரவு நேரததில் வாகனத்தைச் செலுத்தும் போது வேறொரு வாகனம் முன்னால வரும் சந்தர்ப்பத்தில் தாங்கள் செய்யவேண்டியது?	",
      answers: [
        "	முன்னால் வரும் வாகனத்தின் விளக்குகளினது ஒளி குறைக்கப்பட்டால் மாத்திரம் விளக்குகளின் ஒளியை குறைத்தல் வேண்டும்	",
        "	வாகனத்தின் பிரதான விளக்குகளினது ஒளியைக் குறைத்தல்	",
        "	வாகனத்தின் பிரதான விளக்குகளை அனைத்தல்	",
        "	வாகனத்தின் ஹோனை ஒலித்தல்	",
      ],
      questionId: 24,
      correct: "	வாகனத்தின் பிரதான விளக்குகளினது ஒளியைக் குறைத்தல்	",
      image: "",
    },
    {
      question: "	வாகனமொன்றை முந்திச் செல்லும் போது கடைபிடிக்க வேண்டியது	",
      answers: [
        "	3ää4 போன்ற கூடிய கியர் ஒன்றை தெரிவு செய்வதன் மூலம் அதிக சக்தியை வழங்கல்	",
        "	3ää4 போன்ற கூடிய கியர் ஒன்றை தெரிவு செய்வதன் மூலம் அதிக வேகத்தை வழங்கல்	",
        "	1ää2 போன்ற குறைந்த கியர் ஒன்றை தெரிவு செய்வதன் மூலம் அதிக சக்தியை வழங்கல்	",
        "	1ää2 போன்ற குறைந்த கியர் ஒன்றை தெரிவு செய்வதன் மூலம் குறைந்த சக்தியை வழங்கல்	",
      ],
      questionId: 25,
      correct:
        "	3ää4 போன்ற கூடிய கியர் ஒன்றை தெரிவு செய்வதன் மூலம் அதிக சக்தியை வழங்கல்	",
      image: "",
    },
    {
      question:
        "	வீதி ஒழுக்குகள் மூன்று உள்ள வீதியொன்றில் மூன்றாவது ஒழுக்கை பயன்படுத்தவேண்டியது	",
      answers: [
        "	வலது பக்கத்திற்கு திருப்புவதற்கு	",
        "	அதிக வேகத்துடன் வாகனத்தைச் செலுத்துவதற்கு	",
        "	வேறு வாகனமொன்றை முந்திச் செல்வதற்கு	",
        "	வேறு வாகனமொன்றை முந்திச் செல்வதற்கு அல்லது வலது பக்கத்திற்கு திருப்பவதற்கு அல்லது விபத்தொன்றை தவிர்ப்பதற்கு	",
      ],
      questionId: 26,
      correct:
        "	வேறு வாகனமொன்றை முந்திச் செல்வதற்கு அல்லது வலது பக்கத்திற்கு திருப்பவதற்கு அல்லது விபத்தொன்றை தவிர்ப்பதற்கு	",
      image: "",
    },
    {
      question:
        "	நெடுஞ்சாலைகள் வழிகாட்டல் கோவையில் எச்சரிக்கை வழங்கும் சமிக்ஞைகளின் நிறமாவது	",
      answers: [
        "	சிவப்புää வெள்ளை மற்றும் கருப்பு நிறம்	",
        "	சிவப்புää வெள்ளை மற்றும் நீல நிறம்	",
        "	வெள்ளை நிறப் பின்னணியில் சிவப்பு நிறம்	",
        "	மஞ்சள் நிற பின்னணியில் கருப்பு நிறம்	",
      ],
      questionId: 27,
      correct: "	மஞ்சள் நிற பின்னணியில் கருப்பு நிறம்	",
      image: "",
    },
    {
      question:
        "	பிரதான வீதிக்கு உட்பிரவேசிக்கும் போது தாங்கள் வழிவிட வேண்டியது	",
      answers: [
        "	இடது பக்கத்தால் வரும் வாகனங்களுக்கு	",
        "	வலது பக்கத்தால் வரும் வாகனங்களுக்கு	",
        "	வலது மற்றும் இடது பக்கத்தால் வரும் வாகனங்களுக்கு	",
        "	முன்னால் வரும் வாகனங்களுக்கு	",
      ],
      questionId: 28,
      correct: "	வலது மற்றும் இடது பக்கத்தால் வரும் வாகனங்களுக்கு	",
      image: "",
    },
    {
      question:
        "	இரவு நேரங்களில் முன்னால் வரும் வாகனத்தின் பிரதான விளக்குகளின் ஒளி குறைக்கப்படாவிடின் தாங்கள் செய்யவேண்டியது	",
      answers: [
        "	வாகனத்தின் விளக்குகளை அனைத்தல்	",
        "	உடனடியாக பிரதான விளக்கின் ஒளியை கூட்டிக்கொள்ளல்	",
        "	வாகனத்தின் வேகத்தைக் குறைத்துக்கொள்ளல	",
        "	வாகனத்தின் வேகத்தை குறைத்தல் அல்லது நிறுத்துவதற்கு முயற்சித்தல்	",
      ],
      questionId: 29,
      correct:
        "	வாகனத்தின் வேகத்தை குறைத்தல் அல்லது நிறுத்துவதற்கு முயற்சித்தல்	",
      image: "",
    },
    {
      question: "	வெள்ளை நிற தனிக் கோடு வீதியின் நடுவே கீரப்பட்டுள்ள போது	",
      answers: [
        "	கோட்டை வெட்டி வலது பக்கத்திற்கு திருப்ப முடியும்ää முந்திச் செல்ல முடியாது	",
        "	கோட்டை வெட்டி வலது பக்கத்திற்கு திருப்ப வேண்டும்	",
        "	கோட்டை வெட்டி வலது பக்கத்திற்கு திருப்புதல் மற்றும் முந்திச் செல்ல முடியும்	",
        "	கோட்டை வெட்டி முந்திச் செல்லலாம்ää வலது பக்கத்திற்கு திருப்ப முடியாது	",
      ],
      questionId: 30,
      correct:
        "	கோட்டை வெட்டி வலது பக்கத்திற்கு திருப்ப முடியும்ää முந்திச் செல்ல முடியாது	",
      image: "",
    },
    {
      question:
        "	வாகனமொன்றை பின்னோக்கி செலுத்தும் போது பின்பற்ற வேண்டிய ஒழுங்குமுறை	",
      answers: [
        "	கூடிய கியர் ஒன்றை தெரிவு செய்வதன் மூலம் கூடிய சக்தியைப் பெறல்	",
        "	தான் விரும்பிய பிரகாரம் வாகனத்தை பின்னால் செலுத்தலாம்	",
        "	வாகனத்தை வீதி ஒரத்திற்கு சமாந்தரமாக நிறுத்தி துணை வீதியில் குறைந்த தூரத்தில் வாகனத்தை பின்னோக்கி செலுத்தல்	",
        "	வாகனத்தை வீதி ஒரத்திற்கு சமாந்தரமாக நிறுத்தாமல் துணை வீதியில் குறைந்த தூரத்தில் வாகனத்தை பின்னோக்கி செலுத்தல்	",
      ],
      questionId: 31,
      correct:
        "	வாகனத்தை வீதி ஒரத்திற்கு சமாந்தரமாக நிறுத்தி துணை வீதியில் குறைந்த தூரத்தில் வாகனத்தை பின்னோக்கி செலுத்தல்	",
      image: "",
    },
    {
      question: "	முந்திச் செல்லக் கூடாத சந்தர்ப்பம்	",
      answers: [
        "	பாடசாலையொன்று முன்னால் உள்ள போது	",
        "	வைத்தியசாலையொன்று முன்னால் உள்ள போது	",
        "	வீதிக்கு நடுவில் உள்ள வெள்ளை நிற ஒன்றுவி;ட்ட கேர்டு உள்ளபோது	",
        "	மலையொன்றின் உச்சியில்	",
      ],
      questionId: 32,
      correct: "	மலையொன்றின் உச்சியில்	",
      image: "",
    },
    {
      question: "	பாதைக் கடவையைக் குறிக்கும் சமிக்ஞைப் பலகையைக் கண்டால்	",
      answers: [
        "	வீதி நெரிசல் உள்ள சந்தர்ப்பத்தில் மாத்திரம் கவனம் செலுத்தல் வேண்டும்	",
        "	பாதசாரி ஒருவர் உள்ளாரா என பரிசோதித்தல்	",
        "	மஞ்சள் கோட்டிற்கு வெளியே வாகனத்தை நிறுத்துதல்	",
        "	வாகனத்தின் வேகத்தைக் குறைத்தல்	",
      ],
      questionId: 33,
      correct: "	மஞ்சள் கோட்டிற்கு வெளியே வாகனத்தை நிறுத்துதல்	",
      image: "",
    },
    {
      question: "	செல்வதற்கு ஆயத்தமாவதற்கான ஒளி விளக்கை எரியவிடல	",
      answers: [
        "	சிவப்பு நிற விளக்கு எரியும்	",
        "	பச்சை நிற விளக்கு எரியும்	",
        "	மஞ்சள் நிற விளக்கு எரியும்	",
        "	மஞ்சள் விளக்குடன் சிவப்பு விளக்கும் எரியும	",
      ],
      questionId: 34,
      correct: "	மஞ்சள் நிற விளக்கு எரியும்	",
      image: "",
    },
    {
      question:
        "	சுற்று வளைவொன்றை நோக்கி வரும் போது முன்னால் மஞ்சள் நிற விளக்கு எரிந்தால	",
      answers: [
        "	ஒளி விளக்கு சமிக்ஞைகளினால் கட்டுப்படுத்தப்படாதது	",
        "	வலது பக்கத்தால் வருபவர்களுக்கு வழிவிடல வேண்டும்	",
        "	இடது பக்கத்தால் வருபவர்களுக்கு வழிவிடல வேண்டும்	",
        "	ஒளி விளக்கு சமிக்ஞைக்கு கட்டுப்பட வேண்டும்	",
      ],
      questionId: 35,
      correct: "	ஒளி விளக்கு சமிக்ஞைக்கு கட்டுப்பட வேண்டும்	",
      image: "",
    },
    {
      question:
        "	சுற்று வளைவொன்றை நோக்கி வரும் போது முன்னால் மஞ்சள் விளக்கு எரிந்தும் அனைந்தும் காணப்படின்	",
      answers: [
        "	சுற்று வளைவுக்கு தங்களது கூடிய கவனத்தை செலுத்துவதற்காக வேண்டி இடப்பட்டுள்ளது	",
        "	நேரடியாக முன்னோக்கிச் செல்ல வேண்டும்	",
        "	சுற்று வளைவின் சட்டத்தைப் பின்பற்றல் வேண்டும்	",
        "	சுற்று வளைவின் சட்டம் அப்போது அமுல்படுத்தப்படாது	",
      ],
      questionId: 36,
      correct: "	சுற்று வளைவின் சட்டத்தைப் பின்பற்றல் வேண்டும்	",
      image: "",
    },
    {
      question: "	வாகனங்களில் கண்ணாடிகளை இருள்மயப்படுத்த தற்போதுள்ள ஏற்பாடுகள	",
      answers: [
        "	முன்கண்ணாடி தவிர்ந்த ஏனைய கண்ணாடிகளை இருள்மயப்படுத்த முடியும்	",
        "	சகல கண்ணாடிகளையும் இருள்மயப்படுத்த முடியாது	",
        "	முன்னால் இரு கதவுகளின் கண்ணாடிகளையும் முன்கண்ணாடியின் உயரத்தின் ஐந்தில் நான்கு பகுதி கீழ் பாகத்தில் இருள்மயப்படுத்தலாம்	",
        "	சகல கண்ணாடிகளையும் இருள்மயப்படுத்தலாம்	",
      ],
      questionId: 37,
      correct:
        "	முன்னால் இரு கதவுகளின் கண்ணாடிகளையும் முன்கண்ணாடியின் உயரத்தின் ஐந்தில் நான்கு பகுதி கீழ் பாகத்தில் இருள்மயப்படுத்தலாம்	",
      image: "",
    },
    {
      question:
        "	மோட்டார் வாகன ஒளி விளக்கு சமிக்ஞைகளில் பச்சை நிறம் ஒளிருமாயின் அடுத்து நிகழ்வது	",
      answers: [
        "	பச்சை அனைந்து சிவப்பு மற்றும் மஞ்சள் ஒரே தடவையில் எரிதல்	",
        "	பச்சை உள்ள நிலையில் மஞ்சள் எரிதல்	",
        "	பச்சை அனைந்து சிவப்பு எரிதல்	",
        "	பச்சை அனைந்து மஞ்சள் எரிதல்	",
      ],
      questionId: 38,
      correct: "	பச்சை அனைந்து மஞ்சள் எரிதல்	",
      image: "",
    },
    {
      question:
        "	மோட்டார் வாகன ஒளி விளக்கு சமிக்ஞைகளில் சிவப்பு நிறம ; ஒளிருமாயின் அடுத்து நிகழ்வது	",
      answers: [
        "	சிவப்பு அனைந்து பச்சை மற்றும் மஞ்சள் ஒரே தடவையில் எரிதல்	",
        "	சிவப்பு அனைந்து மஞ்சள் எரிதல்	",
        "	சிவப்பு உள்ள நிலையில் மஞ்சள் எரிதல்	",
        "	சிவப்பு அனைந்து பச்சை எரிதல்	",
      ],
      questionId: 39,
      correct: "	சிவப்பு உள்ள நிலையில் மஞ்சள் எரிதல்	",
      image: "",
    },
    {
      question:
        "	வீதி விபத்தொன்றின் போது பொலிஸ் நிலையத்தில் ஆஜராகுவதற்கு வழங்கப்பட்டுள்ள கருணைக் காலம்	",
      answers: [
        "	36 மணித்தியாலம்	",
        "	12 மணித்தியாலம்	",
        "	24 மணித்தியாலம்	",
        "	.48 மணித்தியாலம்	",
      ],
      questionId: 40,
      correct: "	24 மணித்தியாலம்	",
      image: "",
    },
  ],

  questionArrayFourth: [
    {
      question:
        "	தன்னியக்க கியர் அற்ற வாகனமொன்று சாதாரணமாக மட்டமான பாதைiயொன்றில் சுதந்திரமாக செலுத்தப்படும் போது இரு கால்களும் இருக்க வேண்டிய விதம்	",
      answers: [
        "	இடது கால் கிளச் பெடலில் மற்றும் வலது கால் கியர் பெடலில்	",
        "	வலது கால் கீழ் தகட்டில் மற்றும் இடது கால் கிளச் பெடலில்	",
        "	வலது கால் கியர் பெடலில் மற்றும் இடது கால் கீழ் தகட்டில்	",
        "	இடது கால் கிளச் பெடலில் சுமாராக மற்றும் வலது கால் எக்ஸ்லேற்றரில்	",
      ],
      questionId: 1,
      correct: "	வலது கால் கீழ் தகட்டில் மற்றும் இடது கால் கிளச் பெடலில்	",
      image: "",
    },
    {
      question:
        "	தன்னியக்க கியர் அற்ற வாகனமொன்று வாகன நெரிசல் மிகுந்த பாதைiயொன்றில் செலுத்தப்படும் போது இரு கால்களும் இருக்க வேண்டிய விதம்	",
      answers: [
        "	இடது கால் கீழ் தகட்டில் மற்றும் வலது கால் கியர் பெடலில்	",
        "	இடது கால் கிளச் பெடலில் மற்றும் வலது கால் கியர் பெடலில் இலேசாக	",
        "	இடது கால் கீழ் தகட்டில் மற்றும் வலது கால் எக்ஸஜலேற்றரில்	",
        "	இடது கால் கிளச் பெடலில் சுமாராக மற்றும் வலது கால் எக்ஸ்லேற்றரில்	",
      ],
      questionId: 2,
      correct:
        "	இடது கால் கிளச் பெடலில் சுமாராக மற்றும் வலது கால் எக்ஸ்லேற்றரில்	",
      image: "",
    },
    {
      question: "	வாகனமொன்றை கடந்து செல்லும் சரியான முறையாவது	",
      answers: [
        "	வாகனத்தின் வேகத்தைப் பொறுத்து முறை வேறுபடும்	",
        "	சமிக்ஞைகளை இட்டு வீதியை நன்றாக அவதானித்து வாகனத்தை சரியாக கட்டுப்படுத்தல்	",
        "	வாகனத்தின் வேகத்தைக் கூட்டி சமிக்ஞைகளை இட்டு வீதியை நன்றாக அவதானித்து வாகனத்தை சரியாக கட்டுப்படுத்தல்	",
        "	வீதியை நன்றாக அவதானித்து சமிக்ஞைகளை இட்டு வாகனத்தை சரியாக கட்டுப்படுத்தல்	",
      ],
      questionId: 3,
      correct:
        "	வீதியை நன்றாக அவதானித்து சமிக்ஞைகளை இட்டு வாகனத்தை சரியாக கட்டுப்படுத்தல்	",
      image: "",
    },
    {
      question: "	வேறு வாகனமொன்றை முந்தும் போது முதலில் செய்யவேண்டியது	",
      answers: [
        "	வாகனத்தை சரியாக வீதி ஒழுக்குக்கு எடுத்தல்	",
        "	தனது வேகத்தை ஏற்ற அளவில் வைத்திருத்தல்	",
        "	சமிக்ஞைகளை இடுதல்	",
        "	கண்ணாடியால் பின்னோக்கிப் பார்த்தல்	",
      ],
      questionId: 4,
      correct: "	கண்ணாடியால் பின்னோக்கிப் பார்த்தல்	",
      image: "",
    },
    {
      question:
        "	வேறு வாகனமென்றை முந்துவதற்கு எத்தனிக்கும் போது இன்னுமொருவர் தங்களை முந்துவதற்கு எத்தனிக்கின்றார் என உணரும் போது முதலில் செய்யவேண்டியது	",
      answers: [
        "	பின்னால் வரும் வாகனம் தொடர்பாக கவனம் செலுத்தத் தேவையில்லை	",
        "	உடனடியாக மிகவும் தெளிவாக தாங்கள் முந்திச்செல்வதற்கு தயாராக உள்ளீர்கள் என்பதை உணர்த்தும் சமிக்ஞையை இடுதல்	",
        "	உடனடியாக மிகவும் தெளிவாக வேகத்தைக் குறைக்கும் சமிக்ஞையை இடுதல்	",
        "	உடனடியாக மிகவும் தெளிவாக தாங்கள் இடது பக்க ஓரத்திற்கு வருவதற்கு தயாராக உள்ளீர்கள் என்பதை உணர்த்தும் சமிக்ஞையை இடுதல்	",
      ],
      questionId: 5,
      correct:
        "	உடனடியாக மிகவும் தெளிவாக தாங்கள் இடது பக்க ஓரத்திற்கு வருவதற்கு தயாராக உள்ளீர்கள் என்பதை உணர்த்தும் சமிக்ஞையை இடுதல்	",
      image: "",
    },
    {
      question:
        "	பாதைக் கடவை ஓரத்தில் நேராக பாதையில் மஞ்சள் கோடொன்று வரையப்பட்டிருப்பின்	",
      answers: [
        "	நெடுஞ்சாலையின் ஓரத்தை காட்டும் அண்மித்த ரேகை	",
        "	அவ்விடத்தில் வாகனங்களை தரிக்கலாம்	",
        "	அவ்விடத்தில் வாகனங்கள் தரிக்க முடியாது	",
        "	இடது பக்க சில்லுகள் இரண்டும் பாதசாரிகள் பயணிக்கும் மேடையில் உள்ளவாறு நிறுத்திவைக்கலாம்	",
      ],
      questionId: 6,
      correct: "	அவ்விடத்தில் வாகனங்கள் தரிக்க முடியாது	",
      image: "",
    },
    {
      question:
        "	பாதைக் கடவை ஓரத்தில் நேராக பாதையில் வெள்ளை நிறக் கோடொன்று வரையப்பட்டிருப்பின	",
      answers: [
        "	நெடுஞ்சாலையின் ஓரத்தை காட்டும் அண்மித்த ரேகை	",
        "	அவ்விடத்தில் வாகனங்களை தரிக்கலாம்	",
        "	அவ்விடத்தில் வாகனங்கள் தரிக்க முடியாது	",
        "	இடது பக்க சில்லுகள் இரண்டும் பாதசாரிகள் பயணிக்கும் மேடையில் உள்ளவாறு நிறுத்திவைக்கலாம்	",
      ],
      questionId: 7,
      correct: "	அவ்விடத்தில் வாகனங்களை தரிக்கலாம்	",
      image: "",
    },
    {
      question: "	நகர எல்லையினுள் அனுமதிக்கப்பட்ட வேக எல்லைகள்	",
      answers: [
        "	சகல வாகனங்களும் மணிகு;கு 50 கி.மீ	",
        "	துவிச்சக்கர வண்டிää மோட்டார் கார்ää இரட்டைப்பயன்பாடுää வர்த்தக வாகனங்கள்ää முச்சக்கர வண்டிகள் என்பன முறையே மணிக்கு 50 கி.மீää மணி 50 கி.மீää மணி 40 கி.மீ	",
        "	துவிச்சக்கர வண்டிää மோட்டார் கார்ää இரட்டைப்பயன்பாடுää மணிக்கு 70 கி.மீää வர்த்தக வாகனங்கள மணி 60 கி.மீää முச்சக்கர வண்டி மணி 40 கி.மீ	",
        "	துவிச்சக்கர வண்டிää மோட்டார் கார்ää இரட்டைப்பயன்பாடுää மணிக்கு 50 கி.மீää வர்த்தக வாகனங்கள மணி 50 கி.மீää முச்சக்கர வண்டி மணி 40 கி.மீ	",
      ],
      questionId: 8,
      correct:
        "	துவிச்சக்கர வண்டிää மோட்டார் கார்ää இரட்டைப்பயன்பாடுää மணிக்கு 50 கி.மீää வர்த்தக வாகனங்கள மணி 50 கி.மீää முச்சக்கர வண்டி மணி 40 கி.மீ	",
      image: "",
    },
    {
      question: "	பயனிகள் கடவை ஒன்று உள்ள போது	",
      answers: [
        "	பயனிகள் கடவை ஊடாக மாத்திரம் பாதசாரிகளுக்கு வீதியை கடக்க முடியும்	",
        "	25 மீட்டருக்கு உட்பட்ட எந்தவொரு இடத்தாலும் பாதசாரிகளுக்கு பாதையைக் கடக்க முடியும்	",
        "	50 மீட்டருக்கு உட்பட்ட எந்தவொரு இடத்தாலும் பாதசாரிகளுக்கு பாதையைக் கடக்க முடியாது	",
        "	50 மீட்டருக்கு உட்பட்ட எந்தவொரு இடத்தாலும் பாதசாரிகளுக்கு பாதையைக் கடக்க	",
      ],
      questionId: 9,
      correct:
        "	50 மீட்டருக்கு உட்பட்ட எந்தவொரு இடத்தாலும் பாதசாரிகளுக்கு பாதையைக் கடக்க முடியாது	",
      image: "",
    },
    {
      question:
        "	மோட்டார் வாகன ஒளி சமிக்ஞைகளில் மஞ்சள் நிறம் மாத்திரம் எரியுமாயின	",
      answers: [
        "	சிவப்பு ஒளி விழுவதற்கு முன்னர் வேகமாக வாகனத்தைச் செலுத்தல் வேண்டும்	",
        "	அடுத்து பச்சை ஒளி எரியவுள்ளதனால் செல்வதற்கு தயாராக வேண்டும்	",
        "	அடுத்து சிவப்பு ஒளி எரியவுள்ளதனால் நிறுத்தல் வேண்டும்	",
        "	அடுத்து பச்சை ஒளி எரியவுள்ளதால் முன்னோக்கிச் செல்லல் வேண்டும்	",
      ],
      questionId: 10,
      correct: "	அடுத்து சிவப்பு ஒளி எரியவுள்ளதனால் நிறுத்தல் வேண்டும்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் பயனிக்கும் வீதியில் முன் தெளிவில்லாத வளைவு ஒன்று உள்ள போது செய்யவேண்டியது	",
      answers: [
        "	வாகனத்தை எப்போதும் வீதியின் நடுவே செலுத்துதல்	",
        "	வந்த வேகத்தில் ஹோனை ஒலித்துக்கொண்டு செல்லல்	",
        "	நிறுத்தும் நோக்கில் வேகத்தை தனித்து ஒரு முறை ஹோனை ஒலித்துக்கொண்டு செல்லல்	",
        "	வாகனத்தின் சங்கை ஊதாமல் வேகமாக செல்லல்	",
      ],
      questionId: 11,
      correct:
        "	நிறுத்தும் நோக்கில் வேகத்தை தனித்து ஒரு முறை ஹோனை ஒலித்துக்கொண்டு செல்லல்	",
      image: "",
    },
    {
      question: "	பாதையின் நடுவே தனி வெள்ளை நிறக்கோடு உள்ள போது	",
      answers: [
        "	வளைவின் ஆபத்தைக் குறிக்க இடப்பட்டுள்ளது	",
        "	வெள்ளைக் கோட்டை வெட்டி முந்திச் செல்லல் கூடாது	",
        "	வலது பக்கத்திற்கு திருப்புவது தடைசெய்யப்பட்டுள்ளது	",
        "	வீதியை பிரிக்க கோடு இடப்பட்டுள்ளது	",
      ],
      questionId: 12,
      correct: "	வெள்ளைக் கோட்டை வெட்டி முந்திச் செல்லல் கூடாது	",
      image: "",
    },
    {
      question: "	வீதியின் நடுவே அருகருகே இரண்டு வெள்ளைக் கோடுகள் உள்ள போது	",
      answers: [
        "	கோடுகளை வெட்டும் வண்ணம் முந்திச் செல்லல் அல்லது வலது பக்கத்திற்கு திருப்புதல் கூடாது	",
        "	வலது பக்கத்திற்கு திருப்ப முடியும்	",
        "	முந்திச் செல்ல முடியும்	",
        "	வலது பக்கத்திற்கு திருப்ப மற்றும் முந்திச் செல்ல முடியும்	",
      ],
      questionId: 13,
      correct:
        "	கோடுகளை வெட்டும் வண்ணம் முந்திச் செல்லல் அல்லது வலது பக்கத்திற்கு திருப்புதல் கூடாது	",
      image: "",
    },
    {
      question:
        "	மோட்டார் வாகனமொன்றின் முன் பகுதி பஃபரில் உலோக பாதுகாப்பு வேலியொன்றை பொருத்தல்	",
      answers: [
        "	முன் பகுதி பஃபர் கழன்று செல்ல வாய்ப்பு உண்டு	",
        "	சட்டரீதியற்றது	",
        "	அவசர விபத்தின் போது மற்றைய தரப்புக்கு ஏற்படும் இழப்பு அதிகம்	",
        "	சாரதியின் பார்வைக்கு தடையை ஏற்படுத்தும்	",
      ],
      questionId: 14,
      correct: "	சட்டரீதியற்றது	",
      image: "",
    },
    {
      question:
        "	சாரதி ஒருவர் வாகனமொன்றை செலுத்தும் எல்லா சந்தர்ப்பங்களிலும் சாரதி அனுமதிப்பத்திரத்தை தம்வசம் வைத்திருப்பது	",
      answers: [
        "	சட்டரீதியான தேவைப்பாடு இல்லை	",
        "	சட்டரீதியான தேவையாகும்	",
        "	நிழற்படப் பிரதி ஒன்றை வைத்திருப்பது போதுமானது	",
        "	தேவையான போது முன்வைப்பதற்கு பாதுகாப்பாக வீடடில் வைத்திருப்பது உகந்தது	",
      ],
      questionId: 15,
      correct: "	சட்டரீதியான தேவையாகும்	",
      image: "",
    },
    {
      question:
        "	தனது வாகனத்திற்கு முன்னால் பயனிக்கும் வாகனமொன்றிற்கு இடது பக்கத்தால் முந்திச் செல்ல முடியுமாவது	",
      answers: [
        "	இடது பக்கத்தால் முந்திச் செல்வது முற்றாக தடைசெய்யப்பட்டுளளது	",
        "	முன்னால் செல்லும் வாகனம் வலது பக்கத்திற்கு திருப்பவதாக சமிக்ஞை செய்துள்ள போது	",
        "	இடது பக்கத்தால் முந்திச்செல்வது பாதுகாப்பானது என உணரும் சந்தர்ப்பத்தில்	",
        "	மேலுள்ள எச்சந்தர்ப்பத்திலும் இடது பக்கத்தால் செல்ல முடியும்	",
      ],
      questionId: 16,
      correct:
        "	முன்னால் செல்லும் வாகனம் வலது பக்கத்திற்கு திருப்பவதாக சமிக்ஞை செய்துள்ள போது	",
      image: "",
    },
    {
      question:
        "	வாகனத்தை நிறுத்தி வைக்கும் போது தாங்கள் செய்ய வேண்டிய முக்கிய விடயம	",
      answers: [
        "	வாகனத்தின் கதவுகளை நன்றாக மூடிவிடல்	",
        "	நிறுத்தி வைக்கும் பிரேக்கை சரியாக இடுதல்	",
        "	பகல் நேரமாயினும் நிறுத்தல் விளக்குகளை எரியவிடல்	",
        "	முன்னாலுள்ள சில்லுகளை முற்றாக இடது பக்கத்திற்கு திருப்பி நிறுத்தி வைத்தல்	",
      ],
      questionId: 17,
      correct: "	நிறுத்தி வைக்கும் பிரேக்கை சரியாக இடுதல்	",
      image: "",
    },
    {
      question: "	எச்சரிக்கையை உணர்த்தும் விளக்குகளை எரியவிட வேண்டியது	",
      answers: [
        "	வாகனத்தில் நோயாளி ஒருவரை கொண்டு செல்லும் போது	",
        "	நாற்சந்தியொன்றில் முன்னோக்கிச் செல்லும் போது	",
        "	எச்சரிக்கைமிகுந்த ஒரு இடத்தில் வாகனமொன்றை கடந்து செல்லும் போது	",
        "	நெடுஞ்சாலையில் இடருக்குள்ளான வாகனமொன்றை நிறுத்திவைக்கும் போது	",
      ],
      questionId: 18,
      correct: "	நெடுஞ்சாலையில் இடருக்குள்ளான வாகனமொன்றை நிறுத்திவைக்கும் போது	",
      image: "",
    },
    {
      question: "	மோட்டார் வாகனமொன்றிற்கு எரிபொருள் நிரப்புகின்ற போது	",
      answers: [
        "	எரிபொருள் நிரப்பும் போது என்ஜின் தொடர்பாக கவனம் செலுத்தவேண்டியதில்லை	",
        "	வாகனத்தின் என்ஜின் இயங்கிக்கொண்டிருக்கும் போது எரிபொருள் நிரப்புதல் வேண்டும்	",
        "	எரிபொருள் நிரப்புகின்ற போது என்ஜினை செயலிழக்கச் செய்தல் வேண்டும்	",
        "	என்ஜினில் எண்ணெய் உரிய அளவில் உள்ளதா என்பதை கட்டாயமாக பரீட்சித்துப்பார்த்தல் வேண்டும்	",
      ],
      questionId: 19,
      correct:
        "	எரிபொருள் நிரப்புகின்ற போது என்ஜினை செயலிழக்கச் செய்தல் வேண்டும்	",
      image: "",
    },
    {
      question: "	சுற்று வட்டமொன்றில் கடைப்பிடிக்க வேண்டிய சட்டம	",
      answers: [
        "	இடது மற்றும் வலது பக்கங்களிலிருந்து வரும் வாகனங்களுக்கு வழிவிடல்	",
        "	இடது பக்கத்தால் வரும் வாகனங்களுக்கு முந்துரிமை வழங்கல்	",
        "	பொலிஸ் உத்தியோகத்தர் ஒருவரால் கட்டுபடுத்தப்படாத போது வலது பக்கத்தால் வரும் வாகனங்களுக்கு வழிவிடல்	",
        "	முன்னோக்கி மற்றும் வலது பக்கத்திற்கு வழிவிடல்	",
      ],
      questionId: 20,
      correct:
        "	பொலிஸ் உத்தியோகத்தர் ஒருவரால் கட்டுபடுத்தப்படாத போது வலது பக்கத்தால் வரும் வாகனங்களுக்கு வழிவிடல்	",
      image: "",
    },
    {
      question: "	வாகனத்தின் டயர்களில் காற்று உரிய அளவை குறைவாக இருப்பின்	",
      answers: [
        "	டயரின் சில இடங்கள் வேகமாக தேய்வடையும்	",
        "	டயரின் இருபக்கமும் விரைவாக தேய்வடையும்	",
        "	டயர் வேகமாக தேய்வடையும்	",
        "	டயரின் நடுப்பகுதி வேகமாக தேய்வடையும்;	",
      ],
      questionId: 21,
      correct: "	டயரின் இருபக்கமும் விரைவாக தேய்வடையும்	",
      image: "",
    },
    {
      question: "	வாகனத்தில் எரிபொருள் கூடுதலாக எரிவதற்கு முக்கிய காரணமவாது	",
      answers: [
        "	கூடிய கியர்களில் வாகனத்தைச் செலுத்துவது	",
        "	பலவீனமான சுக்கான் கட்டுப்பாடு	",
        "	வளைவுகளில் வேகமாக செலுத்தல்	",
        "	கடுமையாக பிரேக் இடுதல் மற்றும் வேகமாக செலுத்தல்	",
      ],
      questionId: 22,
      correct: "	கடுமையாக பிரேக் இடுதல் மற்றும் வேகமாக செலுத்தல்	",
      image: "",
    },
    {
      question:
        "	தன்னியக்க கியர் பெட்டி அற்ற வாகனமொன்றில் கியர்களை மாற்றும் போது ஏற்படும் சிறமத்திற்கு முக்கிய காரணமாக அமைவது	",
      answers: [
        "	கிளச்சை சரியாக செயற்படுத்தாமை	",
        "	கியர் பெட்டியின் சில்லுகள் உடைந்திருத்தல்	",
        "	என்ஜினின் செயற்பாடு சிறப்பாக இல்லாதிருத்தல்	",
        "	வாகனத்தின் டிபரன்சரில் கோளாறு இருத்தல்	",
      ],
      questionId: 23,
      correct: "	கிளச்சை சரியாக செயற்படுத்தாமை	",
      image: "",
    },
    {
      question:
        "	தாங்கள் செலுத்தும் பெற்றறி (சார்ஜ்) ஆகவில்லை என்பதைக் குறிக்கும் விளக்கு எரிந்தால் தாங்கள் முதலில் செய்யவேண்டியது	",
      answers: [
        "	பற்றறி வயர்களைப் பரிசோதித்தல்	",
        "	வாகனத்தின் பற்றறியை பரிசோதித்தல்	",
        "	ஓல்டனேட்டரில் அல்லது டைனமோவை சுழலவைக்கும் பட்டியை பரிசோதித்தல்	",
        "	வாகனத்தின் மின் கட்டமைப்பை பரிசோதித்தல் வேண்டும்	",
      ],
      questionId: 24,
      correct: "	ஓல்டனேட்டரில் அல்லது டைனமோவை சுழலவைக்கும் பட்டியை பரிசோதித்தல்	",
      image: "",
    },
    {
      question:
        "	ஒவ்வாது திடீரென்று இறுக்கமாகும் பிரேக் ஒரு டயரில் அல்லது பல டயர்களில் இருப்பதன் மூலம் புலப்படுவது	",
      answers: [
        "	பிரேக் கட்டமைப்பில் எண்ணெய் கசிவு உள்ளது என்பதாகும்	",
        "	பிரேக் டிரம் டிஸ்க் அல்லது லைனர் என்பவற்றில் கோளாறு உள்ளது என்பதாகும்	",
        "	பிரேக் பெடலின் சுதந்திர அசைவு அதன் அளவுக்கு மேல் இருத்தல் என்பதாகும்	",
        "	பிரேக் சக்திக் கட்டமைப்பில் கோளாறு உள்ளது என்பதாகும்	",
      ],
      questionId: 25,
      correct:
        "	பிரேக் டிரம் டிஸ்க் அல்லது லைனர் என்பவற்றில் கோளாறு உள்ளது என்பதாகும்	",
      image: "",
    },
    {
      question:
        "	டயரின் இரு பக்கங்களை விடவும் நடுப்பகுதி அதிகம் தேய்வுற்றிருப்பதனால் புலப்படுவது	",
      answers: [
        "	ஆடிக்கொண்டுள்ள கட்டமைப்பில் கோளாறு உள்ளது	",
        "	டயரின் வளி அமுக்கம் அளவை விடக் குறைவு என்பதாகும்	",
        "	டயரின் வளி அமுக்கம் அளவை விட அதிகம் என்பதாகும்	",
        "	சுக்கான் கட்டமைப்பில் கோளாறு உள்ளது என்பதாகும்	",
      ],
      questionId: 26,
      correct: "	டயரின் வளி அமுக்கம் அளவை விட அதிகம் என்பதாகும்	",
      image: "",
    },
    {
      question: "	எஞ்ஜின் ஒன்றுக்கு உராய்வு நீக்கி பயன்படுத்தப்படுவது	",
      answers: [
        "	எஞ்ஜினுக்குள் வாயுவின் ஒட்டத்தை அதிகரிக்க	",
        "	அசையும் பாகங்களின் தேய்வைக் கட்டுப்படுத்த	",
        "	எரிபொருள் எரிவின் போது அதன் வினைத்திறனை அதிகரிக்க	",
        "	எஞ்ஜினின் வினைத்திறனை அதிகரிக்க	",
      ],
      questionId: 27,
      correct: "	அசையும் பாகங்களின் தேய்வைக் கட்டுப்படுத்த	",
      image: "",
    },
    {
      question: "	டிபரன்சலின் செயற்பாடு யாது?	",
      answers: [
        "	வாகனத்தை வளைவில் செலுத்தும் போது சில்லுகளின் வேகத்தை சமனிலைப்படுத்தல்	",
        "	டயர்களுக்கு பாதிப்பின்றி அதிக பாரத்தை கொண்டு செல்லல்	",
        "	டயர்களின் முறையற்ற தேய்வை கட்டுப்படுத்தல்	",
        "	வாகனத்தை வளைவில் செலுத்தும் போது சில்லுகளின் வேகத்தை மாற்றுதல்	",
      ],
      questionId: 28,
      correct: "	வாகனத்தை வளைவில் செலுத்தும் போது சில்லுகளின் வேகத்தை மாற்றுதல்	",
      image: "",
    },
    {
      question:
        "	வாகனமொன்றை செலுத்தும் போது முன்னாலுள்ள பகுதியின் குலுக்கத்திற்கு காரணமாவதுää	",
      answers: [
        "	சுக்கான் கட்டமைப்பின் பகுதிகள் தேய்வுற்றிருத்தல்	",
        "	வேறுவிதமான தயாரிப்புக்களின் டயர்களை பொருத்தியிருத்தல்	",
        "	முன்னாலுள்ள டயர்களின் காற்றின் அளவு மாறுபட்ட அளவைக் கொண்டிருத்தல்	",
        "	முன்னாலுள்ள டயர்களினது பற்களின் அமைப்பு வித்தியாசப்படல்	",
      ],
      questionId: 29,
      correct: "	சுக்கான் கட்டமைப்பின் பகுதிகள் தேய்வுற்றிருத்தல்	",
      image: "",
    },
    {
      question: "	வாகனத்தின் நீர் குழாயின் செயற்பாடு	",
      answers: [
        "	எஞ்ஜினது எண்ணெய் ஓட்டத்தை இலகுபடுத்தல்	",
        "	எஞ்ஜினுக்குள் நீர் ஓட்டத்தை ஏற்படுத்தல்	",
        "	என்ஜினில் பயன்படுத்தப்படும் நீரை குளிரூட்டல்	",
        "	ரேடியேட்டரை குளிரூட்டல்	",
      ],
      questionId: 30,
      correct: "	எஞ்ஜினுக்குள் நீர் ஓட்டத்தை ஏற்படுத்தல்	",
      image: "",
    },
    {
      question:
        "	வாகனத்தை செலுத்தும் போது திடீரென பிரேக் கட்டமைப்புடன் தொடர்புடைய கட்டுப்பாட்டு விளக்கு எரிந்தால் தாங்கள் செய்ய வேண்டியது	",
      answers: [
        "	வாகனத்தை அண்மையிலுள்ள கராஜிக்கு கொண்டு செல்லல்	",
        "	பயன முடிவு வரை கவனமாக வாகனத்தைச் செலுத்தல்	",
        "	வாகனத்தை உடனடியாக நிறுத்தி பிரேக் எண்ணெய் உள்ளதா என்பதை பரிசோதித்தல்	",
        "	தொழில்நுட்பவியலாளர் ஒருவரை அழைத்து பரிசோதிக்கும் வரை வாகனத்தை நிறுத்தி வைத்தல்	",
      ],
      questionId: 31,
      correct:
        "	வாகனத்தை உடனடியாக நிறுத்தி பிரேக் எண்ணெய் உள்ளதா என்பதை பரிசோதித்தல்	",
      image: "",
    },
    {
      question: "	வாகனத்தினது டயர்களின் வளி அமுக்கம் குறையும் போது	",
      answers: [
        "	டயர்களின் ஒரு பக்கம் மாத்திரம் கூடுதலாக தேய்வுறும்	",
        "	டயர்களின் இரு பக்கங்களும் தேய்வுற்று நடுப்பகுதி எஞ்சும்	",
        "	டயர்களின் நடுப்பகுதி தேய்வுற்று இரு பக்கமும் எஞ்சும்	",
        "	டயர்கள் தேய்விற்கு காற்று காரணமன்று	",
      ],
      questionId: 32,
      correct: "	டயர்களின் இரு பக்கங்களும் தேய்வுற்று நடுப்பகுதி எஞ்சும்	",
      image: "",
    },
    {
      question: "	சுக்கான் கட்டமைப்பில் சுதந்திர அசைவு அதிகரிப்பது	",
      answers: [
        "	முன்னாலுள்ள டயர்கள் தேய்ந்துள்ள போது	",
        "	சுக்கான் கட்டமைப்பின் ஒரு பகுதி தேய்ந்துள்ள போது	",
        "	முன்னாலுள்ள டயர்களின் வளி அமுக்கம் அதிகரித்துள்ள போது	",
        "	முன்னாலுள்ள டயர்களின் வளி அமுக்கம் குறைந்துள்ள போது	",
      ],
      questionId: 33,
      correct: "	சுக்கான் கட்டமைப்பின் ஒரு பகுதி தேய்ந்துள்ள போது	",
      image: "",
    },
    {
      question:
        "	தாங்கள் செலுத்தும் வாகனத்தின் எஞ்ஜினின் வெப்பம் அதிகரித்து நீர் கொதிக்கும் நிலையில் குறைநீரை ஊற்றும் போது கடைப்பிடிக்க வேண்டியது	",
      answers: [
        "	வாகனத்தின் எஞ்ஜின் தொடர்பாக கவனம் செலுத்த தேவையில்லை	",
        "	எஞ்சினை நிறுத்தி நீர் ஊற்ற வேண்டு;ம்	",
        "	எஞ்ஜின் இயக்கத்தில் உள்ள போது நீர் ஊற்ற வேண்டும்	",
        "	மேலுள்ள கூற்றுக்கள் இரண்டும் சரியானவை	",
      ],
      questionId: 34,
      correct: "	எஞ்சினை நிறுத்தி நீர் ஊற்ற வேண்டும்	",
      image: "",
    },
    {
      question:
        "	தாங்கள் செலுத்தும் வாகனத்தில் மின் கோளாறு எற்பட்டு வயர்கள் எரியும் நாற்றம் நுகரப்படுமாயின் உடனடியாக தாங்கள் செய்ய வேண்டியது	",
      answers: [
        "	வாகனத்தின் சகல விளக்குகளையும் அனைத்துவிடல்	",
        "	பிரதான சுவிச்சை செயலிழக்கச் செய்தல்	",
        "	பியுஸை கழற்றுதல்	",
        "	பற்றறி வயரை கழற்றுதல்	",
      ],
      questionId: 35,
      correct: "	பற்றறி வயரை கழற்றுதல்	",
      image: "",
    },
    {
      question:
        "	வாகனமொன்று சறுகிச் செல்லும் போது ஏற்படும் ஆபத்தை குறைக்க செய்ய வேண்டியது	",
      answers: [
        "	சறுகிச் செல்லும் போது ஆபத்தை குறைக்க முடியாது	",
        "	உடனே பிரேக் இட்டு வேகத்தைக் கட்டுப்படுத்தல்	",
        "	பிரேக் இடாமல் சறுகிச் செல்லும் திசையின் மாற்றுத்திசைக்கு சுக்கானை திருப்புதல் வேண்டும்	",
        "	கியர் மூலம் கட்டுப்படுத்தி சறுகிச் செல்லும் திசைக்கு முறைப்படி சுக்கானை கட்டுப்படுத்தல் வேண்டும்	",
      ],
      questionId: 36,
      correct:
        "	கியர் மூலம் கட்டுப்படுத்தி சறுகிச் செல்லும் திசைக்கு முறைப்படி சுக்கானை கட்டுப்படுத்தல் வேண்டும்	",
      image: "",
    },
    {
      question:
        "	பிரேக் எண்ணெய் பயன்படுத்தப்படும் ஒரு வாகனத்தில் பிரேக் பெடல் கீழுக்கே செல்லுமாயின் அதன் மூலம் புலப்படுவது	",
      answers: [
        "	பிரேக் பெடலில் கோளாறு உள்ளது என்பதாகும்	",
        "	பிரேக் பயின்ட் (பிரேக் இறுக்கம்) உள்ளது என்பதாகும்	",
        "	பிரேக் ஒழுங்காக செயற்படுகிறது என்பதாகும'	",
        "	பிரேக் எண்ணெய் கசிவு உள்ளது என்பதாகும'	",
      ],
      questionId: 37,
      correct: "	பிரேக் எண்ணெய் கசிவு உள்ளது என்பதாகும'	",
      image: "",
    },
    {
      question:
        "	வாகனத்திற்கு பிரேக் இடும் போது இடது பக்கத்திற்கு இழுத்துச் செல்லுமாயின் அதன் மூலம் புலப்படுவது	",
      answers: [
        "	டயர்களின் வளி அமுக்கம் ஒவ்வொன்றுக்கு மாற்றமாக உள்ளது என்பதாகும்	",
        "	முன்னால் இடது பக்க டயரில் பிரேக் எண்ணெய் கசிவு உள்ளது என்பதாகும்	",
        "	முன்னால் வலது பக்க டயரில் பிரேக் எண்ணெய் கசிவு உள்ளது என்பதாகும'	",
        "	பின்னாலுள்ள டயர்களில் பிரேக் எண்ணெய் இல்லை என்பதாகும'	",
      ],
      questionId: 38,
      correct:
        "	முன்னால் வலது பக்க டயரில் பிரேக் எண்ணெய் கசிவு உள்ளது என்பதாகும'	",
      image: "",
    },
    {
      question:
        "	வாகனமொன்றை செலுத்தும் போது கியர் இடுவதற்கு கஷ்டமாக இருப்பதற்கு முக்கிய காரணம்	",
      answers: [
        "	வாகனத்தின் கியர் லிவரில் கோளாறு உள்ளது	",
        "	கிளச் சீறாக இயங்காமை	",
        "	எஞ்ஜினின் வேகம் சீறாக இல்லாமை	",
        "	கியர் பெட்டியில் கோளாறு உள்ளது	",
      ],
      questionId: 39,
      correct: "	கிளச் சீறாக இயங்காமை	",
      image: "",
    },
    {
      question: "	வானத்தின் டயர்களில் காற்று உரிய அளவை விட அதிகரித்தால	",
      answers: [
        "	டயரின் சிற்சில இடங்கள் அதிகமாக தேய்வுறும்	",
        "	டயர் விரைவாக தேய்வுறும்	",
        "	டயரின் நடுப்பகுதி விரைவாக தேய்வுறும'	",
        "	டயரின் இரு பக்கமும் விரைவாக தேய்வுறும'	",
      ],
      questionId: 40,
      correct: "	டயரின் நடுப்பகுதி விரைவாக தேய்வுறும'	",
      image: "",
    },
  ],
};
